import * as React from "react";

function TravelAccordionArrow(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12.546 7.173"
      {...props}
    >
      <path
        data-name="Path 12977"
        d="M1.273 5.9l5-5 5 5"
        fill="none"
        stroke="#636381"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.8}
      />
    </svg>
  );
}

export default TravelAccordionArrow;
