const Fonts = {
    DejavuSans: "DejaVu_sans",
    DejavuSansBold: "DejaVu_sans_bold",
    NinoMtavruliNormal: "Nino_Mtavruli_Normal",
    NinoMtavruliBold: "Nino_Mtavruli_Normal_Bold",
    FiraGORegular: "FiraGO-Regular",
    FiraGOBold: "FiraGO-Bold",
    FiraGOMtavruliRegular: "FiraGO-Regular; font-weight: normal; font-feature-settings: 'case' on",
    FiraGOMtavruliBold: "FiraGO-Bold; font-weight: bold; font-feature-settings: 'case' on",
};

export default Fonts;