import * as React from "react";

function PocketIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 29"
      {...props}
    >
      <g data-name="Group 3506">
        <g data-name="Group 2582">
          <path
            data-name="Path 9454"
            d="M17.643 6.31l.881-4.946a1.067 1.067 0 00-.586-1.311.842.842 0 00-.295-.053H8.357a.986.986 0 00-.929 1.035 1.145 1.145 0 00.048.329l.881 4.946C3.248 7.024.008 11.864 0 17.607v6.214A4.939 4.939 0 004.643 29h16.714A4.939 4.939 0 0026 23.821v-6.214c-.008-5.743-3.248-10.583-8.357-11.297z"
            fill="#261e56"
          />
        </g>
        <path
          data-name="Path 9465"
          d="M5.813 15.675a4.048 4.048 0 012.488-3.987"
          fill="#261e56"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={2.4}
        />
      </g>
    </svg>
  );
}

export default PocketIcon;
