import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";
import { RootState } from "../slices";

interface PrivateRouteProps extends RouteProps {
  component?: any;
  wrapper?: any;
  children?: any;
}

const Wrap: React.FC<{ children?: any; wrapper?: any }> = ({
  wrapper: Wrapper,
  children,
}) => {
  return Wrapper ? <Wrapper>{children}</Wrapper> : children;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  wrapper: Wrapper = null,
  children,
  ...rest
}) => {
  const { user, token } = useSelector((state: RootState) => state.auth);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        token ? (
          Wrapper ? (
            <Wrap wrapper={Wrapper}>
              {Component ? <Component {...routeProps} /> : children}
            </Wrap>
          ) : (
            Component ? <Component {...routeProps} /> : children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
