import * as React from "react";

function SvgSubMenuIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11.541}
      height={6.171}
      {...props}
    >
      <path
        data-name="Path 9704"
        d="M1.271 1.271l4.5 4 4.5-4"
        fill="none"
        stroke="#767880"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.8}
      />
    </svg>
  );
}

export default SvgSubMenuIcon;
