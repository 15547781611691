import React from 'react';

function ReferencesDetailPage() {
  const reference = {
    insuredPerson: 'გიორგი გაფრინდაშვილი',
    uploadDate: '28 აგვისტო 2020',
    documents: [
      { url: 'http:///...document1.pdf', name: 'document1.pdf', documentType: 'passport' },
      { url: 'http:///...document2.pdf', name: 'document2.pdf', documentType: 'receipt' },
    ]
  };
  return (
    <div className="border" style={{ width: 350 }}>
      <ul className="list-group list-group-flush">
        <h2 className="text-center">ოპერაცია</h2>
        <li className="list-group-item">დაზღვეული პირი:  ნიკა ჟღენტი</li>
        <li className="list-group-item">ატვირთვის თარიღი: 25 ივნისი 2020</li>
        <li className="list-group-item">მიმდინარე პასუხის მოლოდინი 48 საათი</li>
        <li className="list-group-item">ტატიშვილის სამედიცინო ცენტრი</li>
        <li className="list-group-item">ვიზიტის სავარაუდო თარიღი <h6>23 ოქტომბერი 2020</h6> </li>
        <li className="list-group-item">ტკივილები სახსრებში, მკერდის არეში, ყელის სიმშრალე, მაღალი სიცხე</li>
      </ul>
      <form>
        <div className="form-group">

          {reference.documents.map(document => (
            <div className="ml-3 mt-2">
                <a href={document.url} target="_blank" rel="noreferrer" >{document.name} ({document.documentType})</a>
                </div>
          ))}
        </div>
      </form>
      <div className="form-group">
        <h5 className="ml-3">კომენტარი</h5>
        <h6 className="ml-2 mt-3">გთხოვთ დროულად გაეცნოთ ჩემს მოთხოვნას</h6>
      </div>
    </div>
  );
}

export default ReferencesDetailPage;
