import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
// import HideShowIcon from "../../icons/HideShowIcon";
// icons
import PopUpAcceptedIcon from "../../icons/popUp-accepted-icon.svg";
// import ShowPassIcon from "../../icons/ShowPassIcon";
import { RootState } from "../../slices";
import { loginUser } from "../../slices/authSlice";
import { AuthState } from "../../domain";
import { useAppDispatch } from "../../config/store";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
import Responsive from "../../config/Responsive";
// import LoginShowIcon from "../../icons/login-show-icon.svg";
import LoginHideIcon from "../../icons/login-hide-icon.svg";
import meaningfulErrorMessage from "utils/meaningfulErrorMessage";
import PopUp from "components/PopUp";

const LoginStart = styled.div`
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: green; */
`;
const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  position: relative;
  margin-right: 10px;
    ${Responsive.desktop}{
      max-width: 440px;
      min-width: 300px;
      width: 100%;
      margin-right: 10px;
    }
    ${Responsive.laptop}{
      max-width: 320px;
      min-width: 300px;
      width: 100%;
      margin-right: 10px;
    }
    ${Responsive.tablet}{
      max-width: 300px;
      min-width: 300px;
      width: 100%;
      margin-right: 5px;
    }
    ${Responsive.mobile}{
      max-width: 300px;
      min-width: 300px;
      width: 100%;
      margin-right: unset;
    }
`;
const LoginTitle = styled.h1`
  font-size: 35px;
  color: ${Colors.TitleBlue};
  margin: unset;
  cursor: default;
  user-select: none;
  font-weight: 400;
  font-family: ${Fonts.FiraGOMtavruliRegular};
    ${Responsive.desktop}{
      font-size: 35px;
      font-weight: 400;
    }
    ${Responsive.laptop}{
      font-size: 22px;
      font-weight: 400;
    }
    ${Responsive.tablet}{
      font-size: 22px;
      font-weight: 400;
    }
    ${Responsive.mobile}{
      font-size: 16px;
      margin-top: 150px;
      font-weight: 400;
    }
`;
const ForgetPasswordText = styled.h1`
  font-size: 16px;
  color: #f14862;
  margin: 27px 0 auto auto;
  cursor: pointer;
  font-weight: 400;
  font-family: ${Fonts.FiraGORegular};
    ${Responsive.desktop}{
      font-size: 16px;
      margin: 27px 0 auto auto;
    }
    ${Responsive.laptop}{
      font-size: 12px;
      margin: 20px 0 auto auto;
    }
    ${Responsive.tablet}{
      font-size: 12px;
      margin: 20px 0 auto auto;
    }
    ${Responsive.mobile}{
      font-size: 12px;
      margin: 20px 0 auto auto;
    }
`;
const LoginAuthorizationButton = styled.button`
  font-size: 20px;
  color: #ffffff;
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  height: 70px;
  background-color: #261e56;
  border-radius: 10px;
  outline: none;
  border: unset;
  margin: unset;
  margin-top: 27px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  &:focus,
  &:hover {
    outline: none;
    border: unset;
    background-color: #261e56;
  }
    ${Responsive.desktop}{
      height: 70px;
      font-size: 20px;
      margin-top: 27px;
      border-radius: 10px;
      max-width: 440px;
    }
    ${Responsive.laptop}{
      height: 50px;
      font-size: 14px;
      margin-top: 20px;
      border-radius: 6px;
      max-width: 320px;
    }
    ${Responsive.tablet}{
      height: 50px;
      font-size: 14px;
      margin-top: 20px;
      border-radius: 6px;
      max-width: 300px;
    }
    ${Responsive.mobile}{
      height: 50px;
      font-size: 14px;
      margin-top: 20px;
      border-radius: 4px;
      max-width: 300px;
    }
`;
const LoginAuthorizationText = styled.h1`
  font-size: 16px;
  color: ${Colors.Gray};
  margin: unset;
  margin-top: 40px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
    ${Responsive.desktop}{
      font-size: 16px;
      margin-top: 40px;
    }
    ${Responsive.laptop}{
      font-size: 12px;
      margin-top: 30px;
    }
    ${Responsive.tablet}{
      font-size: 12px;
      margin-top: 30px;
    }
    ${Responsive.mobile}{
      font-size: 12px;
      margin-top: 30px;
    }
`;

// popUp
const ConfirmedPopUpBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
`;
const ConfirmedPopUp = styled.div`
  width: 660px;
  height: 360px;
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ConfirmedPopUpText = styled.h1`
  font-size: 20px;
  color: ${Colors.TitleBlue};
  margin: unset;
  margin-top: 50px;
  user-select: none;
  font-family: ${Fonts.FiraGOMtavruliBold};
`;
const ShowPassIconBox = styled.div`
  position: absolute;
  top: 47%;
  right: 20px;
    ${Responsive.desktop}{
      top: 47%;
      right: 20px;
    }
    ${Responsive.laptop}{
      top: 45%;
      right: 20px;
    }
    ${Responsive.tablet}{
      top: 45%;
      right: 20px;
    }
    ${Responsive.mobile}{
      top: 61.5%;
      right: 20px;
    }
`;
// inputs
const IdNumberInput = styled.input`
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  height: 70px;
  border: 1px solid #767880;
  border-radius: 10px;
  margin: unset;
  margin-top: 60px;
  outline: none;
  color: #636381;
  font-size: 14px;
  padding-left: 30px;
  font-family: ${Fonts.FiraGORegular};
    ${Responsive.desktop}{
      font-size: 14px;
      margin-top: 60px;
      border-radius: 10px;
      padding-left: 30px;
      height: 70px;
      max-width: 440px;
    }
    ${Responsive.laptop}{
      font-size: 12px;
      margin-top: 40px;
      border-radius: 6px;
      padding-left: 25px;
      height: 50px;
      max-width: 320px;
    }
    ${Responsive.tablet}{
      font-size: 12px;
      margin-top: 40px;
      border-radius: 6px;
      padding-left: 25px;
      height: 50px;
      max-width: 300px;
    }
    ${Responsive.mobile}{
      font-size: 12px;
      margin-top: 35px;
      border-radius: 6px;
      padding-left: 25px;
      height: 50px;
      max-width: 300px;
    }
`;
const PasswordInput = styled.input`
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  height: 70px;
  border: 1px solid #767880;
  border-radius: 10px;
  margin: unset;
  margin-top: 25px;
  outline: none;
  color: #636381;
  font-size: 14px;
  padding-left: 30px;
  font-family: ${Fonts.FiraGORegular};
    ${Responsive.desktop}{
      font-size: 14px;
      margin-top: 25px;
      border-radius: 10px;
      padding-left: 30px;
      height: 70px;
      max-width: 440px;
    }
    ${Responsive.laptop}{
      font-size: 12px;
      margin-top: 20px;
      border-radius: 6px;
      padding-left: 25px;
      height: 50px;
      max-width: 320px;
    }
    ${Responsive.tablet}{
      font-size: 12px;
      margin-top: 20px;
      border-radius: 6px;
      padding-left: 25px;
      height: 50px;
      max-width: 300px;
    }
    ${Responsive.mobile}{
      font-size: 12px;
      margin-top: 20px;
      border-radius: 4px;
      padding-left: 25px;
      height: 50px;
      max-width: 300px;
    }
`;


const Login: React.FC = () => {
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  // const [visible, setVisible] = useState(false);

  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  const [personalNumber, setPersonalNumber] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>('');

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (auth.user) {
      history.push('/dashboard');
    }
  }, []);

  const loginFunction = async () => {
    dispatch(loginUser({ personalNumber, password: pwd }))
    .then(unwrapResult)
    .then(result => {
      history.push('/dashboard');
    })
    .catch(error => {
      console.log(error);
      const msg = meaningfulErrorMessage(error, "გთხოვთ, სცადოთ თავიდან.");
      setErrorMessage(msg);
    });
  };

  const EnterTrigger = (e: { charCode: any; keyCode: number; }) => {
    if (e.charCode == 13) {
      loginFunction()
    }
  };

  return (
    <div style={{ width: 100 + "%" }}>
      <LoginStart>
        <LoginContent>
          <LoginTitle>ავტორიზაცია</LoginTitle>

          <IdNumberInput
            type="text"
            name="პირადი ნომერი"
            placeholder="პირადი ნომერი"
            onChange={(e) => setPersonalNumber(e.target.value)}
          />

          <PasswordInput
            placeholder="პაროლი"
            name="pwd"
            type={isRevealPwd ? "text" : "password"}
            value={pwd}
            onChange={(e) => setPwd(e.target.value)}
            onKeyPress={EnterTrigger}
          />

          <ShowPassIconBox>
            {/* {isRevealPwd ? <LoginShowIcon /> : <ShowPassIcon />} */}
            <img
              title={isRevealPwd ? "დამალე პაროლი" : "გამოაჩინე პაროლი"}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
              src={LoginHideIcon}
              style={{
                width: 19,
                cursor: "pointer",
                transition: "all 3s",
              }}
            />
            <img src="" alt="" />
          </ShowPassIconBox>
          <ForgetPasswordText><Link style={{ color: Colors.Primary, textDecoration: 'none' }} to="/reset-password">დაგავიწყდა?</Link></ForgetPasswordText>
          <LoginAuthorizationButton onClick={loginFunction}>ავტორიზაცია</LoginAuthorizationButton>
          <LoginAuthorizationText>
            კაბინეტში შესასვლელად გაიარე ავტორიზაცია
          </LoginAuthorizationText>
        </LoginContent>
      </LoginStart>
      <PopUp popUpVisible={!!errorMessage} popUpClose={() => setErrorMessage('')} popUpMessage={errorMessage} />
      <ConfirmedPopUpBox>
        <ConfirmedPopUp>
          <img
            src={PopUpAcceptedIcon}
            alt="PopUpAcceptedIcon"
            style={{
              width: 90,
              height: 90,
              display: "block",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
          <ConfirmedPopUpText>
            რეგისტრაცია წარმატებით დასრულდა
          </ConfirmedPopUpText>
        </ConfirmedPopUp>
      </ConfirmedPopUpBox>
    </div>
  );
};

export default Login;
