import React, { useState, useEffect } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { RootState } from "../../slices";
import { AuthState } from "../../domain";
import { useAppDispatch } from "../../config/store";
import { checkUpdatePasswordSMS, sendUpdatePasswordSMS, updateProfile } from "../../slices/authSlice";

import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import Colors from "styles/Colors";
import Fonts from "styles/Fonts";
import AddPhotoIcon from "../../icons/add-photo-icon.png";
import PlusIcon from "../../icons/PlusIcon";
import Responsive from "config/Responsive";
import DisclaimerModal from "components/DisclaimerModal";

import { uploadFile } from "slices/insuranceSlice";
import { viewUploadedFileUrl } from "config";

import meaningfulErrorMessage from "../../utils/meaningfulErrorMessage";
import PopUp from "components/PopUp";

import { useParams } from "react-router-dom";
import defaultAvatarImage from '../../images/personalManage_beta-img.png';


// styles
const ProfileDecoreLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f2f5f7;
`;
const ProfileContainer = styled.div`
  border-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  ${Responsive.laptop} {
  }
  ${Responsive.tablet} {
  }
  ${Responsive.mobile} {
  }
`;
const TabStyle = styled(Tab)`
  font-size: 18px !important;
  outline: none !important;
  color: ${Colors.Primary} !important;
  max-width: 300px !important;
  width: 100% !important;
  font-family: ${Fonts.FiraGOMtavruliRegular} !important;
  font-weight: 400 !important;
  margin-top: 38px !important;
  opacity: unset !important;
  ${Responsive.laptop} {
    font-size: 14px !important;
    max-width: 250px !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.tablet} {
    font-size: 14px !important;
    max-width: 220px !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.mobile} {
    font-size: 14px !important;
    max-width: 150px !important;
    margin-top: unset !important;
    height: 75px !important;
    padding: unset !important;
    line-height: 15px !important;
    &:focus {
      width: 100% !important;
    }
  }
`;
const TabsStyled = styled(Tabs)`
  background-color: #ffffff !important;
  height: 120px !important;
  opacity: unset !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  ${Responsive.laptop} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.tablet} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.mobile} {
    height: 75px !important;
    min-height: 75px !important;
  }
`;
const PersonalInfoTab = styled.div`
  height: 200px;
  width: 100%;
  // background-color: aqua;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${Responsive.laptop} {
    height: 140px;
  }
  ${Responsive.tablet} {
    height: 140px;
  }
  ${Responsive.mobile} {
    height: 130px;
  }
`;
const CustomImgUploadDiv = styled.div`
  margin-left: 50px;
  height: 105px;
  width: 105px;
  background-color: #ebe8fc;
  border-radius: 50%;
  position: relative;
  ${Responsive.laptop} {
    height: 70px;
    width: 70px;
    margin-left: 35px;
    cursor: pointer;
  }
  ${Responsive.tablet} {
    height: 70px;
    width: 70px;
    margin-left: 35px;
    cursor: pointer;
  }
  ${Responsive.mobile} {
    height: 70px;
    width: 70px;
    margin-left: 35px;
    cursor: pointer;
  }
`;
const FileUpLoadInput = styled.input`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
`;
const UploadFileIcon = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  ${Responsive.laptop} {
    /* height: 25px; */
    /* left: 22px;
    top: 25px; */
  }
  ${Responsive.tablet} {
    /* height: 25px; */
    /* left: 22px;
    top: 25px; */
  }
  ${Responsive.mobile} {
    /* height: 25px; */
    /* left: 22px;
    top: 25px; */
  }
`;
const UploadFileText = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.Primary};
  margin: unset;
  margin-left: 30px;
  font-weight: 600;
  user-select: none;
  ${Responsive.laptop} {
    font-size: 14px;
    margin-left: 20px;
    font-weight: 400;
  }
  ${Responsive.tablet} {
    font-size: 14px;
    margin-left: 20px;
    font-weight: 400;
  }
  ${Responsive.mobile} {
    font-size: 14px;
    margin-left: 20px;
    font-weight: 400;
  }
`;
// id card style
const IdCardContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px;
  ${Responsive.laptop} {
    padding-left: 35px;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  ${Responsive.tablet} {
    padding-left: 35px;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  ${Responsive.mobile} {
    padding-left: 20px;
    padding-top: 35px;
    padding-right: 20px;
    padding-bottom: 35px;
  }
`;
const IdCardDiv = styled.div``;
const IdCardTitle = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: #636381;
  margin: unset;
  ${Responsive.laptop} {
    font-size: 14px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
  }
  ${Responsive.mobile} {
    font-size: 14px;
  }
`;
const IdCardUploadContainer = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: flex-start;
  ${Responsive.laptop} {
    margin-top: 30px;
  }
  ${Responsive.tablet} {
    margin-top: 30px;
  }
  ${Responsive.mobile} {
    margin-top: 30px;
    align-items: center;
    flex-direction: column;
  }
`;
const IdCardFrontImgBox = styled.div`
  height: 160px;
  width: 25%;
  border: 2px dashed #ced0dd;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Responsive.screens.NarrowNavBar} {
    width: 30%;
  }
  ${Responsive.laptop} {
    height: 110px;
    width: 25%;
    border-radius: 6px;
  }
  ${Responsive.tablet} {
    height: 110px;
    width: 30%;
    border-radius: 6px;
  }
  ${Responsive.mobile} {
    height: 170px;
    width: 100%;
    border-radius: 6px;
  }
`;
const IdCardBackImgBox = styled.div`
  height: 160px;
  width: 25%;
  border: 2px dashed #ced0dd;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  ${Responsive.screens.NarrowNavBar} {
    width: 30%;
  }
  ${Responsive.laptop} {
    height: 110px;
    width: 25%;
    border-radius: 6px;
  }
  ${Responsive.tablet} {
    height: 110px;
    width: 30%;
    border-radius: 6px;
  }
  ${Responsive.mobile} {
    height: 170px;
    width: 100%;
    border-radius: 6px;
    margin-left: unset;
    margin-top: 25px;
  }
`;
const IdCardPlusICon = styled(PlusIcon)`
  color: #aaaabe;
  height: 15px;
  width: 15px;
  margin-right: 20px;
  ${Responsive.laptop} {
    height: 14px;
    width: 14px;
    margin-right: 15px;
  }
  ${Responsive.tablet} {
    height: 14px;
    width: 14px;
    margin-right: 15px;
  }
`;
const IdCardFrotInput = styled.input`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;
const IdCardFrontTitle = styled.h1`
  font-size: 18px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: #aaaabe;
  margin: unset;
  display: block;
  ${Responsive.laptop} {
    font-size: 14px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
  }
  ${Responsive.mobile} {
    font-size: 16px;
  }
`;
const IdCardFrontPageText = styled.h1`
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  color: #aaaabe;
  margin: unset;
  ${Responsive.laptop} {
    font-size: 12px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
  }
`;
// contact info style
const ContactInfoContainer = styled.div`
  padding-left: 50px;
  ${Responsive.laptop} {
    padding-left: 35px;
  }
  ${Responsive.tablet} {
    padding-left: 35px;
  }
  ${Responsive.mobile} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const ContactInfoTitle = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: #636381;
  margin: unset;
  margin-top: 40px;
  ${Responsive.laptop} {
    font-size: 14px;
    margin-top: 35px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
    margin-top: 35px;
  }
  ${Responsive.mobile} {
    font-size: 14px;
    margin-top: 30px;
  }
`;
const ContactInfoInputDiv = styled.div`
  margin-top: 35px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  ${Responsive.laptop} {
    margin-top: 35px;
    padding-bottom: 50px;
  }
  ${Responsive.tablet} {
    margin-top: 35px;
    padding-bottom: 50px;
  }
  ${Responsive.mobile} {
    margin-top: 30px;
    padding-bottom: 30px;
    flex-direction: column;
  }
`;
const ContactInfoMobileInput = styled.input`
  /* width: 30%; */
  height: 50px;
  border-radius: 6px;
  outline: none;
  border: 1px solid #ced0dd;
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  padding-left: 30px;
  color: #ced0dd;
  ${Responsive.laptop} {
    /* width: 30%; */
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
  }
  ${Responsive.tablet} {
    /* width: 30%; */
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
  }
  ${Responsive.mobile} {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 15px;
  }
`;
const ContactInfoEmailInput = styled.input`
  /* width: 30%; */
  height: 50px;
  border-radius: 6px;
  outline: none;
  border: 1px solid #ced0dd;
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  padding-left: 30px;
  color: #ced0dd;
  ${Responsive.laptop} {
    /* width: 30%; */
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
  }
  ${Responsive.tablet} {
    /* width: 30%; */
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
  }
  ${Responsive.mobile} {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 15px;
  }
`;
const ContactSaveChangeButton = styled.button`
  width: 30%;
  height: 50px;
  border: 1px solid ${Colors.Primary};
  border-radius: 6px;
  color: #fff;
  background-color: ${Colors.Primary};
  outline: none;
  font-size: 14px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  margin-right: 50px;
  margin-top: 29px;
  &:focus,
  &:hover {
    outline: none;
    background-color: ${Colors.HoverRed};
  }
  ${Responsive.laptop} {
    width: 30%;
    height: 42px;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 35px;
  }
  ${Responsive.tablet} {
    width: 30%;
    height: 42px;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 35px;
  }
  ${Responsive.mobile} {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: unset;
  }
`;
// tab two bank addres style
const BankInfoContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 60px;
  width: 100%;
  border-radius: 6px;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // background-color: green;
  ${Responsive.laptop} {
    padding-top: 35px;
    padding-bottom: 40px;
  }
  ${Responsive.mobile} {
    // margin-left: 10px;
    // margin-right: 10px;
    flex-direction: column;
  }
`;
const BankNameInput = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #ced0dd;
  border-radius: 6px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: normal;
  font-family: ${Fonts.FiraGORegular};
  color: #aaaabe;
  background-color: inherit;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    /* width: 23%; */
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    /* width: 23%; */
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 40px;
    /* width: 90%; */
    border-radius: 4px;
    padding-left: 15px;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const BankAddressInput = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #ced0dd;
  border-radius: 6px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: normal;
  font-family: ${Fonts.FiraGORegular};
  color: #aaaabe;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    /* width: 23%; */
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    /* width: 23%; */
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 40px;
    /* width: 90%; */
    border-radius: 4px;
    padding-left: 15px;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const BankAccountHolderInput = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #ced0dd;
  border-radius: 6px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: normal;
  font-family: ${Fonts.FiraGORegular};
  color: #aaaabe;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    /* width: 23%; */
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    /* width: 23%; */
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 40px;
    /* width: 90%; */
    border-radius: 4px;
    padding-left: 15px;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const BankSaveChangeButton = styled.button`
  height: 50px;
  width: 20%;
  margin-top: 29px;
  border-radius: 6px;
  border: 1px solid ${Colors.Primary};
  background-color: ${Colors.Primary};
  outline: none;
  font-size: 14px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: #fff;
  &:focus,
  &:hover {
    outline: none;
    background-color: ${Colors.HoverRed};
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 23%;
    border-radius: 4px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 23%;
    border-radius: 4px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 40px;
    width: 90%;
    border-radius: 4px;
    font-size: 12px;
  }
`;
// password change tab style
const ChangePasswordContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 60px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;
const ChangePasswordInputDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding-left: 3%; */
  padding-bottom: 50px;
  ${Responsive.mobile} {
    flex-direction: column;
  }
`;
const CurrentPasswordInput = styled.input`
  height: 50px;
  width: 20%;
  /* margin-right: 30px; */
  border: 1px solid #ced0dd;
  border-radius: 6px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: normal;
  font-family: ${Fonts.FiraGORegular};
  color: #aaaabe;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 20%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 20%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 40px;
    width: 90%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const NewPasswordInput = styled.input`
  height: 50px;
  width: 20%;
  /* margin-right: 30px; */
  border: 1px solid #ced0dd;
  border-radius: 6px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: normal;
  font-family: ${Fonts.FiraGORegular};
  color: #aaaabe;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 20%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 20%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 40px;
    width: 90%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const RepeatPasswordInput = styled.input`
  height: 50px;
  width: 20%;
  /* margin-right: 30px; */
  border: 1px solid #ced0dd;
  border-radius: 6px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: normal;
  font-family: ${Fonts.FiraGORegular};
  color: #aaaabe;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 20%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 20%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 40px;
    width: 90%;
    border-radius: 4px;
    padding-left: 20px;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const PasswordSaveChangeButton = styled.button`
  height: 50px;
  width: 20%;
  border-radius: 6px;
  border: 1px solid ${Colors.Primary};
  background-color: ${Colors.Primary};
  outline: none;
  font-size: 14px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: #fff;
  &:focus,
  &:hover {
    outline: none;
    background-color: ${Colors.HoverRed};
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 23%;
    border-radius: 4px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 23%;
    border-radius: 4px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 40px;
    width: 90%;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 8px;
  }
`;
const PasswordRequirements = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  color: #b6b8c5;
  margin: unset;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  padding-left: 3%;
  ${Responsive.laptop} {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 1.5%;
  }
  ${Responsive.tablet} {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 1%;
  }
  ${Responsive.mobile} {
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 7px;
    padding-left: 5%;
  }
`;
const UploadedImagePreviewImg = styled.img`
  position: absolute;
  width: 100%;
  z-index: 0;
  height: 100%;
  object-fit: cover;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
    ${Responsive.mobile} {
      width: 100%;
    }
`;
const InputValueText = styled.span`
  font-size: 12px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.TitleBlue};
  margin-bottom: 10px;
`;

const BankInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 20%;
    ${Responsive.mobile} {
      width: 90%;
      margin-top: 10px;
    }
`;
const BankInfoValueText = styled.span`
  font-size: 12px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.TitleBlue};
  margin-bottom: 10px;
    ${Responsive.mobile} {
      margin-bottom: 0px;
    }
`;


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Profile: React.FC<any> = (props) => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  const dispatch = useAppDispatch();
  const history = useHistory();
  // tab one
  const [email, setEmail] = useState<string>(auth.user?.email || "");
  const [phone, setPhone] = useState<string>(auth.user?.phone || "");
  // tab two
  const [bankName, setBankName] = useState<string>(auth.user?.bankName || "");
  const [bankAccountNumber, setBankAccountNumber] = useState<string>(
    auth.user?.bankAccountNumber || ""
  );
  const [bankAccountHolder, setBankAccountHolder] = useState<string>(
    auth.user?.bankAccountHolder || ""
  );
  // tab three
  const [currentPassword, setCurrentPassword] = useState<string>(""); //current password
  const [newPassword, setNewPassword] = useState<string>(""); //new password
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>(""); //repeat password
  const [updatePasswordSmsCodeSent, setUpdatePasswordSmsCodeSent] = useState(false);
  const [updatePasswordSmsCode, setUpdatePasswordSmsCode] = useState('');

  // popUp
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (auth.user) {
      setEmail(auth.user.email);
      setPhone(auth.user.phone);
      setBankName(auth.user?.bankName);
      setBankAccountNumber(auth.user?.bankAccountNumber);
      setBankAccountHolder(auth.user?.bankAccountHolder);
    }
    return () => { };
  }, [auth.user]);

  const classes = useStyles();

  let tab =
    (props.match.params.tab as "personal" | "bank" | "password") || "personal";

  const [activeTabIndex, setActiveTabIndex] = React.useState(
    tab == "bank" ? 1 : tab == "password" ? 2 : 0
  );

  const handleSwitchTab = (
    event: React.ChangeEvent<{}>,
    newTabIndex: number
  ) => {
    setActiveTabIndex(newTabIndex);
  };

  const [
    isBankInfoDisclaimerModalOpen,
    setIsBankInfoDisclaimerModalOpen,
  ] = useState(false);

  const saveBankInfo = () => {
    dispatch(updateProfile({ bankName, bankAccountNumber, bankAccountHolder }))
      .then(unwrapResult)
      .then(({ user }) => {
        // alert("ინფორმაცია წარმატებით დამახსოვრდა!");
        const msg = meaningfulErrorMessage(
          user,
          "ინფორმაცია წარმატებით დამახსოვრდა!"
        ); //lm
        setErrorMessage(msg);
        setIsBankInfoDisclaimerModalOpen(false);
      })
      .catch((error) => {
        console.log(error);
        // alert(
        //   meaningfulErrorMessage(error) || "გთხოვთ, სცადოთ თავიდან."
        // );
        const msg = meaningfulErrorMessage(error || "გთხოვთ, სცადოთ თავიდან."); //lm
        setErrorMessage(msg);
      });
  };

  const submitSendSMSCode = () => {
    dispatch(
      sendUpdatePasswordSMS()
    )
      .then(unwrapResult)
      .then(({ success }) => {
        // alert("ინფორმაცია წარმატებით დამახსოვრდა!");
        setUpdatePasswordSmsCodeSent(true);
        const msg = "SMS კოდი გამოგზავნილია!";
        // const msg = meaningfulErrorMessage(
        //   user,
        //   "SMS კოდი გამოგზავნილია!"
        // ); //lm
        setErrorMessage(msg);
      })
      .catch((error) => {
        console.log(error);
        // alert(meaningfulErrorMessage(error) || "გთხოვთ, სცადოთ თავიდან." );

        const msg = meaningfulErrorMessage(
          error || "გთხოვთ, სცადოთ თავიდან."
        ); //lm
        setErrorMessage(msg);
      });
  };

  const submitCheckSMSCode = (smsCode: string) => {
    dispatch(
      checkUpdatePasswordSMS({ smsCode })
    )
      .then(unwrapResult)
      .then(({ isValid }) => {
        // alert("ინფორმაცია წარმატებით დამახსოვრდა!");
        if (isValid) {
          submitUpdatePasswordActualSave();
        } else {
          setErrorMessage("SMS კოდი არასწორია.");
        }
      })
      .catch((error) => {
        console.log(error);
        // alert(meaningfulErrorMessage(error) || "გთხოვთ, სცადოთ თავიდან." );

        const msg = meaningfulErrorMessage(
          error || "გთხოვთ, სცადოთ თავიდან."
        ); //lm
        setErrorMessage(msg);
      });
  };

  const submitUpdatePasswordActualSave = () => {
    dispatch(
      updateProfile({
        currentPassword,
        password: newPassword,
        passwordConfirmation,
      })
    )
      .then(unwrapResult)
      .then(({ user }) => {
        setErrorMessage("პაროლი წარმატებით განახლდა!");
        setCurrentPassword('');
        setNewPassword('');
        setPasswordConfirmation('');
        setUpdatePasswordSmsCodeSent(false);
        setUpdatePasswordSmsCode('');
      })
      .catch((error) => {
        console.log(error);
        const msg = meaningfulErrorMessage(
          error || "გთხოვთ, სცადოთ თავიდან."
        ); //lm
        setErrorMessage(msg);
      });
  }

  return (
    <ProfileContainer>
      <div className={classes.root}>
        <AppBar position="static">
          <TabsStyled
            value={activeTabIndex}
            onChange={handleSwitchTab}
            aria-label="simple tabs example"
          >
            <TabStyle label="პერსონალური ინფორმაცია" {...a11yProps(0)} />
            <TabStyle label="საბანკო რეკვიზიტები" {...a11yProps(1)} />
            <TabStyle label="პაროლის შეცვლა" {...a11yProps(2)} />
          </TabsStyled>
        </AppBar>

        {/* personal info tab  1*/}
        <TabPanel value={activeTabIndex} index={0}>
          <PersonalInfoTab>
            <CustomImgUploadDiv>
              <FileUpLoadInput
                id="img-upload"
                name="myfile"
                type="file"
                onChange={(event) => {
                  const files = event.target.files;
                  if (!files || !files.length) {
                    setErrorMessage("გთხოვთ, აირჩიოთ ფაილი.");
                    return;
                  }
                  const file = files[0]; // for now only single file upload
                  dispatch(uploadFile({ file }))
                    .then(unwrapResult)
                    .then(({ path }) => {
                      dispatch(updateProfile({ avatarPath: path }))
                        .then(unwrapResult)
                        .catch((error) => {
                          console.log(
                            "error while updating avatar path",
                            error
                          );
                          //  alert(meaningfulErrorMessage(error) || "სურათის განახლება ვერ მოხერხდა.");

                          const msg = meaningfulErrorMessage(
                            error || "სურათის განახლება ვერ მოხერხდა."
                          ); //lm
                          setErrorMessage(msg);
                        });
                    })
                    .catch((error) => {
                      console.log("error while uploading avatar", error);
                      //  alert(meaningfulErrorMessage(error) || "ფაილის ატვირთვა ვერ მოხერხდა.");

                      const msg = meaningfulErrorMessage(
                        error || "ფაილის ატვირთვა ვერ მოხერხდა."
                      ); //lm
                      setErrorMessage(msg);
                    });
                }}
              />
              <UploadFileIcon src={
                auth.user?.avatar
                  ? `${viewUploadedFileUrl}/${auth.user?.avatar}`
                  : defaultAvatarImage
              } alt="AddPhotoIcon" />
            </CustomImgUploadDiv>
            <UploadFileText>ატვირთე ფოტო</UploadFileText>
          </PersonalInfoTab>

          <ProfileDecoreLine />

          {/* ID card div */}
          <IdCardContainer>
            <IdCardDiv>
              <IdCardTitle>პირადობის მოწმობა</IdCardTitle>
              <IdCardUploadContainer>
                <IdCardFrontImgBox>
                  {auth.user?.idCardFront && (
                    <UploadedImagePreviewImg
                      src={`${viewUploadedFileUrl}/${auth.user?.idCardFront}`}
                    />
                  )}
                  <IdCardFrotInput
                    id="id-front-side"
                    name="id-front-side"
                    type="file"
                    onChange={(event) => {
                      const files = event.target.files;
                      if (!files || !files.length) {
                        setErrorMessage("გთხოვთ, აირჩიოთ ფაილი.");
                        return;
                      }
                      const file = files[0]; // for now only single file upload
                      dispatch(uploadFile({ file }))
                        .then(unwrapResult)
                        .then(({ path }) => {
                          dispatch(updateProfile({ idCardFrontPath: path }))
                            .then(unwrapResult)
                            .catch((error) => {
                              console.log(
                                "error while updating avatar path",
                                error
                              );
                              // alert(meaningfulErrorMessage(error) || "სურათის განახლება ვერ მოხერხდა.");

                              const msg = meaningfulErrorMessage(
                                error,
                                "სურათის განახლება ვერ მოხერხდა."
                              ); //lm
                              setErrorMessage(msg);
                            });
                        })
                        .catch((error) => {
                          console.log("error while uploading avatar", error);
                          // alert(meaningfulErrorMessage(error) || "ფაილის ატვირთვა ვერ მოხერხდა.");

                          const msg = meaningfulErrorMessage(
                            error || "ფაილის ატვირთვა ვერ მოხერხდა."
                          ); //lm
                          setErrorMessage(msg);
                        });
                    }}
                  />
                  <IdCardPlusICon />
                  <div>
                    <IdCardFrontTitle>პირადობა</IdCardFrontTitle>
                    <IdCardFrontPageText>გვერდი 1</IdCardFrontPageText>
                  </div>
                </IdCardFrontImgBox>

                <IdCardBackImgBox>
                  {auth.user?.idCardBack && (
                    <UploadedImagePreviewImg
                      src={`${viewUploadedFileUrl}/${auth.user?.idCardBack}`}
                    />
                  )}
                  <IdCardFrotInput
                    id="id-back-side"
                    name="id-back-side"
                    type="file"
                    onChange={(event) => {
                      const files = event.target.files;
                      if (!files || !files.length) {
                        setErrorMessage("გთხოვთ, აირჩიოთ ფაილი.");
                        return;
                      }
                      const file = files[0]; // for now only single file upload
                      dispatch(uploadFile({ file }))
                        .then(unwrapResult)
                        .then(({ path }) => {
                          dispatch(updateProfile({ idCardBackPath: path }))
                            .then(unwrapResult)
                            .catch((error) => {
                              console.log(
                                "error while updating avatar path",
                                error
                              );
                              setErrorMessage(
                                meaningfulErrorMessage(error) ||
                                "სურათის განახლება ვერ მოხერხდა."
                              );
                            });
                        })
                        .catch((error) => {
                          console.log("error while uploading avatar", error);
                          setErrorMessage(
                            meaningfulErrorMessage(error) ||
                            "ფაილის ატვირთვა ვერ მოხერხდა."
                          );
                        });
                    }}
                  />
                  <IdCardPlusICon />
                  <div>
                    <IdCardFrontTitle>პირადობა</IdCardFrontTitle>
                    <IdCardFrontPageText>გვერდი 2</IdCardFrontPageText>
                  </div>
                </IdCardBackImgBox>
              </IdCardUploadContainer>
            </IdCardDiv>
          </IdCardContainer>
          {/* ID card div */}

          <ProfileDecoreLine />

          {/* contact infot */}
          <ContactInfoContainer>
            <ContactInfoTitle>საკონტაქტო ინფორმაცია</ContactInfoTitle>
            <ContactInfoInputDiv>
              <InputWrapper>
                <InputValueText>ტელეფონი</InputValueText>
                <ContactInfoMobileInput
                  type="number"
                  name="mobile-number"
                  id="mobile-number"
                  placeholder="555 123 456"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <InputValueText>ელ.ფოსტა</InputValueText>
                <ContactInfoEmailInput
                  type="email"
                  id="contact-email"
                  placeholder="example@mail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputWrapper>
              <ContactSaveChangeButton
                onClick={() => {
                  dispatch(updateProfile({ phone, email }))
                    .then(unwrapResult)
                    .then(({ user }) => {
                      // alert("ინფორმაცია წარმატებით დამახსოვრდა!");  //lm
                      const msg = meaningfulErrorMessage(
                        user,
                        "ინფორმაცია წარმატებით დამახსოვრდა!"
                      );
                      setErrorMessage(msg);
                    })
                    .catch((error) => {
                      console.log(error);
                      // alert(
                      //   meaningfulErrorMessage(error) ||"გთხოვთ, სცადოთ თავიდან."
                      // );
                      const msg = meaningfulErrorMessage(
                        error || "გთხოვთ, სცადოთ თავიდან."
                      );
                      setErrorMessage(msg);
                    });
                }}
              >
                ცვლილების შენახვა
              </ContactSaveChangeButton>
            </ContactInfoInputDiv>
          </ContactInfoContainer>
          <PopUp
            popUpVisible={!!errorMessage}
            popUpClose={() => setErrorMessage("")}
            popUpMessage={errorMessage}
          />
          {/* contact infot */}
        </TabPanel>
        {/* personal info tab  1*/}

        {/* bank inputs tab 2 */}
        <TabPanel value={activeTabIndex} index={1}>
          <BankInfoContainer>
            <BankInfoWrapper>
              <BankInfoValueText>ბანკის დასახელება</BankInfoValueText>
              <BankNameInput
                type="text"
                id="bank-name"
                placeholder="ბანკის დასახელება"
                name="bank-name"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
            </BankInfoWrapper>
            <BankInfoWrapper>
              <BankInfoValueText>ანგარიშის ნომერი</BankInfoValueText>
              <BankAddressInput
                type="text"
                id="bank-address"
                name="bank-address"
                placeholder="ანგარიშის ნომერი"
                value={bankAccountNumber}
                onChange={(e) => setBankAccountNumber(e.target.value)}
              />
            </BankInfoWrapper>
            <BankInfoWrapper>
              <BankInfoValueText>ანგარიშის მფლობელი</BankInfoValueText>
              <BankAccountHolderInput
                type="text"
                id="bank-account-holder"
                name="bank-account-holder"
                placeholder="ანგარიშის მფლობელი"
                value={bankAccountHolder}
                onChange={(e) => setBankAccountHolder(e.target.value)}
              />
            </BankInfoWrapper>

            <BankSaveChangeButton
              onClick={() => setIsBankInfoDisclaimerModalOpen(true)}
            >
              ცვლილების შენახვა
            </BankSaveChangeButton>
            <PopUp
              popUpVisible={!!errorMessage}
              popUpClose={() => setErrorMessage("")}
              popUpMessage={errorMessage}
            />
          </BankInfoContainer>
          {isBankInfoDisclaimerModalOpen ? (
            <DisclaimerModal
              disclaimerTextComponent={
                <>
                  <p>
                    ვეთანხმები ჩემს მიერ საიტზე შევსებულ ყველა ინფორმაციას და
                    ვადასტურებ, რომ ის არის უტყუარი და მე ვარ პასუხისმგებელი
                    ჩემი მხრიდან არაზუსტი/არასრული ინფორმაციის მოწოდებით
                    გამოწვეულ ნებისმიერ შედეგზე.
                  </p>
                  <p>ასევე:</p>
                  <ul style={{ paddingLeft: 15 }}>
                    <li>
                      თანახმა ვარ, რომ ჩემთან ანგარიშსწორება განხორციელდება ჩემს
                      მიერ მითითებულ საბანკო ანგარიშზე და შესაბამისად ჩემს მიერ
                      არაზუსტად/არასრულად ინფორმაციის მითითების შემთხვევაში
                      თანხის სხვა პირისთვის ჩარიცხვის შემთხვევაში არ მექნება
                      პრეტენზია;
                    </li>
                    <li>
                      თანახმა ვარ, რომ ჩემს მიერ მითითებულ საკონტაქტო
                      ნომერზე/ელ.ფოსტაზე გამოიგზავნოს ჩემს შესახებ ინფორმაცია და
                      ჩემს მიერ არაზუსტად/არასრულად ინფორმაციის მითითების
                      შემთხვევაში ინფორმაციის სხვა პირისთვის მიწოდებაზე ან/და
                      ინფორმაციის ვერ მიღებაზე პასუხისმგებელი ვარ მხოლოდ მე და
                      არანაირი პრეტენზია არ მექნება კომპანიასთან.
                    </li>
                  </ul>
                  <p>
                    თანახმა ვარ, რომ სს "სადაზღვევო კომპანია ალფა"–მ "პერსონალურ
                    მონაცემთა დაცვის შესახებ" საქართველოს კანონით დადგენილი
                    წესით, ჩემთვის მომსახურების გაწევის მიზნით, დაამუშავოს
                    (აღრიცხავს, შეინახავს, მიაწვდის შესაბამის
                    პირებს/ორგანიზაციებს და ა.შ.) ჩემს მიერ შევსებული და
                    კომპანიისათვის გადაცემული ინფორმაცია. ჩემთვის ცნობილია, რომ
                    ნებისმიერ დროს შემიძლია შევცვალო ჩემს მიერ მითითებული
                    ინფორმაცია და ასევე მოვითხოვო კომპანიისგან ჩემს შესახებ
                    არსებული ინფორმაციის რედაქტირება ან/და წაშლა.
                  </p>
                </>
              }
              onAccept={() => saveBankInfo()}
              onClose={() => setIsBankInfoDisclaimerModalOpen(false)}
            />
          ) : null}
          <PopUp
            popUpVisible={!!errorMessage}
            popUpClose={() => setErrorMessage("")}
            popUpMessage={errorMessage}
          />
        </TabPanel>
        {/* bank inputs tab 2 */}

        {/* change password tab 3 */}
        <TabPanel value={activeTabIndex} index={2}>
          <ChangePasswordContainer>
            <ChangePasswordInputDiv>
              <CurrentPasswordInput
                type="password"
                id="current-password"
                placeholder="მიმდინარე პაროლი"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <NewPasswordInput
                type="password"
                id="new-password"
                placeholder="ახალი პაროლი"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <RepeatPasswordInput
                type="password"
                id="repeat-password"
                placeholder="გაიმეორე პაროლი"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />

              {updatePasswordSmsCodeSent && (
                <RepeatPasswordInput
                  type="text"
                  id="update-password-sms-code"
                  placeholder="SMS კოდი"
                  value={updatePasswordSmsCode}
                  onChange={(e) => setUpdatePasswordSmsCode(e.target.value)}
                />
              )}

              <PasswordSaveChangeButton
                onClick={() => {
                  const invalidField = [
                    { validIf: !!currentPassword, messageIfNot: "გთხოვთ, შეიყვანოთ მიმდინარე პაროლი", },
                    { validIf: !!newPassword, messageIfNot: "გთხოვთ, შეიყვანოთ ახალი პაროლი", },
                    { validIf: !!passwordConfirmation, messageIfNot: "გთხოვთ, გაიმეოროთ პაროლი", },
                    { validIf: !updatePasswordSmsCodeSent || (updatePasswordSmsCodeSent && !!updatePasswordSmsCode), messageIfNot: "გთხოვთ, შეიყვანოთ SMS კოდი", },
                    { validIf: !updatePasswordSmsCodeSent || (updatePasswordSmsCodeSent && updatePasswordSmsCode.length == 4), messageIfNot: "SMS კოდი უნდა იყოს 4-ნიშნა", },
                  ].find(f => !f.messageIfNot);
                  if (invalidField) {
                    setErrorMessage(invalidField.messageIfNot);
                    return;
                  }
                  if (!updatePasswordSmsCodeSent) {
                    submitSendSMSCode();
                  } else {
                    submitCheckSMSCode(updatePasswordSmsCode);
                    // this will be called after checking SMS code
                    // submitUpdatePasswordActualSave();
                  }
                }}
              >
                ცვლილების შენახვა
              </PasswordSaveChangeButton>
            </ChangePasswordInputDiv>
            <PasswordRequirements>
              პაროლი უნდა შედგებოდეს მინიმუმ 8 სიმბოლოსგან
            </PasswordRequirements>
            <PasswordRequirements>
              პაროლი უნდა შეიცავდეს დიდ და პატარა ლათინურ ასოებს
            </PasswordRequirements>
            <PasswordRequirements>
              პაროლი უნდა შეიცავდეს ციფრებს
            </PasswordRequirements>
          </ChangePasswordContainer>
          <PopUp
            popUpVisible={!!errorMessage}
            popUpClose={() => setErrorMessage("")}
            popUpMessage={errorMessage}
          />
        </TabPanel>
        {/* change password tab 3 */}
      </div>
    </ProfileContainer>
  );
};
export default Profile;
