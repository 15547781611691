import Responsive from "config/Responsive";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fbfbfb;
  margin-left: 50px;
  width: ${Responsive.constants.rightSidebarWidth.default}px;
  ${Responsive.desktop} {
    margin-left: 30px;
    min-width: ${Responsive.constants.rightSidebarWidth.laptop}px;
  }
  ${Responsive.laptop} {
    margin-left: 30px;
    min-width: ${Responsive.constants.rightSidebarWidth.laptop}px;
  }
  ${Responsive.tablet} {
    margin-left: 15px;
    min-width: ${Responsive.constants.rightSidebarWidth.tablet}px;
  }
  ${Responsive.mobile} {
    margin: unset;
    width: 100%;
    min-width: unset;
  }
`;
const Center = styled.div``;
const RightSide = styled.div`
  width: 100%;
`;

export interface RightSidebarProps {
  rightSide: any;
}

const RightSidebar: React.FC<RightSidebarProps> = ({ children, rightSide }) => {
  return (
    <Wrapper>
      <Center>{children}</Center>
      <RightSide>{rightSide}</RightSide>
    </Wrapper>
  );
};

export default RightSidebar;
