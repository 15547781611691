import * as React from "react";

function PlusIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        data-name="Group 2720"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
      >
        <path data-name="Path 9514" d="M22 12H2" />
        <path data-name="Path 9515" d="M12 22V2" />
      </g>
    </svg>
  );
}

export default PlusIcon;

