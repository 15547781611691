import React, { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../slices";
import { resetPasswordCheckPersonalNumber } from "../../slices/authSlice";
import { AuthState } from "../../domain";
import { useAppDispatch } from "../../config/store";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
import Responsive from "../../config/Responsive";
import meaningfulErrorMessage from "../../utils/meaningfulErrorMessage";

const ResetPasswordRightSide = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ResetPasswordTitle = styled.h1`
  font-size: 36px;
  color: ${Colors.TitleBlue};
  font-family: ${Fonts.FiraGOMtavruliRegular};
  margin: unset;
  margin-bottom: 55px;
  user-select: none;
  ${Responsive.desktop} {
    font-size: 36px;
    margin-bottom: 55px;
  }
  ${Responsive.laptop} {
    font-size: 22px;
    margin-bottom: 40px;
  }
  ${Responsive.tablet} {
    font-size: 22px;
    margin-bottom: 40px;
  }
  ${Responsive.mobile} {
    font-size: 16px;
    margin-bottom: 40px;
    margin-top: 150px;
  }
`;
const ResetPasswordButton = styled.button`
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  height: 70px;
  line-height: 77px;
  border-radius: 10px;
  background-color: ${Colors.Primary};
  font-family: ${Fonts.FiraGOMtavruliBold};
  outline: none;
  border: unset;
  font-size: 20px;
  color: #ffffff;
  margin-top: 30px;
  &:focus,
  &:hover {
    outline: none;
    border: unset;
    background-color: ${Colors.HoverRed};
  }
  ${Responsive.desktop} {
    max-width: 440px;
    min-width: 300px;
    height: 70px;
    line-height: 77px;
    border-radius: 10px;
    margin-top: 30px;
    font-size: 20px;
  }
  ${Responsive.laptop} {
    max-width: 320px;
    min-width: 300px;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;
    margin-top: 25px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    max-width: 300px;
    min-width: 300px;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;
    margin-top: 25px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    max-width: 300px;
    min-width: 300px;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;
    margin-top: 25px;
    font-size: 14px;
  }
`;
const ResetPasswordInput = styled.input`
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  margin: unset;
  outline: none;
  color: #ced0dd;
  font-size: 14px;
  padding-left: 30px;
  border: 1px solid #ced0dd;
  font-family: ${Fonts.FiraGORegular};
  ${Responsive.desktop} {
    max-width: 440px;
    min-width: 300px;
    height: 70px;
    border-radius: 10px;
    font-size: 14px;
  }
  ${Responsive.laptop} {
    max-width: 320px;
    min-width: 300px;
    height: 50px;
    border-radius: 6px;
    font-size: 12px;
  }
  ${Responsive.tablet} {
    max-width: 300px;
    min-width: 300px;
    height: 50px;
    border-radius: 6px;
    font-size: 12px;
  }
  ${Responsive.mobile} {
    max-width: 300px;
    min-width: 300px;
    height: 50px;
    border-radius: 6px;
    font-size: 12px;
  }
`;
const ResetPasswordDiv = styled.div`
  width: 100%;
  z-index: 1;
`;

const ResetPassword: React.FC = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  const [personalNumber, setPersonalNumber] = useState<string>("");

  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <ResetPasswordDiv>
      <ResetPasswordRightSide>
        <ResetPasswordTitle>პაროლის აღდგენა</ResetPasswordTitle>
        <ResetPasswordInput
          type="text"
          name="პირადი ნომერი"
          placeholder="პირადი ნომერი"
          onChange={(e) => setPersonalNumber(e.target.value)}
        />
        <ResetPasswordButton
          onClick={() => {
            dispatch(resetPasswordCheckPersonalNumber({ personalNumber }))
              .then(unwrapResult)
              .then((result) => {
                if (result.success) {
                  history.push("/reset-password-step-two");
                } else {
                  alert(
                    meaningfulErrorMessage(result, "გთხოვთ, სცადოთ თავიდან.")
                  );
                }
              })
              .catch((error) => {
                console.log(error);
                alert(meaningfulErrorMessage(error, "გთხოვთ, სცადოთ თავიდან."));
              });
          }}
        >
          შემდეგი
        </ResetPasswordButton>
      </ResetPasswordRightSide>
    </ResetPasswordDiv>
  );
};

export default ResetPassword;
