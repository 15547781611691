import React, { PropsWithChildren } from 'react';
import { UploadedFile } from '../domain';
import styled from 'styled-components';
import Colors from '../styles/Colors';
import Fonts from '../styles/Fonts';

const CurrentUploadDocuments = styled.a`
    font-size: 17px;
    color: ${Colors.Gray};
    margin: unset;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    display: block;
    margin-top: 20px;
    text-decoration: underline;
`;

const UploadedFileList = ({ files }: { files?: UploadedFile[] }) => {
    return (
        <>
        {files?.map((uploadedFile, index) => (
                <CurrentUploadDocuments key={uploadedFile.url} href={uploadedFile.url} target={'_blank'}>
                    {/*uploadedFile.fileName*/}
                    ფაილი {index+1}
                </CurrentUploadDocuments>
            ))}
        </>
    );
}

export default UploadedFileList;