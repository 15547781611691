import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';


import styled from 'styled-components';
import Colors from 'styles/Colors';
import Fonts from 'styles/Fonts';
import Responsive from 'config/Responsive';


import "screens/Policies/PolicyStyle.css";
import { PolicyLimit } from 'domain/types';



const ForSpaceDiv = styled.div`
  height: 20px;
  width: 100%;
  background-color: inherit;
`;
const LimitAccordionContainer = styled.div`
  /* background-color: #fbfbfb; */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;
const HealthAccordionWrapper = styled.div`
    height: 100%;
    width: 100%;
`;
const HealthAccordionTitle = styled.h1`
    margin: unset;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: #636381;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    border-radius: 10px;
      ${Responsive.laptop}{
        font-size: 14px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 40px;
      }
      ${Responsive.tablet}{
        font-size: 14px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 40px;
      }
      ${Responsive.mobile}{
        font-size: 14px;
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 20px;
      }
`;
const HealthInsuranceLimitsTabOneWrapper = styled.div`
    background-color: #fff;
`;
const HealtInsuranceOneTable = styled.div`
  padding-right: 26px;
    ${Responsive.mobile}{
      display: none;
    }
`;
const HealtInsuranceOneTableTitle = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin: unset;
    color: #767880;
    line-height: 50px;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
      &:nth-child(1){
        width: 5%;
        /* background-color: red; */
        text-align: center;
      }
      &:nth-child(2){
        width: 35%;
        /* background-color: green; */
        text-align: left;
      }
      &:nth-child(3){
        width: 15%;
        /* background-color: gray; */
        text-align: right;
      }
      &:nth-child(4){
        width: 15%;
        /* background-color: yellow; */
        text-align: right;
      }
      &:nth-child(5){
        width: 15%;
        /* background-color: yellow; */
        text-align: right;
      }
      &:nth-child(6){
        width: 15%;
        /* background-color: yellow; */
        text-align: right;
      }
    ${Responsive.laptop}{
      font-size: 12px;
    }
    ${Responsive.tablet}{
      font-size: 12px;
    }
`;
const HealtInsuranceOneAnswerRow = styled.div`
      width: 100%;
      height: 80px;
      padding-right: 25px;
        &:hover{
          cursor: pointer;
          background-color: #ebe8fc;
          transition: all 200ms;
        }
      ${Responsive.laptop}{
        height: 56px;
      }
      ${Responsive.tablet}{
        height: 56px;
      }
      ${Responsive.mobile}{
        flex-direction: column;
        height: auto;
        text-align: left;
        align-items: flex-start;
        position: relative;
        border-bottom: 1px dashed ${Colors.Gray};
        display: flex;
        padding: 0px 10px;
      }
`;
const HealtInsuranceOneTableAnswer = styled.h1`
    width: 15%;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin: unset;
    color: #767880;
    line-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
      ${HealtInsuranceOneAnswerRow}:hover & {
        color: #636381;
      }
      width: 15%;
      text-align: right;
      &:nth-child(1){
          width: 5%;
          /* background-color: red; */
          text-align: center;
            ${Responsive.mobile}{
              display: none;
            }
        }
        &:nth-child(2){
          width: 35%;
          /* background-color: green; */
          text-align: left;
            ${Responsive.mobile}{
              width: 70%;
              height: 80px;
              line-height: 18px;
              overflow: unset;
              text-overflow: unset;
              white-space: unset;
              padding-top: 13px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical; 
              max-height: 67px;
            }
        }


    ${Responsive.laptop}{
      font-size: 14px;
      line-height: 56px;
    }
    ${Responsive.tablet}{
      font-size: 14px;
      line-height: 56px;
    }
    ${Responsive.mobile}{
      font-size: 14px;
      line-height: unset;
      width: 100%;
      text-align: left;
    }
`;
const HealtInsuranceOneTableAnswerFieldNameXS = styled.span`
  display: none;
  ${Responsive.mobile}{
    display: inline-block; margin-right: 5px;
  }
`;

/*const AswerWrapper = styled.div`
  width: 60%;
  display: inline-block;
    h1 {
      width: 33.33% !important;
      text-align: right !important;
    }
    ${Responsive.mobile}{
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-end;
        h1 {
          height: fit-content !important;
          width: unset !important;
            &:nth-child(1) {
              display: block;
            }
            &:nth-child(2) {
              padding: 0px;
            }
        }
    }
`;*/
const AccordionWrapper = styled(Accordion)`
  padding-top: 10px !important;
    &:first-child {
      padding-top: 5px !important;
    }
`;





const PolicyLimitsAccordion: React.FC<{ limits: PolicyLimit[] }> = ({ limits }) => {

  return (
    <LimitAccordionContainer>
      {limits.map(parentLimit => (
        <AccordionWrapper expanded={parentLimit.name == 'ლიმიტები'}>
          <AccordionSummary>
            <HealthAccordionTitle>{parentLimit.name}</HealthAccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <HealthAccordionWrapper>

              {parentLimit.children?.length > 0 ? (
              <HealthInsuranceLimitsTabOneWrapper>
                  <HealtInsuranceOneTable>
                    <HealtInsuranceOneTableTitle>N </HealtInsuranceOneTableTitle>
                    <HealtInsuranceOneTableTitle>დასახელება</HealtInsuranceOneTableTitle>
                    <HealtInsuranceOneTableTitle>გახარჯულია</HealtInsuranceOneTableTitle>
                    <HealtInsuranceOneTableTitle>დარჩენილია</HealtInsuranceOneTableTitle>
                    <HealtInsuranceOneTableTitle>დაფარვა</HealtInsuranceOneTableTitle>
                    <HealtInsuranceOneTableTitle>მაქს. ლიმიტი</HealtInsuranceOneTableTitle>
                  </HealtInsuranceOneTable>
                  {parentLimit.children.map((childrenLimit, index) => (
                    <HealtInsuranceOneAnswerRow>
                      <HealtInsuranceOneTableAnswer>
                        <HealtInsuranceOneTableAnswerFieldNameXS># </HealtInsuranceOneTableAnswerFieldNameXS>
                        {index + 1}
                      </HealtInsuranceOneTableAnswer>
                      <HealtInsuranceOneTableAnswer>
                        <HealtInsuranceOneTableAnswerFieldNameXS>დასახელება: </HealtInsuranceOneTableAnswerFieldNameXS>
                        {childrenLimit.name}
                      </HealtInsuranceOneTableAnswer>
                        <HealtInsuranceOneTableAnswer>
                          <HealtInsuranceOneTableAnswerFieldNameXS>გახარჯულია: </HealtInsuranceOneTableAnswerFieldNameXS>
                          {childrenLimit.usedAmount} ₾
                        </HealtInsuranceOneTableAnswer>
                        <HealtInsuranceOneTableAnswer>
                          <HealtInsuranceOneTableAnswerFieldNameXS>დარჩენილია: </HealtInsuranceOneTableAnswerFieldNameXS>
                          {childrenLimit.isUnlimited ? 'ულიმიტო' : `${childrenLimit.amount - childrenLimit.usedAmount} ₾`}
                        </HealtInsuranceOneTableAnswer>
                        <HealtInsuranceOneTableAnswer>
                          <HealtInsuranceOneTableAnswerFieldNameXS>დაფარვა: </HealtInsuranceOneTableAnswerFieldNameXS>
                          {childrenLimit.coveragePercent}%
                        </HealtInsuranceOneTableAnswer>
                        <HealtInsuranceOneTableAnswer>
                          <HealtInsuranceOneTableAnswerFieldNameXS>ლიმიტი: </HealtInsuranceOneTableAnswerFieldNameXS>
                          {childrenLimit.isUnlimited ? 'ულიმიტო' : `${childrenLimit.amount} ₾`}
                        </HealtInsuranceOneTableAnswer>
                    </HealtInsuranceOneAnswerRow>
                    ))}
              </HealthInsuranceLimitsTabOneWrapper>
              ) : null}

            </HealthAccordionWrapper>
          </AccordionDetails>
        </AccordionWrapper>
      ))}
    </LimitAccordionContainer>
  );
}

export default PolicyLimitsAccordion
