const Colors = {
  Primary: "#261e56", //"#FF4561",
  PrimaryLight: "#6c61ab",
  PrimaryVeryLight: "#f4f2ff", //"#FDF1F3",
  Secondary: "#d9dbe1", //"#FDF1F3",
  Yellow: "#ffc800",
  Purple: "ff0057",
  Gray: "#767880",
  LightGray: "#767880",
  TitleBlue: "#636381",
  HoverRed: "#261e56",
  BoxShadow: "#9898DB",
  ActiveStatus: "#44C2D3",
  ForOtherIcons: "#ff0000",
  GrayLine: "#F2F5F7",
  Border: "#E5E6ED",
  Input: "#636e72",
  PortGore: "#261E56"
};

// color: ${Colors.Input};

export default Colors;
