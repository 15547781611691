import * as React from "react";

function SvgStar(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={17} {...props}>
      <path
        data-name="star"
        d="M17.61 7.704a1.252 1.252 0 00.327-1.3 1.278 1.278 0 00-1.042-.866l-4.358-.624a.569.569 0 01-.429-.307L10.158.709a1.3 1.3 0 00-2.315 0L5.894 4.602a.569.569 0 01-.429.307l-4.358.624a1.278 1.278 0 00-1.042.866 1.252 1.252 0 00.327 1.3l3.153 3.03a.558.558 0 01.164.5l-.744 4.279a1.233 1.233 0 00.281 1.032 1.317 1.317 0 001.591.309l3.9-2.02a.591.591 0 01.531 0l3.9 2.02a1.3 1.3 0 001.591-.309 1.233 1.233 0 00.281-1.032l-.744-4.279a.557.557 0 01.164-.5z"
        fill="currentColor"
      />
      <path
        data-name="Path 9467"
        d="M6.154 11.985l.429-2.043-.953-.957"
        fill="currentColor"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgStar;
