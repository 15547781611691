import * as React from "react";

function CheckmarkIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15 18"
      {...props}
    >
      <g data-name="Group 4811">
        <g data-name="Group 4637">
          <path
            data-name="Path 9735"
            d="M7.5 0C11.337 0 15 2.547 15 7.236S7.5 18 7.5 18 0 11.925 0 7.236 3.663 0 7.5 0z"
            fill="currentColor"
          />
        </g>
        <path
          data-name="Path 12892"
          d="M7.499 5.31a1.863 1.863 0 11-1.863 1.863A1.863 1.863 0 017.499 5.31z"
          fill="none"
          stroke="#fff"
          strokeWidth={1.4}
        />
      </g>
    </svg>
  );
}

export default CheckmarkIcon;
