import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";


import {useSelector} from "react-redux";
import {
    getPolicies,
    getClaims,
    getClaimHistory,
    uploadFile, addReference,
} from "../../slices/insuranceSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useHistory} from "react-router-dom";
import {RootState} from "../../slices";
import {
    InsuranceState,
    Policy,
    // Claim,
    AddClaimUploadType,
    AddReferenceUploadType,
    AuthState,
} from "../../domain";
import {isOfPolicyType} from '../../domain/policyType';
// policyTypes, typeOfPolicy,
import {useAppDispatch} from "../../config/store";
import {addClaim} from "../../slices/insuranceSlice";

import styled from "styled-components";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
import PlusIcon from "../../icons/PlusIcon";
import FileUploadIcon from "../../icons/FileUploadIcon";
import {IconButton} from "@material-ui/core";
// Button, colors,
import Responsive from "config/Responsive";

import FillRequiredPersonalInformation from '../../components/FillRequiredPersonalInformation';
import addVisit from "../DoctorsAndProviderClinics/AddVisit";
import CompensationType, {CompensationTypeKeyword, compensationTypes} from "../../domain/compensationType";

const NewFormsContainer = styled.div`
    border-radius: 10px;
    background-color: #fff;
    height: 100%;

    ${Responsive.laptop} {

    }

    ${Responsive.tablet} {

    }

    ${Responsive.mobile} {

    }
`;
const NewPersonSelector = styled.select`
    height: 50px;
    border-radius: 4px;
    width: 28%;
    outline: none;
    border: 1px solid #e5e6ed;
    line-height: 50px;
    padding-left: 20px;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: #636381;

    ${Responsive.laptop} {
        width: 35%;
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        padding-left: 20px;
    }

    ${Responsive.tablet} {
        width: 40%;
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        padding-left: 20px;
    }

    ${Responsive.mobile} {
        width: 100%;
        font-size: 12px;
        line-height: 40px;
        height: 40px;
        padding-left: 20px;
    }
`;
const NewFormsTitle = styled.h1`
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-weight: 400;
    color: ${Colors.TitleBlue};
    margin: 50px 0 50px 50px;

    ${Responsive.laptop} {
        font-size: 16px;
        margin: 40px 0 40px 35px;
    }

    ${Responsive.tablet} {
        font-size: 16px;
        margin: 40px 0 40px 35px;
    }

    ${Responsive.mobile} {
        font-size: 14px;
        margin: 30px 0 30px 30px;
    }
`;
const NewFormsLine = styled.span`
    height: 1px;
    width: 100%;
    background-color: ${Colors.Gray};
    display: block;
`;
const NewFormsContentBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;

    ${Responsive.laptop} {
        margin-left: 35px;
        margin-right: 35px;
    }

    ${Responsive.tablet} {
        margin-left: 35px;
        margin-right: 35px;
    }

    ${Responsive.mobile} {
        margin-left: 30px;
        margin-right: 30px;
    }
`;
const NewFormsInsurancePersonName = styled.h1`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    margin: 40px 0 30px 0;
    color: ${Colors.TitleBlue};

    ${Responsive.laptop} {
        font-size: 14px;
        margin: 35px 0 25px 0;
    }

    ${Responsive.tablet} {
        font-size: 14px;
        margin: 35px 0 25px 0;
    }

    ${Responsive.mobile} {
        font-size: 14px;
        margin: 30px 0 20px 0;
    }
`;
const UpLoadDocumentsTitle = styled.h1`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin-top: 40px;
    margin-bottom: unset;
    margin-left: unset;

    ${Responsive.laptop} {
        margin-top: 35px;
        font-size: 14px;
    }

    ${Responsive.tablet} {
        margin-top: 35px;
        font-size: 14px;
    }

    ${Responsive.mobile} {
        margin-top: 30px;
        font-size: 12px;
    }
`;
const UpLoadDocumentsWarning = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    margin-top: 20px;
    margin-bottom: unset;
    font-weight: 400;
    margin-left: unset;

    ${Responsive.laptop} {
        font-size: 12px;
        margin-top: 20px;
    }

    ${Responsive.tablet} {
        font-size: 12px;
        margin-top: 20px;
    }

    ${Responsive.mobile} {
        font-size: 12px;
        margin-top: 20px;
    }
`;
const WarningText = styled.span`
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.Primary};
`;
const UpLoadDocumentStampTitle = styled.h1`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin: unset;

    ${Responsive.laptop} {
        font-size: 14px;
    }

    ${Responsive.tablet} {
        font-size: 14px;
    }

    ${Responsive.mobile} {
        font-size: 12px;
    }
`;
const UploadFileDiv = styled.div`
    margin-top: 40px;

    ${Responsive.laptop} {
        margin-top: 35px;
    }

    ${Responsive.tablet} {
        margin-top: 35px;
    }

    ${Responsive.mobile} {
        margin-top: 20px;
    }
`;
const CustomFileUpload = styled.div`
    height: 50px;
    width: 100%;
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.LightGray};
    border: 1px solid ${Colors.LightGray};
    border-radius: 6px;

    ${Responsive.laptop} {
        width: 100%;
        height: 40px;
        font-size: 14px;
    }

    ${Responsive.tablet} {
        width: 100%;
        height: 40px;
        font-size: 14px;
    }

    ${Responsive.mobile} {
        width: 100%;
        height: 40px;
        font-size: 14px;
    }
`;
const CustomFileUploadLabel = styled.label`
    margin: unset;
    width: 100%;
`;
const CustomFileUploadSpan = styled.span`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.LightGray};
    margin: unset;
    line-height: 50px;
    margin-top: -4px;

    ${Responsive.laptop} {
        line-height: 40px;
        margin-top: -15px;
        font-size: 14px;
    }

    ${Responsive.tablet} {
        line-height: 40px;
        margin-top: -15px;
        font-size: 12px;
    }

    ${Responsive.mobile} {
        line-height: 40px;
        margin-top: -15px;
        font-size: 12px;
    }
`;
const CustomUploadIcon = styled(FileUploadIcon)`
    height: 18px;

    ${Responsive.laptop} {
        height: 15px;
        margin-top: -13px;
    }

    ${Responsive.tablet} {
        height: 15px;
        margin-top: -13px;
    }

    ${Responsive.mobile} {
        height: 15px;
        margin-top: -13px;
    }
`;
const UploadFileButton = styled.button`
    height: 50px;
    line-height: 50px;
    width: 200px;
    border: 2px dashed ${Colors.LightGray};
    background-color: inherit;
    border-radius: 6px;
    font-size: 14px;
    color: ${Colors.Gray};
    font-family: ${Fonts.FiraGOMtavruliRegular};
    font-weight: 400;
    margin-left: 20px;

    &:focus {
        outline: none;
    }

    ${Responsive.laptop} {
        height: 40px;
        line-height: 40px;
        width: 200px;
        font-size: 14px;
        margin-left: 20px;
    }

    ${Responsive.tablet} {
        height: 40px;
        line-height: 40px;
        width: 200px;
        font-size: 12px;
        margin-left: 20px;
    }

    ${Responsive.mobile} {
        height: 40px;
        line-height: 40px;
        width: 100%;
        font-size: 12px;
        margin-left: unset;
        margin-top: 15px;
    }
`;
const PlusCustomIcon = styled(PlusIcon)`
    height: 10px;
    margin-top: 17px;
    margin-right: 5px;

    ${Responsive.laptop} {
        height: 10px;
        margin-top: 13px;
        margin-right: 5px;
    }

    ${Responsive.tablet} {
        height: 10px;
        margin-top: 13px;
        margin-right: 5px;
    }

    ${Responsive.mobile} {
        height: 10px;
        margin-top: 12.5px;
        margin-right: 5px;
    }
`;
const CustomFileUpLoadBox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;

    ${Responsive.laptop} {
        margin-top: 25px;
    }

    ${Responsive.tablet} {
        margin-top: 15px;
    }

    ${Responsive.mobile} {
        margin-top: 10px;
        flex-direction: column;
    }
`;
const SendButton = styled.button`
    width: 200px;
    height: 50px;
    line-height: 50px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    background-color: ${Colors.Primary};
    border: 1px solid ${Colors.Primary};
    border-radius: 6px;
    margin: 130px 0 60px auto;

    &:hover,
    &:focus {
        background-color: ${Colors.HoverRed};
        outline: none;
    }

    ${Responsive.laptop} {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        margin: 100px 0 50px auto;
    }

    ${Responsive.tablet} {
        height: 40px;
        line-height: 41px;
        font-size: 12px;
        margin: 80px 0 40px auto;
    }

    ${Responsive.mobile} {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        margin: 60px 0 40px auto;
    }
`;

const Select = styled.select`
  height: 50px;
  border-radius: 4px;
  max-width: 320px;
  min-width: 150px;
  width: 100%;
  outline: none;
  border: 1px solid #e5e6ed;
  line-height: 50px;
  padding-left: 20px;
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #636381;
`;
const Label = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  margin: 40px 0 30px 0;
  color: ${Colors.TitleBlue};
`;
type ReceiptKeyword = "ambulatory" | "meds";
type ReceiptType = {
    name: string;
    keyword: ReceiptKeyword;
};


const ReceiptTypes: Record<
    ReceiptKeyword,
    ReceiptType
> = {
    ambulatory: { keyword: "ambulatory", name: "ამბულატორია/სტაციონარი" },
    meds: { keyword: "meds", name: "მედიკამენტები" },
};


export default function AddClaim() {
    const {
        user
    } = useSelector<RootState, AuthState>(data => data.auth);
    const {
        policies,
        // policiesLoading,
        // claims,
        // claimsLoading,
        // claimHistory = [],
        // claimHistoryLoading,
        addClaimLoading,
    } = useSelector<RootState, InsuranceState>((data) => data.insurance);
    //
    const [policyNumber, setSelectedPolicyNumber] = useState("");
    const [policyPolid, setSelectedPolicyPolid] = useState("");
    const [insuredPersonalNumber, setSelectedInsuredPersonalNumber] = useState("");

    const [serviceType, setSelectedserviceType] = useState("");

    const [comment, setComment] = useState("");
    const titles: Record<string, string> = {
        form100:
            "დაწესებულების ბეჭდით, შტამპითა და ხელმოწერით დამოწმებული ფ №IV-100ა",
        receipt: "ექიმის/სპეციალისტის დანიშნულება"
    };
    const EMPTY = "EMPTY";
    const [filesByType, setFilesByType] = useState<
        Record<AddReferenceUploadType, string[]>
    >({
        form100: [EMPTY],
        receipt: [EMPTY]
    });
    const addNewFileBox = (fileType: AddReferenceUploadType) => {
        if (filesByType[fileType].filter((file) => file === EMPTY).length > 0) {
            // there's already an empty file box
            alert("გთხოვთ, ჯერ გამოიყენოთ არსებული ცარიელი ფაილის ასატვირთი ველი.");
            return;
        }

        setFilesByType({
            ...filesByType,
            [fileType]: [...filesByType[fileType], EMPTY],
        });
    };
    const setUploadedFile = (fileType: AddReferenceUploadType, index: number, path: string) => {
        setFilesByType({
            ...filesByType,
            [fileType]: filesByType[fileType].map((existingFile, indexCurrent) => {
                // console.log({
                //     indexCurrent, index, path, existingFile
                // });
                return indexCurrent === index ? path : existingFile;
            }),
        });
    }
    //
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {register} = useForm();
    //
    useEffect(() => {
        dispatch(getPolicies(null));
        dispatch(getClaims(null));
        policyPolid && dispatch(getClaimHistory({policyPolid}));
    }, []);
    //
    return (
        <NewFormsContainer>
            <NewFormsTitle>ახალი მიმართვის მოთხოვნა</NewFormsTitle>
            <NewFormsLine/>
            {!(user?.email && user?.phone && user?.bankAccountNumber) ? (
                <FillRequiredPersonalInformation/>
            ) : (
                <NewFormsContentBox>
                    <NewFormsInsurancePersonName>
                        აირჩიე დაზღვეული პირი
                    </NewFormsInsurancePersonName>
                    <NewPersonSelector
                        onChange={(event) => {
                            const value = event.currentTarget.value;
                            if (value) {
                                const policy = policies.find(
                                    (p: Policy) => p.policyPolid === event.currentTarget.value
                                );
                                policy && setSelectedPolicyNumber(policy.policyNumber);
                                policy && setSelectedPolicyPolid(policy.policyPolid);
                                policy && setSelectedInsuredPersonalNumber(policy.personalNumber);
                            } else {
                                setSelectedPolicyNumber('');
                                setSelectedPolicyPolid('');
                                setSelectedInsuredPersonalNumber("");
                            }
                            event.stopPropagation();
                        }}
                    >
                        <option key="empty" value={''}>
                            აირჩიე
                        </option>
                        {policies.filter(isOfPolicyType('health')).map((p) => (
                            <option key={p.policyPolid} value={p.policyPolid}>
                                {p.objectName || p.cardNumber}
                            </option>
                        ))}
                    </NewPersonSelector>

                    <Label>აირჩიე სერვისის ტიპი</Label>
                    <Select
                        onChange={(event) => {
                             const value = event.currentTarget.value;
                             setSelectedserviceType(value);
                             event.stopPropagation();
                        }}
                    >
                        <option key="empty" value={""}>
                            აირჩიე
                        </option>
                        {Object.values(ReceiptTypes).map(({ name, keyword }) => (
                            <option key={keyword} value={keyword}>
                                {name}
                            </option>
                        ))}
                    </Select>

                    <UpLoadDocumentsTitle>ატვირთე დოკუმენტაცია</UpLoadDocumentsTitle>
                    <UpLoadDocumentsWarning>
                        ვარსკვლავით <WarningText>(*)</WarningText> მონიშნული ველები
                        სავალდებულოა!
                    </UpLoadDocumentsWarning>

                    {Object.keys(filesByType).map((fileType: string) =>
                        filesByType[fileType as AddReferenceUploadType].map(
                            (fileName: string, fileIndex: number) => {
                                const fileInputId = `icon-button-file-${fileType}-${fileIndex}`;
                                return (
                                    <UploadFileDiv key={`${fileType}-${fileName}`}>
                                        <UpLoadDocumentStampTitle>
                                            { (fileType != "form100") ? (
                                                <WarningText>*</WarningText>
                                            ) : ( <WarningText/> )
                                            }
                                            {titles[fileType]}
                                        </UpLoadDocumentStampTitle>
                                        <CustomFileUpLoadBox>
                                            <CustomFileUpload>
                                                <input
                                                    style={{display: "none"}}
                                                    accept="image/*"
                                                    id={fileInputId}
                                                    type="file"
                                                    onChange={(event) => {
                                                        const files = event.target.files;
                                                        if (!files || !files.length) {
                                                            return;
                                                        }
                                                        const file = files[0];// for now only single file upload
                                                        dispatch(uploadFile({file}))
                                                            .then(unwrapResult)
                                                            .then(({path}) => {
                                                                //alert("ფაილი აიტვირთა");
                                                                setUploadedFile(fileType as AddReferenceUploadType, fileIndex, path);
                                                            })
                                                            .catch((error) => {
                                                                console.log('error while uploading file', error);
                                                                alert(error.messages && error.messages[0] || "ფაილის ატვირთვა ვერ მოხერხდა.");
                                                            });
                                                    }}
                                                />
                                                <CustomFileUploadLabel htmlFor={fileInputId}>
                                                    <IconButton
                                                        style={{width: 100 + "%"}}
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="span"
                                                    >
                                                        <CustomFileUploadSpan>
                                                            {fileName !== EMPTY ? /* fileName */ `ფაილი ${fileIndex + 1}` : "ატვირთე ფაილი"}
                                                        </CustomFileUploadSpan>
                                                        <CustomUploadIcon/>
                                                    </IconButton>
                                                </CustomFileUploadLabel>
                                            </CustomFileUpload>
                                            <UploadFileButton
                                                onClick={() =>
                                                    addNewFileBox(fileType as AddReferenceUploadType)
                                                }
                                            >
                                                {" "}
                                                <PlusCustomIcon/> დამატება
                                            </UploadFileButton>
                                        </CustomFileUpLoadBox>
                                    </UploadFileDiv>
                                );
                            }))}

                    {addClaimLoading ? `იტვირთება..` : (
                        <SendButton
                            onClick={() => {
                                if (!policyNumber || !policyPolid) {
                                    alert("გთხოვთ, აირჩიოთ პოლისი");
                                    return;
                                }
                                if (!serviceType) {
                                    alert("გთხოვთ, აირჩიოთ სერვისი");
                                    return;
                                }
                                const policy = policies.find(p => p.policyNumber === policyNumber && p.policyPolid === policyPolid);
                                // if (!filesByType.form100.filter(f => f !== EMPTY).length) {
                                //     alert("გთხოვთ, ატვირთოთ ფორმა 100");
                                //     return;
                                // }
                                // if (!filesByType.calculation.filter(f => f !== EMPTY).length) {
                                //     alert("გთხოვთ, ატვირთოთ დეტალური კალკულაცია");
                                //     return;
                                // }
                                // if (!filesByType.invoice.filter(f => f !== EMPTY).length) {
                                //     alert("გთხოვთ, ატვირთოთ ანგარიშ-ფაქტურა");
                                //     return;
                                // }
                                if (!filesByType.receipt.filter(f => f !== EMPTY).length) {
                                    alert("გთხოვთ, ატვირთოთ დანიშნულება");
                                    return;
                                }
                                dispatch(
                                    addReference({
                                        policyNumber,
                                        policyPolid,
                                        insuredPersonalNumber,
                                        title: policy?.objectName || `${policyNumber} ${policyPolid}`,
                                        status: "request",
                                        comment: comment,
                                        serviceType: serviceType,
                                        files: Object.fromEntries(Object.keys(filesByType)
                                            .map((uploadType: string) =>
                                                [uploadType as AddReferenceUploadType, JSON.stringify(
                                                    filesByType[uploadType as AddReferenceUploadType].filter(f => f !== EMPTY)
                                                )]
                                            )) as unknown as Record<AddReferenceUploadType, string[]>,
                                    })
                                )
                                    .then(unwrapResult)
                                    .then((result) => {
                                        // reload claim list
                                        dispatch(getClaims(null));
                                        // notify the user
                                        alert("განაცხადი წარმატებით გაიგზავნა");
                                        // go back to claims list
                                        history.push("/dashboard");
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        alert(error.messages && error.messages[0] || "გთხოვთ, სცადოთ თავიდან.");
                                    });
                            }}
                        >
                            გაგზავნა
                        </SendButton>
                    )}
                </NewFormsContentBox>
            )}
        </NewFormsContainer>
    );
}
                
