import * as React from "react";

function LuggageIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60.947 52"
      {...props}
    >
      <g data-name="Group 4863">
        <path
          data-name="Intersection 85"
          d="M10 52A10 10 0 010 42V10A10 10 0 0110 0h50l-7.845 24.71L46.9 41.26a16.838 16.838 0 01-2.184 4.788 13.246 13.246 0 01-8.793 5.828 11.505 11.505 0 01-1.768.124z"
          fill="#ebe8fc"
        />
        <g data-name="Group 4613">
          <g data-name="Group 4531">
            <g data-name="Group 2090">
              <g data-name="Group 1335">
                <path
                  data-name="Path 8574"
                  d="M57.733 18.962h-3.808V15.44A3.332 3.332 0 0050.711 12H40.237a3.332 3.332 0 00-3.215 3.44v3.522h-3.807a3.319 3.319 0 00-3.2 3.44L30 36.561a3.332 3.332 0 003.215 3.44h24.518a3.332 3.332 0 003.214-3.44v-14.16a3.332 3.332 0 00-3.214-3.439zm-7.665 0h-9.189V15.44h9.188z"
                  fill="#261e56"
                />
              </g>
            </g>
            <path
              data-name="Intersection 75"
              d="M30.004 32.949l.013-10.55a3.319 3.319 0 013.2-3.441h3.806V15.44a3.332 3.332 0 013.214-3.441H50.71a3.085 3.085 0 012.1.837q.016.4.016.8a19.545 19.545 0 01-19.493 19.6 19.53 19.53 0 01-3.329-.287zM40.88 18.961h9.188V15.44H40.88z"
              fill="#ff677f"
            />
          </g>
          <path
            data-name="Path 12875"
            d="M57.983 35.416v-2.6"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.6}
          />
        </g>
      </g>
    </svg>
  );
}

export default LuggageIcon;
