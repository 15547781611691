import {groupBy} from "lodash";
import React, {ReactNode, ReactNodeArray, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import styled from "styled-components";
import Responsive from "../config/Responsive";
import {
    getPolicyType,
    getPolicyTypeByAbbr,
    isOfPolicyType,
    PolicyTypeAbbr,
    PolicyTypeKeyword,
} from "../domain/policyType";
import {logoutUser} from "../slices/authSlice";
import AlphaLogo from "../icons/alpha-logo.svg";
// import AppealIcon from "../icons/Appeals";
// item icons
import Doctor from "../icons/Doctor";
import Exit from "../icons/Exit";
// import Globe from "../icons/Globe";
import CreditCard from "../icons/LayoutCreditCard";
//import CreditCard from "../icons/CreditCard";
import MoneyBag from "../icons/MoneyBag";
// import Shopping from "../icons/Shopping";
// import Star from "../icons/Star";
import SubMenuIcon from "../icons/SubMenuIcon";
import Warranty from "../icons/Warranty";
import {RootState} from "../slices";
import {InsuranceState, Policy} from "../domain";
import Colors from "../styles/Colors";
import Fonts from "../styles/Fonts";
import NavBarCubeICon from "../icons/NavBarCubeIcon";
import PolicyIcon from "../icons/PolicyIcon";
import {useAppDispatch} from "config/store";
import NavBarBorder from "../icons/NavBarBorder";
import defaultAvatarImage from "../images/default-avatar.png";
import {AuthState} from "../domain";
// item icons
import NavBarBackgroundMask from "../images/nav-bar-mask.svg";
import {viewUploadedFileUrl} from "config";
import SvgWarrantySentIcon from "../icons/WarrantySentIcon";
import StarIcon from "../icons/StarIcon";
import SvgStar from "../icons/Star";

const Wrapper = styled.nav<{ $isSidebarOpen: boolean }>`
    flex: 0 0 ${Responsive.constants.mainMenuWidth.default}px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 100ms;

    ${Responsive.desktop} {
        flex: 0 0 ${Responsive.constants.mainMenuWidth.desktop}px;
    }

    ${Responsive.screens.NarrowNavBar} {
        flex: 0 0 ${Responsive.constants.mainMenuWidth.NarrowNavBar}px;
    }

    ${Responsive.laptop} {
        flex: 0 0 ${Responsive.constants.mainMenuWidth.laptop}px;
    }

    ${Responsive.tablet} {
        width: ${({$isSidebarOpen}) =>
                $isSidebarOpen
                        ? Responsive.constants.mainMenuWidth.tabletOpened + "px"
                        : Responsive.constants.mainMenuWidth.tabletClosed + "px"};
        transition: all 100ms;
        position: absolute;
        background-color: #fff;
        height: 100%;
        z-index: 1;

        &:after {
            display: ${({$isSidebarOpen}) => ($isSidebarOpen ? "block" : "none")};
            overflow: visible;
            content: "";
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            position: ${({$isSidebarOpen}) => ($isSidebarOpen ? "fixed" : "unset")};
            transition: all 100ms ease-out;
            top: 0;
            left: 0;
            z-index: 2;
            margin-left: 300px;

            &:focus {
                position: unset;
            }
        }
    }

    ${Responsive.mobile} {
        position: fixed;
        background-color: #fefefe;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        transition: all 200ms;
        margin-right: ${({$isSidebarOpen}) =>
                $isSidebarOpen ? "unset" : "-100%"};
        padding-top: calc(70px + 20px); /* header + spacing */
    }
`;
const NavBarBackground = styled.img`
    display: none;

    ${Responsive.mobile} {
        display: block;
        position: absolute;
        right: 0;
        top: 20%;
        height: 60%;
        z-index: -1;
    }
`;
const MenuList = styled.ul`
    user-select: none;
    width: 100%;
`;
const MenuItemStyled = styled.li<{ $isSidebarOpen: boolean }>`
    line-height: 80px;
    width: 100%;
    list-style: none;
    transition: 100ms all;

    &:hover {
        background-color: ${Colors.Secondary};
        cursor: pointer;
        color: #261e56;
        border-left: 3px solid #261e56;
        /* transition-timing-function: ease-in-out; */
        /* transition: all 600ms; */

    }

    ${Responsive.desktop} {
        line-height: 80px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 100px;
    }

    ${Responsive.screens.NarrowNavBar} {
        line-height: 80px;
        flex-wrap: wrap;
        padding-left: 25px;
    }

    ${Responsive.laptop} {
        line-height: 55px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 43px;
    }

    ${Responsive.tablet} {
        align-items: unset;
        line-height: 56px;
        display: flex;
        flex-wrap: wrap;
        height: 55px;
        padding-left: ${({$isSidebarOpen}) => ($isSidebarOpen ? "30px" : "unset")};
    }

    ${Responsive.mobile} {
        line-height: 60px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 14%;
    }
`;
const MenuItemLink = styled(Link) <{ $isSidebarOpen: boolean }>`
    list-style: none;
    text-decoration: unset;
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliRegular};
    padding-top: 29px;
    padding-bottom: 29px;
    padding-left: 30px;
    color: ${Colors.Gray};

    &:hover {
        color: #261e56;
        text-decoration: unset;
    }

    ${Responsive.desktop} {
        font-size: 18px;
        padding-left: 25px;
        padding-top: unset;
        padding-bottom: unset;
        flex: 1;
        width: 100%;
        line-height: unset;
    }

    ${Responsive.screens.NarrowNavBar} {
        font-size: 16px;
        padding-left: 10px;
        padding-top: unset;
        padding-bottom: unset;
    }

    ${Responsive.laptop} {
        font-size: 14px;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: unset;
        flex: 1;
        width: 100%;
    }

    ${Responsive.tablet} {
        font-size: 14px;
        padding-left: 10px;
        padding-top: unset;
        padding-bottom: unset;
        flex: 1;
        width: 100%;
        display: ${({$isSidebarOpen}) =>
                $isSidebarOpen ? "inline-block" : "none"};
        line-height: 56px;
    }

    ${Responsive.mobile} {
        padding: unset;
        padding-left: 20px;
        margin-top: 2px;
        font-size: 14px;
        flex: 1;
        width: 100%;
    }
`;
const SubmenuList = styled.ul<{ $isOpen: boolean; $isSidebarOpen: boolean }>`
    height: fit-content;
    width: 100%;
    background-color: #ffffff;
    text-align: left;
    position: sticky;
    margin-bottom: unset;
    display: ${({$isOpen}) => ($isOpen ? "block" : "none")};

    ${Responsive.desktop} {
        // flex-direction: column;
    }

    ${Responsive.tablet} {
        display: ${({$isOpen, $isSidebarOpen}) =>
                $isOpen && $isSidebarOpen ? "block" : "none"};
        position: sticky;
        width: unset;
        padding-left: 25%;
        width: 100%;
    }
`;
const SubmenuItem = styled.li`
    padding-left: 40%;
    line-height: 50px;
    color: #aaaabe;
    font-family: ${Fonts.FiraGOMtavruliRegular};
    list-style: none;

    &:hover {
        color: #261e56;
    }

    ${Responsive.desktop} {
        padding-left: 40%;
        line-height: 50px;
    }

    ${Responsive.screens.NarrowNavBar} {
        padding-left: 28%;
    }

    ${Responsive.laptop} {
        padding-left: 30%;
        line-height: 40px;
        font-size: 14px;
    }

    ${Responsive.tablet} {
        padding-left: unset;
        line-height: 40px;
        font-size: 14px;
        list-style: none;
    }

    ${Responsive.mobile} {
        line-height: 40px;
        padding-left: 25%;
        font-size: 14px;
    }
`;
const SubMenuLink = styled(Link)`
    line-height: 50px;
    width: 100%;
    display: block;

    &:hover {
        color: inherit;
        text-decoration: none;
    }

    ${Responsive.tablet} {
        line-height: 35px;
    }

    ${Responsive.mobile} {
        line-height: 40px;
    }
`;
const SubMenuIconStyled = styled(SubMenuIcon)`
    height: 20px;
    width: 20px;
    margin-left: 10px;
    margin-top: 35px;

    ${Responsive.desktop} {
        height: 20px;
        width: 20px;
        margin-left: 10px;
        margin-top: 35px;
    }

    ${Responsive.screens.NarrowNavBar} {
        height: 20px;
        width: 20px;
        margin-left: 10px;
        margin-top: 35px;
    }

    ${Responsive.laptop} {
        height: 10px;
        width: 10px;
        margin-left: 10px;
        margin-top: 22px;
    }

    ${Responsive.tablet} {
        height: 10px;
        width: 10px;
        margin-left: 10px;
        margin-top: 23px;
    }

    ${Responsive.mobile} {
        margin-top: 27px;
    }
`;
const CloseLineBox = styled.div<{ $isSidebarOpen: boolean }>`
    height: 23px;
    width: 28px;
    cursor: pointer;
    padding: 5px;
    user-select: none;
    margin: 45px auto 75px auto;
    display: none;

    ${Responsive.tablet} {
        margin: ${({$isSidebarOpen}) =>
                $isSidebarOpen ? "45px auto 75px 30px" : "45px auto 75px auto"};
        display: ${({$isSidebarOpen}) => ($isSidebarOpen ? "none" : "block")};
    }

    ${Responsive.mobile} {
        display: block;
        position: fixed;
        top: 22px;
        right: 20px;
        margin: unset;
        // transform: rotate(180deg);
        transform: scaleX(-1);
        z-index: 3;
    }
`;
const TopCloseLine = styled.span`
    width: 18px;
    height: 3px;
    border-radius: 10px;
    background-color: ${Colors.Gray};
    display: block;

    ${Responsive.mobile} {
        background-color: #ffffff;
        border-radius: 20%;
    }
`;
const BottomCloseLine = styled.span`
    width: 12px;
    height: 3px;
    border-radius: 10px;
    background-color: ${Colors.Gray};
    display: block;
    margin-top: 5px;

    ${Responsive.mobile} {
        background-color: #ffffff;
        border-radius: 20%;
    }
`;
const AlphaDashboardLogoLink = styled(Link)`
    width: 100%;
    line-height: 150px;

    ${Responsive.mobile} {
        line-height: unset;
        margin-bottom: 40px;
    }
`;
const AlphaDashboardLogo = styled.img<{ $isSidebarOpen: boolean }>`
    height: 52px;
    margin-left: 100px;
    display: block;
    margin-top: 49px;
    margin-bottom: 49px;

    ${Responsive.desktop} {
        margin-left: 100px;
    }

    ${Responsive.screens.NarrowNavBar} {
        margin-left: 30px;
    }

    ${Responsive.laptop} {
        margin-left: 50px;
    }

    ${Responsive.tablet} {
        display: none;
        display: ${({$isSidebarOpen}) => ($isSidebarOpen ? "block" : "none")};
        margin-left: ${({$isSidebarOpen}) => ($isSidebarOpen ? "30px" : "none")};
    }

    ${Responsive.mobile} {
        margin-left: 10%;
        height: 80px;
        display: none;
    }
`;
const NavBarBorderStyle = styled(NavBarBorder)`
    height: 70px;
    width: 83px;
    position: fixed;
    top: 0;
    right: 0;
    display: none;

    ${Responsive.mobile} {
        display: block;
        z-index: 2;
    }
`;
const MobileLayOutBC = styled.div`
    display: none;

    ${Responsive.mobile} {
        display: block;
        height: 70px;
        width: 100%;
        background-color: #fefefe;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
    }
`;
const MobileProfileLInk = styled(Link)`
    display: flex;
    align-items: baseline;
    display: none;

    ${Responsive.mobile} {
        display: flex;
    }
`;
const MobileProfileDiv = styled.div`
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 14.7%;
    margin-bottom: 30px;
`;
const MobileProfileImg = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;
const MoboleProfileTextDiv = styled.div`
    margin-left: 20px;
`;
const MoboleProfileText = styled.h1`
    margin: unset;
    font-size: 14px;
    font-family: ${Fonts.FiraGOMtavruliRegular};
    font-weight: 400;
    color: #636381;
`;

const SideBarIconWrapper = styled.div<{ $isSidebarOpen: boolean }>`
    /* background-color: red; */
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin: 0px;
        display: block;
        width: fit-content;
        margin-bottom: 4px;
    }

    ${Responsive.desktop} {
        svg {
            width: 17.5px;
        }
    }

    ${Responsive.laptop} {
        svg {
            width: 17px;
        }
    }

    ${Responsive.tablet} {
        width: 100%;
        width: ${({$isSidebarOpen}) => ($isSidebarOpen ? "unset" : "100%")};

        svg {
            width: 17px;
        }
    }

    ${Responsive.mobile} {
        svg {
            width: 16px;
        }
    }

`;

// fix warning for dynamically created styled components
class StyledIcon extends React.Component<{ icon: any }> {
    render() {
        const {icon: Icon} = this.props;
        if (Icon) {
            const Styled = styled(Icon)`
                color: ${Colors.Gray};
                height: 18px;
                width: 25px;
                margin-top: 29px;
                margin-left: 100px;

                ${Responsive.desktop} {
                    margin-left: 100px;
                    height: 18px;
                    width: 25px;
                    margin-top: 29px;
                }

                ${Responsive.screens.NarrowNavBar} {
                    margin-left: 30px;
                    height: 18px;
                    width: 25px;
                    margin-top: 29px;
                }

                ${Responsive.laptop} {
                    margin-left: 50px;
                    height: 14px;
                    width: 15px;
                    margin-top: 21px;
                }

                ${Responsive.tablet} {
                    margin-left: unset;
                    height: 14px;
                    width: 15px;
                    margin-top: 21px;
                    margin: 20px 10px 20px 30px;
                    display: inline-block;
                }

                ${Responsive.mobile} {
                    margin-left: 15%;
                    height: 12px;
                    width: 12px;
                    margin-top: 24px;
                }

                ${MenuItemStyled}:hover & {
                    color: ${Colors.Primary};
                }
            `;
            return <Styled/>;
        } else {
            return <></>;
        }
    }
}

const MenuItem: React.FC<{
    to: string;
    title: string;
    icon?: any;
    children?: ReactNode | ReactNodeArray;
    isSidebarOpen: boolean;
    setIsSidebarOpen: any;
}> = ({to, title, children, icon, isSidebarOpen, setIsSidebarOpen}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const closeMainMenu = () => {
        setIsSidebarOpen && setIsSidebarOpen(false);
    };
    const Icon = icon;
    const MenuOnclick = children
        ? {
            onClick: (event: any) => {
                setIsOpen(!isOpen);
                closeMainMenu();
                event.preventDefault();
            },
        }
        : {};
    return (
        <MenuItemStyled $isSidebarOpen={isSidebarOpen}
                        onClick={(event) => {
                            if (event.target === event.currentTarget) {
                                setIsOpen(!isOpen);
                            }
                            closeMainMenu();
                        }}
        >
            <SideBarIconWrapper $isSidebarOpen={isSidebarOpen}>{Icon && <StyledIcon icon={Icon}/>}</SideBarIconWrapper>

            <MenuItemLink to={to} {...MenuOnclick} $isSidebarOpen={isSidebarOpen}>
                {title}
                {children && (
                    <SubMenuIconStyled
                        onClick={() => {
                            setIsOpen(!isOpen);
                            closeMainMenu();
                        }}
                    />
                )}
            </MenuItemLink>

            <SubmenuList $isOpen={isOpen} $isSidebarOpen={isSidebarOpen}>
                {children}
            </SubmenuList>
        </MenuItemStyled>
    );
};

const Navbar: React.FC = () => {
    // const NavbarProps: React.FC<DashboardProps> = ({}) => {
    const {policies} = useSelector<RootState, InsuranceState>(
        // policiesLoading
        (data) => data.insurance
    );
    const groups = groupBy<Policy>(
        policies,
        (policy: Policy) =>
            getPolicyTypeByAbbr(policy.insurancePrefix as PolicyTypeAbbr).keyword
    );
    const userHasMedicalPolicies =
        policies.filter(isOfPolicyType("health")).length > 0;
    const userHasAutoPolicies =
        policies.filter(isOfPolicyType("auto")).length > 0;

    // nav bar hide & show
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    const dispatch = useAppDispatch();
    const history = useHistory();

    // DUPLICATED in Header.tsx
    // log out function
    function logOut() {
        localStorage.clear();
        dispatch(logoutUser());
        // history.push caches logged out user, so use document.location instead
        //history.push("/login");
        document.location.href = "/login";
    } //not working

    // {isOpened && <MenuItem title={title} />  }
    const auth = useSelector<RootState, AuthState>((state) => state.auth);
    return (
        <Wrapper
            $isSidebarOpen={isSidebarOpen}
            onClick={(event) =>
                event.target == event.currentTarget && setIsSidebarOpen(false)
            }
        >
            <MobileLayOutBC/>
            <NavBarBorderStyle/>
            <CloseLineBox $isSidebarOpen={isSidebarOpen} onClick={toggleSidebar}>
                <TopCloseLine/>
                <BottomCloseLine/>
            </CloseLineBox>
            <AlphaDashboardLogoLink to="/dashboard">
                <AlphaDashboardLogo
                    $isSidebarOpen={isSidebarOpen}
                    src={AlphaLogo}
                    alt="alpha logo"
                />
            </AlphaDashboardLogoLink>
            <NavBarBackground
                src={NavBarBackgroundMask}
                alt="Nav Bar Background Mask"
            />

            <MobileProfileLInk
                to={"/profile"}
                onClick={() => setIsSidebarOpen(false)}
            >
                <MobileProfileDiv>
                    <MobileProfileImg
                        src={
                            auth.user?.avatar
                                ? `${viewUploadedFileUrl}/${auth.user?.avatar}`
                                : defaultAvatarImage
                        }
                        alt="UserProfileImage"
                    />
                </MobileProfileDiv>
                <MoboleProfileTextDiv>
                    <MoboleProfileText>
                        {auth.user?.firstName}
                        <br/>
                        {auth.user?.lastName}
                    </MoboleProfileText>
                </MoboleProfileTextDiv>
            </MobileProfileLInk>

            <MenuList>
                {/* <MenuItem to={"/dashboard"}  title={"მთავარი"} icon={NavBarCubeICon} > */}
                <MenuItem
                    to={"/dashboard"}
                    icon={NavBarCubeICon}
                    title={"მთავარი"}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                ></MenuItem>

                {/* <MenuItem setIsSidebarOpen={!setIsSidebarOpen}
          to={"/my-policies"}
          title={"ჩემი პოლისები"}
          icon={PolicyIcon}
          isSidebarOpen={isSidebarOpen}
        >
          {Object.keys(groups).map((keyword) => {
            const policiesInGroup = groups[keyword];
            const policyType = getPolicyType(keyword as PolicyTypeKeyword);
            const Icon = policyType.icon;
            return (
              <SubmenuItem key={keyword}>
                <SubMenuLink to={`/my-policies/${policyType.keyword}`} onClick={() => setIsSidebarOpen(false)}>
                  {policyType.title}
                </SubMenuLink>
              </SubmenuItem>
            );
          })}
        </MenuItem> */}

                {userHasMedicalPolicies && (
                    <>
                        <MenuItem
                            to={"/visit/list"}
                            icon={Doctor}
                            title={"ჩემი ვიზიტები"}
                            isSidebarOpen={isSidebarOpen}
                            setIsSidebarOpen={setIsSidebarOpen}
                        ></MenuItem>
                        {/* <MenuItem
            setIsSidebarOpen={!setIsSidebarOpen}
            to={"/visit/list"}
            title={"ექიმთან ვიზიტი"}
            icon={Doctor}
            isSidebarOpen={isSidebarOpen}
          >
            <SubmenuItem>
              <SubMenuLink
                to="/visit/list"
                onClick={() => setIsSidebarOpen(false)}
              >
                ჩემი ვიზიტები
              </SubMenuLink>
            </SubmenuItem>
            <SubmenuItem>
              <SubMenuLink
                to="/visit/add"
                onClick={() => setIsSidebarOpen(false)}
              >
                ვიზიტის ჩანიშნვა
              </SubMenuLink>
            </SubmenuItem>
          </MenuItem> */}
                    </>
                )}

                {(userHasMedicalPolicies || userHasAutoPolicies) && (
                    <>
                        <MenuItem
                            to={"/compensation/list"}
                            icon={MoneyBag}
                            title={"ანაზღაურებები"}
                            isSidebarOpen={isSidebarOpen}
                            setIsSidebarOpen={setIsSidebarOpen}
                        ></MenuItem>
                        {/* <MenuItem
            setIsSidebarOpen={!setIsSidebarOpen}
            to={"/compensation/list"}
            title={"ანაზღაურებები"}
            icon={MoneyBag}
            isSidebarOpen={isSidebarOpen}
          >
            <SubmenuItem>
              <SubMenuLink
                to="/compensation/list"
                onClick={() => setIsSidebarOpen(false)}
              >
                ჩემი ანაზღაურებები
              </SubMenuLink>
            </SubmenuItem>
            <SubmenuItem>
              <SubMenuLink
                to="/compensation/add"
                onClick={() => setIsSidebarOpen(false)}
              >
                ანაზღაურების მოთხოვნა
              </SubMenuLink>
            </SubmenuItem>
            </MenuItem>*/}
                    </>
                )}

                <MenuItem
                    // to={"/claim/references"}
                    to={"/reference/add"}
                    icon={SvgStar}
                    title={"მიმართვის მოთხოვნა"}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                ></MenuItem>

                {userHasMedicalPolicies && (
                    <>
                        <MenuItem
                            to={"/claim/list"}
                            icon={Warranty}
                            title={"საგარანტიო მიმართვები"}
                            isSidebarOpen={isSidebarOpen}
                            setIsSidebarOpen={setIsSidebarOpen}
                        ></MenuItem>

                        {/* <MenuItem
            setIsSidebarOpen={!setIsSidebarOpen}
            to={"/claim/list"}
            title={"საგარანტიო მიმართვები"}
            icon={Warranty}
            isSidebarOpen={isSidebarOpen}
          >
            <SubmenuItem>
              <SubMenuLink
                to="/claim/list"
                onClick={() => setIsSidebarOpen(false)}
              >
                ჩემი მიმართვები
              </SubMenuLink>
            </SubmenuItem>
            <SubmenuItem>
              <SubMenuLink
                to="/claim/add"
                onClick={() => setIsSidebarOpen(false)}
              >
                ახალი მიმართვა
              </SubMenuLink>
            </SubmenuItem>
            </MenuItem>*/}
                    </>
                )}

                {/*<MenuItem to={"/my-policies"} title={"ანაზღაურებები"} icon={MoneyBag}>
       <SubmenuItem>
    <Link to="/compensations">ანაზღაურებები</Link>
          </SubmenuItem>
          <SubmenuItem>ჯანრთელობა</SubmenuItem>
          <SubmenuItem>მოგზაურობა</SubmenuItem>
          </MenuItem>*/}
                {/* {userHasMedicalPolicies && (
            <MenuItem to={"/visit-doctor"} title={"ექიმთან ვიზიტი"} icon={Doctor}></MenuItem>
        )} */}
                {/* <MenuItem to={"/"} title={"ბალანსის შევსება"} icon={CreditCard}>
          <SubmenuItem>ქვეპუნქტი</SubmenuItem>
          <SubmenuItem>ქვეპუნქტი</SubmenuItem>
        </MenuItem> */}
                {/* <MenuItem to={"/"} title={"პოლისის შეძენა"} icon={Shopping}></MenuItem> */}
                {/* <MenuItem to={"/"} title={"შეთავაზებები"} icon={Star}></MenuItem> */}

                <MenuItem
                    to={"/profile"}
                    icon={CreditCard}
                    title={"პირადი ინფორმაცია"}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                ></MenuItem>

                {/* <MenuItem to={"/"} title={"ქართული"} icon={Globe}>
        <SubmenuItem>ქართული</SubmenuItem>
        </MenuItem> */}
            </MenuList>
            {/* moved to header dropdown */}
            {/* <MenuItemStyled onClick={logOut}>
        <StyledIcon icon={Exit} />
        <MenuItemLink
          $isSidebarOpen={isSidebarOpen}
          to={""}
          style={{ pointerEvents: "none" }}
        >
          გასვლა
        </MenuItemLink>
      </MenuItemStyled> */}
        </Wrapper>
    );
};
export default Navbar;
