import React from 'react'
import CurrentPolicy from "./Claims/ClaimDetails"

function WarrantyCurrentPolicy() {
    return (
        <div>
            საგარანტიოს მიმდინარე პოლისი
            <CurrentPolicy />
        </div>
    )
}

export default WarrantyCurrentPolicy
