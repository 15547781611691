import React, { useState } from 'react';
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { viewUploadedFileUrl } from '../config';
// uploadsUrl,
// icons
import NotificationIcon from '../icons/Notification';
import { RootState } from '../slices';
import { AuthState } from '../domain';
import Colors from '../styles/Colors';
import Fonts from '../styles/Fonts';
import './header.css';
import defaultAvatarImage from '../images/personalManage_beta-img.png';
import Responsive from 'config/Responsive';
import AlphaMobileLogo from "../icons/alpha-logo.svg";
import { Link, useHistory } from "react-router-dom";
import { logoutUser } from "../slices/authSlice";
import { useAppDispatch } from "config/store";
import PolicyPaymentModal from "../components/PolicyPaymentModal";

export interface HeaderProps {
  user?: {};
  onLogin: () => void;
  onLogout: () => void;
  onCreateAccount: () => void;
}
const HeaderHelloUser = styled.h1`
    font-size: 28px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: #636381;
    font-weight: 600;
    line-height: 40px;
    margin: unset;
      ${Responsive.desktop}{
        font-size: 28px;
        line-height: 40px;
      }
      ${Responsive.laptop}{
        font-size: 20px;
        line-height: 25px;
      }
      ${Responsive.tablet}{
        font-size: 20px;
        line-height: 25px;
      }
      ${Responsive.mobile}{
        font-size: 16px;
        line-height: 20px;
        padding-left: 5%;
        padding-top: 80px;
      }
`;
const WelcomeUser = styled.h1`
    font-size: 18px;
    line-height: 10px;
    color: #767880;
    font-weight: 400;
    margin: unset;
    display: block;
    font-family: ${Fonts.FiraGORegular};
      ${Responsive.desktop}{
        font-size: 18px;
        line-height: 24px;
      }
      ${Responsive.laptop}{
        font-size: 14px;
        line-height: 18px;
      }
      ${Responsive.tablet}{
        font-size: 14px;
        line-height: 18px;
      }
      ${Responsive.mobile}{
        font-size: 12px;
        line-height: 16px;
        padding-left: 5%;
      }
`;
const NotificationIconStyled = styled(NotificationIcon)`
    &:hover { 
      color: ${Colors.Primary}; 
    }
    cursor: pointer;
      ${Responsive.mobile}{
        position: fixed;
        top: 29px;
        right: 110px;
        z-index: 2;
      }
`;
const UserProfileImageBox = styled.div`
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    overflow: hidden;
    object-fit: contain;
    margin-left: 30px;
    cursor: pointer;
    background-color: #e9e9e9;
    border-radius: 50%;
      ${Responsive.desktop}{
        width: 60px;
        height: 60px;
        margin-left: 30px;
      }
      ${Responsive.laptop}{
        width: 40px;
        height: 40px;
        margin-left: 25px;
      }
      ${Responsive.tablet}{
        width: 40px;
        height: 40px;
        margin-left: 25px;
      }
      ${Responsive.mobile}{
        display: none;
      }
`;
const UserProfileImage = styled.img`
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
`;
const UserMenuWrapper = styled.div`
  position: relative;
  z-index: 1;
`;
const UserMenu = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 7px);
  min-width: 180px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
  border-radius: 6px;
  overflow: hidden;
`;
const UserMenuItem = styled.div`
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: #636381;
    font-size: 16px;

    cursor: pointer;
    transition: all 0.2s ease-in;
    display: flex;
    flex-direction: column;
    &:hover {
      color: ${Colors.Primary};
      background-color: ${Colors.Secondary};
    }
    a {
      color: inherit;
      text-decoration: none;
      padding: 13px 20px 11px 20px;
      &:hover { color: inherit; }
    }
`;
const HeaderContainer = styled.div`
    padding-top: 60px;
    padding-bottom: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fbfbfb;
      ${Responsive.laptop}{
         
      }
      ${Responsive.tablet}{
        
      }
      ${Responsive.mobile}{
        padding-top: 28px;
        padding-bottom: 30px;
      }
`;
const AlphaMobileLogoLink = styled(Link)`
    display: none;
      ${Responsive.mobile}{
        display: block;
      }
`;
const AlphaMobileLogoStyle = styled.img`
    display: none;
      ${Responsive.mobile}{
        display: block;
        position: fixed;
        top: 9px;
        left: 4%;
        height: 52px;
        z-index: 2;
      }
`;




const Header: React.FC<HeaderProps> = ({ user, onLogin, onLogout, onCreateAccount }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState<boolean>(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  const dispatch = useAppDispatch();
  const history = useHistory();

  // DUPLICATE of logOut in Navbar.tsx
  // log out function
  function logOut(event: any) {
    event.preventDefault();
    localStorage.clear();
    dispatch(logoutUser());
    // history.push caches logged out user, so use document.location instead
    //history.push("/login");
    document.location.href = "/login";
  } //not working
  
  const [isPaymentModalVisible, setIsPaymentModalVisible] = React.useState(
    false
  );
  
  return (
    <header>
      <HeaderContainer>

        <div style={{ flex: 1, }} >
          <AlphaMobileLogoLink to={"/dashboard"}>
          <AlphaMobileLogoStyle src={AlphaMobileLogo} alt="alpha mobile logo"/>
          </AlphaMobileLogoLink>
          <HeaderHelloUser>გამარჯობა {auth.user?.firstName} !</HeaderHelloUser>
          <WelcomeUser>კეთილი იყოს შენი დაბრუნება პორტალზე</WelcomeUser>
        </div>

        {/*  */}
        <div>
          <NotificationIconStyled
            color={isNotificationsOpen ? Colors.Primary : Colors.Gray}
            size={19}
            onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
          />
          {/* {isNotificationsOpen ? "open" : "closed"} */}

        </div>
        <UserMenuWrapper>
          <UserProfileImageBox onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}>
            <UserProfileImage src={auth.user?.avatar ? `${viewUploadedFileUrl}/${auth.user?.avatar}` : defaultAvatarImage} alt="UserProfileImage" />
          </UserProfileImageBox>
          {isUserMenuOpen && (
            <UserMenu>
              <UserMenuItem><a href="/profile/password">პაროლის შეცვლა</a></UserMenuItem>
              <UserMenuItem><a href="/profile/personal">ფოტოს შეცვლა</a></UserMenuItem>
              <UserMenuItem><a href="" onClick={(event) => {
                setIsPaymentModalVisible(true);
                event.preventDefault();
              }}>პრემიის გადახდა</a></UserMenuItem>
              <UserMenuItem><a href="" onClick={logOut}>გასვლა</a></UserMenuItem>
            </UserMenu>
          )}
        </UserMenuWrapper>

        {isPaymentModalVisible && (
          <PolicyPaymentModal onClose={() => setIsPaymentModalVisible(false)} />
        )}

        {/* <div>
          {user ? (
            <Button size="small" onClick={onLogout} label="Log out" />
          ) : (
              <>
                <Button size="small" onClick={onLogin} label="Log in" />
                <Button primary size="small" onClick={onCreateAccount} label="Sign up" />
              </>
            )}
        </div> */}
      </HeaderContainer>
    </header>
  )
};
export default Header;
