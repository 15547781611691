import React from 'react';
import {
  Link
} from 'react-router-dom';
const doctors = [
  {
    name: 'elguja',
    age: '30'
  },
  {
    name: 'gocha',
    age: '35'
  }
];
function DoctorsList() {
  return (
    <div className="row">
      {doctors.map(doctor => (
        <div className="card col-md-3" style={{ width: '350px' }}>
          <img className="card-img-top" src="https://www.publicdomainpictures.net/pictures/270000/velka/avatar-people-person-business-.jpg" alt="doctor" />
          <div className="card-body">
            <h5 className="card-title">{doctor.name}</h5>
           <Link to="/"><p className="card-text">{doctor.age}</p></Link>
          </div>
        </div>
      )
      )
      }
      </div>
  );
}

export default DoctorsList;