import * as React from "react";

function MasterCardIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 40"
      {...props}
    >
      <g data-name="Group 6003">
        <g
          data-name="Rectangle 2132"
          fill="none"
          stroke="#767880"
          strokeLinecap="round"
        >
          <rect width={64} height={40} rx={4} stroke="none" />
          <rect x={0.5} y={0.5} width={63} height={39} rx={3.5} />
        </g>
        <g data-name="Group 4492">
          <g data-name="MC Card">
            <path
              data-name="Rectangle 278"
              fill="#ff5f00"
              d="M28.223 12.033h8.318V26.98h-8.318z"
            />
            <path
              data-name="Path 7816"
              d="M28.751 19.507a9.532 9.532 0 013.618-7.473 9.507 9.507 0 100 14.947 9.493 9.493 0 01-3.618-7.474z"
              fill="#eb001b"
            />
            <path
              data-name="Path 7817"
              d="M47.764 19.507a9.5 9.5 0 01-15.369 7.471 9.528 9.528 0 000-14.947 9.5 9.5 0 0115.369 7.473z"
              fill="#f79e1b"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MasterCardIcon;
