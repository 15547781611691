const Responsive = {
  // MobileSmall: "@media (max-width: 375px)",
  // MobileLarge: "@media (min-width: 376px) and (max-width: 768px)",
  // Tablet: "@media (min-width: 769px) and (max-width: 1024px)",
  // LaptopSmall: "@media (min-width: 1025px) and (max-width: 1280px)",
  // LaptopLarge: "@media (min-width: 1281px) and (max-width: 1366px)",
  // DesktopSmall: "@media (min-width: 1367px) and (max-width: 1440px)",
  // DesktopSmall: "@media (min-width: 1441px) and (max-width: 1680px)",
  // DesktopLarge: "@media (min-width: 1681px) and (max-width: 1920px)",
  // DesktopOther: "@media (min-width: 1921px)",

  //desktop: "@media (min-width: 1367px) and (max-width: 1366px)",

  mobile: "@media (max-width: 767px)", //includes iPhone/others portrait 375
  tablet: "@media (min-width: 768px) and (max-width: 1199px)", //includes iPad landscape 1024
  laptop: "@media (min-width: 1200px) and (max-width: 1366px)", //includes ALL laptops and desktop workstations
  desktop: "@media (min-width: 1367px) and (max-width: 3200px)", // and (max-width: 1920px) only fullHD monitors
  fullHD: "@media (min-width: 1920px)", //last breakpoint

  screens: {
    NarrowNavBar: "@media (min-width: 1367px) and (max-width: 1570px)", // for nav bar
    // laptop: "@media (min-width: 1200px) and (max-width: 1366px)",  //includes 1280 13" macbooks and 1366 15" windows laptops
  },
  constants: {
    mainMenuWidth: {
      fullHD: 440,
      default: 440,
      desktop: 440,
      laptop: 300,
      NarrowNavBar: 300,
      tablet: 300,
      tabletOpened: 300,
      tabletClosed: 75,
      mobile: 0,
    },
    rightSidebarWidth: {
      fullHD: 300,
      default: 300,
      desktop: 300,
      NarrowNavBar: 300,
      laptop: 300,
      tablet: 200,
      mobile: 0,
    },
  },
};

// export const dashboardContentWidth = (
//   size: "default" | Exclude<keyof typeof Responsive, "screens" | "constants">
// ) => {
//   return `calc(100% - ${Responsive.constants.mainMenuWidth[size]}px - ${Responsive.constants.rightSidebarWidth[size]});`;
// };

export default Responsive;
