import React from "react";
// import { useState } from 'react';
// material ui design
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// for style
import styled from "styled-components";
import Colors from "styles/Colors";
import Responsive from "config/Responsive";
import Fonts from "styles/Fonts";
import { Link, useHistory } from "react-router-dom";
import "../components/header.css";

// redux
import { useSelector } from "react-redux";
// import { getPolicies, getCompensations, getCompensationHistory, getClaims, getClaimHistory } from "../slices/insuranceSlice";
import { RootState } from "../slices";
import { InsuranceState, Claim, Compensation, Visit, Doctor, ClaimHistoryItem, CompensationHistoryItem } from "../domain";
import { getCompensationTypeName } from "../domain/compensationType";
// import { useAppDispatch } from "../config/store";
// import PropTypes from "prop-types";
// import { useEffect } from 'react';

import dayjs from "dayjs";
dayjs.locale("ka");

const DashboardTabsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  background-color: green;
  border-radius: 10px;
  min-height: 300px;
  box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
  box-sizing: border-box;
  overflow: hidden;
  ${Responsive.mobile} {
    margin: 0 auto;
    margin-top: 20px;
  }
`;
// tabs style
const DashboardTabStyle = styled(Tab)`
  font-size: 16px !important;
  line-height: 20px !important;
  outline: none !important;
  color: ${Colors.Primary} !important;
  max-width: 33.33% !important;
  width: 100% !important;
  font-family: ${Fonts.FiraGOMtavruliRegular} !important;
  font-weight: 400 !important;
  margin-top: 38px !important;
  opacity: unset !important;
  ${Responsive.laptop} {
    font-size: 14px !important;
    max-width: 33.33% !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.tablet} {
    font-size: 14px !important;
    max-width: 33.33% !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.mobile} {
    font-size: 14px !important;
    max-width: 33.33% !important;
    margin-top: unset !important;
    height: 75px !important;
    padding: unset !important;
    line-height: 15px !important;
    &:focus {
      width: 100% !important;
    }
  }
`;
const DashboardTabsStyled = styled(Tabs)`
  background-color: #ffffff !important;
  height: 120px !important;
  opacity: unset !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  ${Responsive.laptop} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.tablet} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.mobile} {
    height: 75px !important;
    min-height: 75px !important;
  }
`;
// visit doctor tabs style
const VisitsTab = styled.div`
  /*  */
`;
const VisitsHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  ${Responsive.tablet} {
    display: none;
  }
  ${Responsive.mobile} {
    display: none;
  }
`;
const VisitsHeaderColumn = styled.h1`
  font-size: 12px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #767880;
  margin: unset;
  display: block;
  line-height: 50px;
  &:nth-child(1) {
    width: 5%;
    text-align: center;
    /* background-color: green; */
  }
  &:nth-child(2) {
    width: 18%;
    text-align: left;
    /* background-color: aqua; */
  }
  &:nth-child(3) {
    width: 15%;
    text-align: left;
    padding-left: 20px;
    /* background-color: yellow; */
  }
  &:nth-child(4) {
    width: 20%;
    text-align: left;
    padding-left: 20px;
    /* background-color: pink; */
  }
  &:nth-child(5) {
    width: 15%;
    text-align: left;
    padding-left: 20px;
    /* background-color: greenyellow; */
  }
  &:nth-child(6) {
    width: 10%;
    text-align: center;
    /* background-color: goldenrod; */
  }
  &:nth-child(7) {
    width: 17%;
    text-align: right;
    padding-right: 10px;
    /* background-color: whitesmoke; */
  }
  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.mobile} {
    display: none;
  }
`;
const VisitRow = styled.div`
  width: 100%;
  /* background-color: green; */
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  position: relative;
  ${Responsive.mobile} {
    position: relative;
    display: unset;
    align-items: unset;
    display: block;
  }
`;
const VisitColumn = styled.h1`
  font-size: 12px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  margin: unset;
  color: #767880;
  line-height: 50px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:nth-child(1) {
    width: 5%;
    text-align: center;
    /* background-color: green; */
    ${Responsive.tablet} {
      display: none;
    }
    ${Responsive.mobile} {
      display: none;
    }
  }
  &:nth-child(2) {
    width: 20%;
    text-align: left;
    /* background-color: aqua; */
    ${Responsive.tablet} {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
    }
    ${Responsive.mobile} {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
    }
  }
  &:nth-child(3) {
    width: 15%;
    text-align: left;
    /* background-color: yellow; */
    padding-left: 20px;
    ${Responsive.tablet} {
      position: absolute;
      top: 50px;
      left: 0;
      padding: unset;
      width: 50%;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 50px;
      left: 0;
      padding: unset;
      width: 50%;
    }
  }
  &:nth-child(4) {
    width: 20%;
    text-align: left;
    padding-left: 20px;
    /* background-color: pink; */
    ${Responsive.tablet} {
      display: none;
    }
    ${Responsive.mobile} {
      display: none;
    }
  }
  &:nth-child(5) {
    width: 15%;
    text-align: left;
    padding-left: 20px;
    /* background-color: greenyellow; */
    ${Responsive.tablet} {
      position: absolute;
      right: 50px;
      top: 50px;
      padding-left: unset;
      width: 35%;
      text-align: right;
    }
    ${Responsive.mobile} {
      position: absolute;
      right: 50px;
      top: 50px;
      padding-left: unset;
      width: 35%;
      text-align: right;
    }
  }
  &:nth-child(6) {
    width: 10%;
    text-align: center;
    /* background-color: goldenrod; */
    ${Responsive.tablet} {
      position: absolute;
      top: 50px;
      right: 0;
      width: 50px;
      text-align: right;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 50px;
      right: 0;
      width: 50px;
      text-align: center;
    }
  }
  &:nth-child(7) {
    width: 17%;
    text-align: right;
    padding-right: 10px;
    /* background-color: whitesmoke; */
    ${Responsive.tablet} {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: unset;
      width: 50%;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: unset;
      width: 50%;
    }
  }

  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 50px;
    height: 50px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 50px;
    height: 50px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
    line-height: 50px;
    height: 50px;
  }
`;

// remuTabs
const CompensationHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  /* margin-top: 30px; */
  ${Responsive.tablet} {
    display: none;
  }
  ${Responsive.mobile} {
    display: none;
  }
`;
const CompensationHeaderColumn = styled.div`
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #767880;
  margin: unset;
  display: block;
  line-height: 50px;
  &:nth-child(1) {
    width: 10%;
    text-align: center;
    /* background-color: green; */
  }
  &:nth-child(2) {
    width: 35%;
    text-align: left;
    padding-left: 10px;
    /* background-color: aqua; */
    ${Responsive.mobile} {
      padding-left: unset;
    }
  }
  &:nth-child(3) {
    width: 20%;
    text-align: left;
    padding-left: 20px;
    /* background-color: yellow; */
  }
  &:nth-child(4) {
    width: 15%;
    text-align: center;
    /* padding-left: 10px; */
    /* background-color: pink; */
  }
  &:nth-child(5) {
    width: 20%;
    text-align: right;
    padding-right: 5px;
    /* background-color: greenyellow; */
  }

  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.mobile} {
    display: none;
  }
`;
const CompensationRow = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  ${Responsive.tablet} {
    position: relative;
    border-bottom: 1px dotted #e5e6ed;
    height: 100px;
  }
  ${Responsive.mobile} {
    position: relative;
    border-bottom: 1px dotted #e5e6ed;
    height: 100px;
  }
`;
const CompensationColumn = styled.div`
  font-size: 13px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #767880;
  margin: unset;
  display: block;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${Responsive.laptop} {
    font-size: 12px;
  }
  &:nth-child(1) {
    width: 10%;
    text-align: center;
    /* background-color: green;   */
    ${Responsive.tablet} {
      display: none;
    }
    ${Responsive.mobile} {
      display: none;
    }
  }
  &:nth-child(2) {
    width: 35%;
    text-align: left;
    padding-left: 10px;
    /* background-color: aqua; */
    ${Responsive.tablet} {
      width: 60%;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: unset;
    }
    ${Responsive.mobile} {
      width: 60%;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: unset;
    }
  }
  &:nth-child(3) {
    width: 20%;
    text-align: left;
    padding-left: 20px;
    /* background-color: yellow; */
    ${Responsive.tablet} {
      position: absolute;
      top: 50px;
      left: 0;
      text-align: left;
      padding: unset;
      width: 60%;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 50px;
      left: 0;
      text-align: left;
      padding: unset;
      width: 60%;
    }
  }
  &:nth-child(4) {
    width: 25%;
    text-align: center;
    padding-left: 10px;
    /* background-color: pink; */
    ${Responsive.tablet} {
      position: absolute;
      top: 50px;
      right: 0;
      text-align: right;
      padding: unset;
      width: 40%;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 50px;
      right: 0;
      text-align: right;
      padding: unset;
      width: 40%;
    }
  }
  &:nth-child(5) {
    width: 20%;
    text-align: right;
    padding-right: 5px;
    /* background-color: greenyellow; */
    ${Responsive.tablet} {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      padding: unset;
      width: 40%;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      padding: unset;
      width: 40%;
    }
  }

  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.mobile} {
    /* display: none; */
  }
`;

// ClaimsTabs
const ClaimHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  /* margin-top: 30px; */
  ${Responsive.tablet} {
    display: none;
  }
  ${Responsive.mobile} {
    display: none;
  }
`;
const ClaimTabOneTitlesForDashboard = styled.div`
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #767880;
  margin: unset;
  display: block;
  line-height: 50px;
  &:nth-child(1) {
    width: 5%;
    text-align: center;
    /* background-color: green; */
  }
  &:nth-child(2) {
    width: 25%;
    text-align: left;
    padding-left: 10px;
    /* background-color: aqua; */
    ${Responsive.mobile} {
      padding-left: unset;
    }
  }
  &:nth-child(3) {
    width: 25%;
    text-align: left;
    padding-left: 20px;
    /* background-color: yellow; */
  }
  &:nth-child(4) {
    width: 25%;
    text-align: center;
    /* padding-left: 10px; */
    /* background-color: pink; */
  }
  &:nth-child(5) {
    width: 20%;
    text-align: right;
    /* background-color: greenyellow; */
    padding-right: 5px;
  }

  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.mobile} {
    display: none;
  }
`;
const ClaimItem = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  ${Responsive.tablet} {
    position: relative;
    border-bottom: 1px dotted #e5e6ed;
    height: 100px;
  }
  ${Responsive.mobile} {
    position: relative;
    border-bottom: 1px dotted #e5e6ed;
    height: 100px;
  }
`;
const ClaimColumn = styled.div`
  font-size: 12px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #767880;
  margin: unset;
  display: block;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${Responsive.laptop} {
    font-size: 12px;
  }
  &:nth-child(1) {
    width: 5%;
    text-align: center;
    /* background-color: green;   */
    ${Responsive.tablet} {
      display: none;
    }
    ${Responsive.mobile} {
      display: none;
    }
  }
  &:nth-child(2) {
    width: 25%;
    text-align: left;
    padding-left: 10px;
    /* background-color: aqua; */
    ${Responsive.tablet} {
      width: 60%;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: unset;
    }
    ${Responsive.mobile} {
      width: 60%;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: unset;
    }
  }
  &:nth-child(3) {
    width: 25%;
    text-align: left;
    padding-left: 20px;
    /* background-color: yellow; */
    ${Responsive.tablet} {
      position: absolute;
      top: 50px;
      left: 0;
      text-align: left;
      padding: unset;
      width: 60%;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 50px;
      left: 0;
      text-align: left;
      padding: unset;
      width: 60%;
    }
  }
  &:nth-child(4) {
    width: 25%;
    text-align: center;
    padding-left: 10px;
    /* background-color: pink; */
    ${Responsive.tablet} {
      position: absolute;
      top: 50px;
      right: 0;
      text-align: right;
      padding: unset;
      width: 40%;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 50px;
      right: 0;
      text-align: right;
      padding: unset;
      width: 40%;
    }
  }
  &:nth-child(5) {
    width: 20%;
    text-align: right;
    padding-right: 5px;
    /* background-color: greenyellow; */
    ${Responsive.tablet} {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      padding: unset;
      width: 40%;
    }
    ${Responsive.mobile} {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      padding: unset;
      width: 40%;
    }
  }

  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.mobile} {
    /* display: none; */
  }
`;

const DashboardTabsMobileScroll = styled.div`
  /* height: 415px; */
  max-height: 500px;
  overflow-y: scroll;
  padding-left: 5px;
  padding-right: 10px;
  ${Responsive.laptop} {
    max-height: 400px;
    overflow-y: scroll;
  }
  ${Responsive.tablet} {
    max-height: 400px;
    overflow-y: scroll;
  }
  ${Responsive.mobile} {
    max-height: 415px;
    overflow-y: scroll;
  }
`;

const NoResults = styled.div`
  text-align: center;
  padding-top: 35px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.Gray};
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

// const DashboardTabs: React.FC<{ showVisits: boolean; showClaims: boolean; showCompensations: boolean; }> = ({ showVisits, showClaims, showCompensations }) {
const DashboardTabs: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  // const options = ["Option 1", "Option 2"];
  // const [show, setShow] = useState(true);
  // const [selectedPolid, setSelectedPolid] = useState("");
  //
  const {
    // policies,
    // policiesLoading,
    claims,
    claimsLoading,
    claimHistory,
    claimHistoryLoading,
    compensations,
    compensationsLoading,
    compensationHistory,
    compensationHistoryLoading,
    visits,
    visitsLoading,
    doctors,
    doctorsLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);
  return (
    <DashboardTabsContainer className={classes.root}>
      <AppBar position="static" color="default">
        <DashboardTabsStyled
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <DashboardTabStyle label="ანაზღაურებები" {...a11yProps(0)} />
          <DashboardTabStyle label="საგარანტიო მიმართვები" {...a11yProps(1)} />
          <DashboardTabStyle label="ექიმთან ვიზიტები" {...a11yProps(2)} />
        </DashboardTabsStyled>
      </AppBar>

      <TabPanel value={value} index={0}>
        <CompensationsTable
          compensations={compensations}
          compensationsLoading={compensationsLoading}
          compensationHistory={compensationHistory}
          compensationHistoryLoading={compensationHistoryLoading}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ClaimsTable claims={claims} claimsLoading={claimsLoading} claimHistory={claimHistory} claimHistoryLoading={claimHistoryLoading} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <VisitsTable
          visits={visits}
          doctors={doctors}
          visitsLoading={visitsLoading}
          doctorsLoading={doctorsLoading}
        />
      </TabPanel>
    </DashboardTabsContainer>
  );
};

export const ClaimsTable: React.FC<{
  claims: Claim[];
  claimsLoading: boolean;
  claimHistory: ClaimHistoryItem[];
  claimHistoryLoading: boolean;
  expanded?: boolean;
}> = ({ claims: claimsUnsorted, claimsLoading, claimHistory: claimHistoryUnsorted, claimHistoryLoading, expanded = true }) => {
  // sort claims DESC
  const claimsSorted = claimsUnsorted
  .slice()
  .sort((a, b) => (dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : a == b ? 0 : 1));
const claims = expanded ? claimsSorted : claimsSorted.slice(0, 5);
const claimHistory = claimHistoryUnsorted
  .slice()
  .sort((a, b) => (dayjs(a.validFrom) > dayjs(b.validFrom) ? -1 : a == b ? 0 : 1));
  return (
    <div>
      <ClaimHeader>
        <ClaimTabOneTitlesForDashboard>N</ClaimTabOneTitlesForDashboard>
        <ClaimTabOneTitlesForDashboard>
          დასახელება
        </ClaimTabOneTitlesForDashboard>
        <ClaimTabOneTitlesForDashboard>თარიღი</ClaimTabOneTitlesForDashboard>
        {/*<ClaimTabOneTitlesForDashboard>თანხა</ClaimTabOneTitlesForDashboard>*/}
        <ClaimTabOneTitlesForDashboard>სტატუსი</ClaimTabOneTitlesForDashboard>
      </ClaimHeader>
      {claims.length > 0 ? (
        claims.map((claim) => (
          <ClaimItem key={claim.id}>
            <ClaimColumn>{claim.id.toString()}</ClaimColumn>
            <ClaimColumn>{claim.title}</ClaimColumn>
            <ClaimColumn>
              {dayjs(claim.createdAt)
                .locale("ka")
                .format("DD MMM YYYY | HH:mm")}
            </ClaimColumn>
            {/*<ClaimColumn>
                {claim.policyPolid} ₾
                </ClaimColumn>*/}
            <ClaimColumn>
              {claim.status.replace("request", "მოლოდინში")}
            </ClaimColumn>
          </ClaimItem>
        ))
      ) : (
        <NoResults>ინფორმაცია არ მოიძებნა</NoResults>
      )}
      {/* <ViewAllLink to="/claims">ყველა</ViewAllLink> */}
    </div>
  );
};

export const CompensationsTable: React.FC<{
  compensations: Compensation[];
  compensationsLoading: boolean;
  compensationHistory: CompensationHistoryItem[];
  compensationHistoryLoading: boolean;
  expanded?: boolean;
}> = ({ compensations: compensationsUnsorted, compensationsLoading, compensationHistory: compensationHistoryUnsorted, compensationHistoryLoading, expanded = true }) => {
  // sort compensations DESC
  const compensationsSorted = compensationsUnsorted
      .slice()
      .sort((a, b) => (dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : a == b ? 0 : 1));
  const compensations = expanded ? compensationsSorted : compensationsSorted.slice(0, 5);
  const compensationHistory = compensationHistoryUnsorted
      .slice()
      .sort((a, b) => (dayjs(a.validFrom) > dayjs(b.validFrom) ? -1 : a == b ? 0 : 1));
  return (
    <DashboardTabsMobileScroll>
      <CompensationHeader>
        <CompensationHeaderColumn>N</CompensationHeaderColumn>
        <CompensationHeaderColumn>დასახელება</CompensationHeaderColumn>
        <CompensationHeaderColumn>ტიპი</CompensationHeaderColumn>
        <CompensationHeaderColumn>თარიღი</CompensationHeaderColumn>
        {/*<CompensationHeaderColumn>თანხა</CompensationHeaderColumn>*/}
      </CompensationHeader>
      {compensations.length > 0 ? (
        compensations.map((compensation) => (
          <CompensationRow key={compensation.id}>
            <CompensationColumn>{compensation.id}</CompensationColumn>
            <CompensationColumn>{compensation.title}</CompensationColumn>
            <CompensationColumn>
              {getCompensationTypeName(compensation.type)}
            </CompensationColumn>
            <CompensationColumn>
              {dayjs(compensation.createdAt)
                .locale("ka")
                .format("DD MMM YYYY | HH:mm")}
            </CompensationColumn>
            {/*<CompensationColumn>
                {compensation.amount} ₾
            </CompensationColumn>*/}
          </CompensationRow>
        ))
      ) : (
        <NoResults>ინფორმაცია არ მოიძებნა</NoResults>
      )}
      {/*{compensationHistory.map((compensationHistoryItem) => (
      <CompensationRow>
        <CompensationColumn>
        {compensationHistoryItem.documentNumber}
      </CompensationColumn>
        <CompensationColumn>
        {compensationHistoryItem.serviceType}{" "}
      {compensationHistoryItem.serviceName}
      </CompensationColumn>
        <CompensationColumn>
        {dayjs(compensationHistoryItem.validFrom)
          .locale("ka")
          .format("DD MMM YYYY")}
      </CompensationColumn>
        <CompensationColumn>
        {compensationHistoryItem.amount} ₾
      </CompensationColumn>
        <CompensationColumn>
        {compensationHistoryItem.statusText}
      </CompensationColumn>
        </CompensationRow>
    ))}*/}
      {/* <ViewAllLink to="/compensations">ყველა</ViewAllLink> */}
    </DashboardTabsMobileScroll>
  );
};

export const VisitsTable: React.FC<{
  visits: Visit[];
  visitsLoading: boolean;
  doctors: Doctor[];
  doctorsLoading: boolean;
  expanded?: boolean;
}> = ({ doctors, doctorsLoading, visits: visitsUnsorted, visitsLoading, expanded = true }) => {
  // sort claims DESC
  const visitsSorted = visitsUnsorted
  .slice()
  .sort((a, b) => (dayjs(a.visitingTime) > dayjs(b.visitingTime) ? -1 : a == b ? 0 : 1));
  const visits = expanded ? visitsSorted : visitsSorted.slice(0, 5);
  return (
    <VisitsTab>
      <VisitsHeader>
        <VisitsHeaderColumn>N</VisitsHeaderColumn>
        <VisitsHeaderColumn>ექიმი</VisitsHeaderColumn>
        <VisitsHeaderColumn>პროფილი</VisitsHeaderColumn>
        <VisitsHeaderColumn>კლინიკა</VisitsHeaderColumn>
        <VisitsHeaderColumn>თარიღი</VisitsHeaderColumn>
        <VisitsHeaderColumn>საათი</VisitsHeaderColumn>
        <VisitsHeaderColumn>სტატუსი</VisitsHeaderColumn>
      </VisitsHeader>
      {visits.length > 0 ? (
        visits.map((v, i) => {
          const doctor = doctors?.find(
            (d) => d.personalNumber === v.doctorPersonalNumber
          );
          return (
            <VisitRow key={v.visitingTime.toString()}>
              <VisitColumn>{i + 1}</VisitColumn>
              <VisitColumn>
                {doctor?.firstName} {doctor?.lastName}
              </VisitColumn>
              <VisitColumn>{doctor?.profession}</VisitColumn>
              <VisitColumn>{doctor?.providerName}</VisitColumn>
              <VisitColumn>
                {dayjs(v.visitingTime).format("DD MMM YYYY")}
              </VisitColumn>
              <VisitColumn>{dayjs(v.visitingTime).format("HH:mm")}</VisitColumn>
              <VisitColumn>დაგეგმილი</VisitColumn>
            </VisitRow>
          );
        })
      ) : (
        <NoResults>ინფორმაცია არ მოიძებნა</NoResults>
      )}
      {/* <ViewAllLink to="/visits">ყველა</ViewAllLink> */}
    </VisitsTab>
  );
};

const ViewAllLink = styled(Link)`
  font-size: 13px;
  color: ${Colors.Primary};
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 600;
  width: 100%;
  display: block;
  text-align: right;
  text-decoration: underline;
  margin-top: 6px;
  padding-right: 9px;
`;

export default DashboardTabs;
