import * as React from "react";
import Colors from '../styles/Colors';

function PolicyBorder(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 140 112"
      {...props}
    >
      <defs>
        <clipPath id="policy-border_svg__a">
          <path
            data-name="Path 9693"
            d="M75.118 0C139.992.008 160 0 160 0v112H20l16.731-53.222 11.2-35.644a36.431 36.431 0 014.659-10.312C57.261 5.929 64.091 1.356 71.346.27A24.282 24.282 0 0175.118 0z"
            transform="translate(759.333 268)"
              fill={Colors.PrimaryVeryLight}
          />
        </clipPath>
      </defs>
      <g
        data-name="Mask Group 14"
        transform="translate(-779.333 -268)"
        clipPath="url(#policy-border_svg__a)"
      >
        <path
          data-name="Rectangle 1583"
          d="M511.481 268h407.857v102a10 10 0 01-10 10H511.481a10 10 0 01-10-10v-92a10 10 0 0110-10z"
            fill={Colors.PrimaryVeryLight}
        />
      </g>
    </svg>
  );
}

export default PolicyBorder;
