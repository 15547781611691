import React from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Fonts from "styles/Fonts";

const ProfileLink = styled(Link)`
display: inline-block;
margin-top: 10px;
font-weight: bold;
font-size: 108%;
color: #261e56;
font-family: ${Fonts.FiraGOMtavruliBold};
vertical-align: top;
padding: 6px 12px 3px 12px;
background-color: #ebe8fc;
border-radius: 4px;
&:hover {
    color: #261e56;
    background-color: #fdd8de;
    text-decoration: none;
}
`;

const FillRequiredPersonalInformation: React.FC = () => {
  return <div style={{padding:30, color: '#636381', lineHeight: '200%' }}>
  გთხოვთ, შეავსოთ აუცილებელი პირადი ინფორმაცია: ტელეფონი, ელ-ფოსტა, საბანკო რეკვიზიტები.
 <br />
    <ProfileLink to="/profile">პროფილზე გადასვლა</ProfileLink>
        </div>
  };
  export default FillRequiredPersonalInformation;
