import React, { useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
import Responsive from "../../config/Responsive";
import dayjs from "dayjs";
import useStateMachine from "@cassiozen/usestatemachine";
import { useSelector } from "react-redux";
import { RootState } from "slices";
import { useAppDispatch } from "config/store";
import { InsuranceState, Policy, Visit, Doctor } from "../../domain";
import {
  policyTypes,
  typeOfPolicy,
  isOfPolicyType,
} from "../../domain/policyType";
import {
  getDoctorFreeDays,
  getDoctorFreeHours,
  addVisit,
  getVisits,
} from "slices/insuranceSlice";
import meaningfulErrorMessage from "utils/meaningfulErrorMessage";
import { VisitType, VisitTypes } from '../../domain/types';

function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
};

const RecordDoctorGrayLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.GrayLine};
  display: none;
  margin: 30px 0px;
  ${Responsive.mobile} {
    display: block;
  }
`;

const RecordDoctorWrapper = styled.div`
  background-color: #ffffff;
  min-height: 100vh;
  border-radius: 10px;
`;
const RecordDoctorContent = styled.div`
  padding: 50px;
  ${Responsive.laptop} {
    padding: 35px;
  }
  ${Responsive.tablet} {
    padding: 35px;
  }
  ${Responsive.mobile} {
    padding: 30px;
  }
`;
const RecordDoctorNewVisitWrapper = styled.div`
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Responsive.laptop} {
    height: 85px;
  }
  ${Responsive.tablet} {
    height: 85px;
  }
  ${Responsive.mobile} {
    height: unset;
    justify-content: center;
    flex-direction: column;
  }
`;
const RecordDoctorNewVisitText = styled.h1`
  font-size: 18px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  margin: 0px;
  color: ${Colors.TitleBlue};
  ${Responsive.laptop} {
    font-size: 14px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
  }
  ${Responsive.mobile} {
    font-size: 14px;
    margin: auto auto auto 0px;
  }
`;
const StyledLabel = styled.label`
  width: 100%;
  margin-bottom: 10px;
  color: ${Colors.TitleBlue};
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
`;
const StyledSelect = styled.select`
  width: 320px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid ${Colors.Border};
  padding-left: 30px;
  font-size: 16px;
  color: ${Colors.TitleBlue};
  font-family: ${Fonts.FiraGORegular};
  &:focus {
    outline: none;
  }
  ${Responsive.laptop} {
    width: 250px;
    height: 42px;
    font-size: 14px;
    padding-left: 23px;
    padding-left: 23px;
  }
  ${Responsive.mobile} {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 23px;
  }
`;

const VisitDoctorTabNewButton = styled.button`
  height: 50px;
  width: 30%;
  border-radius: 6px;
  border: unset;
  background-color: #261e56;
  color: #fff;
  outline: none;
  font-size: 14px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus,
  &:hover {
    outline: none;
    color: #fff;
    text-decoration: unset;
    background-color: ${Colors.HoverRed};
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 35%;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 37%;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }
  ${Responsive.mobile} {
    height: 38px;
    width: 60%;
    border-radius: 4px;
    font-size: 14px;
    margin: unset;
    margin-top: 20px;
  }
`;

const AddVisit: React.FC = () => {
  const {
    policies,
    policiesLoading,
    doctors,
    doctorsLoading,
    providers,
    providersLoading,
    selectedDoctorFreeDays,
    selectedDoctorFreeDaysLoading,
    selectedDoctorFreeHours,
    selectedDoctorFreeHoursLoading,
    addVisitLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);

  const [policyNumber, setSelectedPolicyNumber] = useState("");
  const [policyPolid, setSelectedPolicyPolid] = useState("");
  const [patientPersonalNumber, setPatientPersonalNumber] = useState("");

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [machine, send] = useStateMachine<{
    visitTypeKeyword?: VisitType['keyword'];
    providerName?: string;
    doctorPersonalNumber?: string;
    day?: string;
    hour?: string;
  }>({
    visitTypeKeyword: undefined,
    providerName: undefined,
    doctorPersonalNumber: undefined,
    day: undefined,
    hour: undefined,
  })({
    initial: "blank",
    verbose: true,
    states: {
      blank: {
        on: {
          SELECT_VISIT_TYPE: "visitTypeSelected",
        },
      },
      visitTypeSelected: {
        on: {
          SELECT_VISIT_TYPE: "visitTypeSelected",
          SELECT_PROVIDER: "providerSelected",
          SELECT_DOCTOR: "doctorSelected",
        },
        effect({ send, setContext, event }) {
          setContext((context) => ({
            ...context,
            visitTypeKeyword: event?.value,
            providerName: undefined,
            doctorPersonalNumber: undefined,
            day: undefined,
            hour: undefined,
          }))
        }
      },
      providerSelected: {
        effect({ send, setContext, event }) {
          const providerName = event?.value;
          // alert(`selected provider: ${providerName}`);
          setContext((context) => ({
            ...context,
            providerName,
            doctorPersonalNumber: undefined,
            day: undefined,
            hour: undefined,
          }));
        },
        on: {
          SELECT_VISIT_TYPE: "visitTypeSelected",
          SELECT_PROVIDER: "providerSelected",
          SELECT_DOCTOR: "doctorSelected",
        },
      },
      doctorSelected: {
        effect({ send, setContext, event }) {
          const doctorPersonalNumber = event?.value;
          dispatch(getDoctorFreeDays({ doctorPersonalNumber }));
          // alert(`selected doctor: ${doctorPersonalNumber}`);
          setContext((context) => ({
            ...context,
            doctorPersonalNumber,
            providerName: doctorPersonalNumber ? doctors.find(d => d.personalNumber == doctorPersonalNumber)?.providerName : machine.context.providerName,
            day: undefined,
            hour: undefined,
          }));
        },
        on: {
          SELECT_VISIT_TYPE: "visitTypeSelected",
          SELECT_PROVIDER: "providerSelected",
          SELECT_DOCTOR: "doctorSelected",
          SELECT_DAY: "daySelected",
        },
      },
      daySelected: {
        on: {
          SELECT_VISIT_TYPE: "visitTypeSelected",
          SELECT_PROVIDER: "providerSelected",
          SELECT_DOCTOR: "doctorSelected",
          SELECT_DAY: "daySelected",
          SELECT_HOUR: "hourSelected",
        },
        effect({ send, setContext, context, event }) {
          const doctorPersonalNumber = context.doctorPersonalNumber!;
          const day = event?.value;
          dispatch(getDoctorFreeHours({ doctorPersonalNumber, day }));
          // alert(`selected day: ${day}`);
          setContext((context) => ({
            ...context,
            day,
            hour: undefined,
          }));
        },
      },
      hourSelected: {
        effect({ send, setContext, context, event }) {
          const doctorPersonalNumber = context.doctorPersonalNumber!;
          const day = context.day!;
          const hour = event?.value!;
          // alert(`selected hour: ${hour}`);
          setContext((context) => ({ ...context, hour }));
        },
        on: {
          SELECT_VISIT_TYPE: "visitTypeSelected",
          SELECT_PROVIDER: "providerSelected",
          SELECT_DOCTOR: "doctorSelected",
          SELECT_DAY: "daySelected",
          SELECT_HOUR: "hourSelected",
          SUBMIT: "submitting",
        },
      },
      submitting: {
        effect({ context }) {
          // submit form
          dispatch(
            addVisit({
              policyNumber,
              policyPolid,
              visitTypeKeyword: context.visitTypeKeyword!,
              doctorPersonalNumber: context.doctorPersonalNumber!,
              patientPersonalNumber: patientPersonalNumber,
              day: context.day!,
              hour: context.hour!,
            })
          )
            .then(unwrapResult)
            .then((result) => {
              // re-fetch visits list
              dispatch(getVisits(null));
              // show notification
              alert(
                `ვიზიტი წარმატებით დაიჯავშნა: ${context.day} ${context.hour}`
              );
              // redirect back to visits list
              history.push("/visit/list");
            })
            .catch((error) => {
              console.log(error);
              alert(meaningfulErrorMessage(error, "გთხოვთ, სცადოთ თავიდან."));
            });
        },
      },
    },
  });


  const selectedDoctor = doctors.find(
    (d: Doctor) => d.personalNumber == machine.context.doctorPersonalNumber
  );

  const providerOfSelectedDoctor = providers.find(p => p.id === selectedDoctor?.providerId) || providers.find(p => p.name == machine.context.providerName);
  const detectedProviderDetails = providerOfSelectedDoctor;

  return (
    <RecordDoctorWrapper>
      <RecordDoctorContent>
        <RecordDoctorNewVisitWrapper>
          <RecordDoctorNewVisitText>ახალი ვიზიტი</RecordDoctorNewVisitText>
          <RecordDoctorGrayLine />

          <span style={{ marginLeft: "auto", marginRight: 15 }}>პაციენტი:</span>
          <StyledSelect
            name=""
            defaultValue={''}
            value={policyPolid}
            onChange={(event) => {
              const value = event.currentTarget.value;
              if (value) {
                const policy = policies.find(
                  (p: Policy) => p.policyPolid === event.currentTarget.value
                );
                policy && setSelectedPolicyNumber(policy.policyNumber);
                policy && setSelectedPolicyPolid(policy.policyPolid);
                policy && setPatientPersonalNumber(policy.personalNumber);
              } else {
                setSelectedPolicyNumber("");
                setSelectedPolicyPolid("");
                setPatientPersonalNumber("");
              }
              event.stopPropagation();
            }}
          >

            {policies.filter(isOfPolicyType("health")).map((p) => (
              <option key={p.policyPolid} value={p.policyPolid}>
                {p.objectName || p.cardNumber}
              </option>
            ))}
          </StyledSelect>
        </RecordDoctorNewVisitWrapper>

        <div style={{ marginBottom: 30 }}>
          {/* choose visit type */}
          <div style={{ marginBottom: 30 }}>
            <StyledLabel htmlFor="visit_type">
              აირჩიე ვიზიტის ტიპი
            </StyledLabel>
            <StyledSelect
              name="visit_type"
              defaultValue={''}
              value={machine.context.visitTypeKeyword}
              onChange={(event) => {
                const visitTypeKeyword = event.target.value;
                send({ type: "SELECT_VISIT_TYPE", value: visitTypeKeyword });
              }}
            >
              <option key="empty" value={""}></option>
              {Object.values(VisitTypes).map((t) => (
                <option key={t.keyword} value={t.keyword}>
                  {t.name}
                </option>
              ))}
            </StyledSelect>
          </div>
          {/* choose doctor */}
          {machine.context.visitTypeKeyword && (
            <>
            <div style={{ marginBottom: 30 }}>
              <StyledLabel htmlFor="provider_name">
                აირჩიე პროვაიდერი
              </StyledLabel>
              <StyledSelect
                name="provider_name"
                defaultValue={''}
                value={machine.context.providerName}
                onChange={(event) => {
                  const providerName = event.target.value;
                  send({ type: "SELECT_PROVIDER", value: providerName });
                }}
              >
                <option key="empty" value={''}></option>
                {doctors.map(d => d.providerName).filter(onlyUnique).map((pName) => (
                  <option key={pName} value={pName}>
                    {pName}
                  </option>
                ))}
              </StyledSelect>
            </div>
          <div
            style={{
              marginBottom: 30,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ marginRight: 30 }}>
              <StyledLabel htmlFor="doctor_personal_number">
                აირჩიე ექიმი
              </StyledLabel>
              <StyledSelect
                name="doctor_personal_number"
                defaultValue={''}
                value={machine.context.doctorPersonalNumber}
                onChange={(event) => {
                  const doctorPersonalNumber = event.target.value;
                  send({ type: "SELECT_DOCTOR", value: doctorPersonalNumber });
                }}
              >
                <option key="empty" value={""}></option>
                {doctors.filter(d => machine.context.providerName ? d.providerName == machine.context.providerName : true).map((d) => (
                  <option key={d.personalNumber} value={d.personalNumber}>
                    {d.firstName} {d.lastName}
                  </option>
                ))}
              </StyledSelect>
            </div>
            <div style={{ marginRight: 30 }}>
              {detectedProviderDetails && /*machine.context.visitTypeKeyword == VisitTypes.clinic.keyword && */ (
                <>
                  <StyledLabel>
                    პროვაიდერი კლინიკა:
                  </StyledLabel>
                  <div style={{ color: '#AAAABE', marginBottom: 20 }}>{detectedProviderDetails.name}</div>
                  <StyledLabel>
                    მისამართი:
                  </StyledLabel>
                  <div style={{ color: '#AAAABE', marginBottom: 10 }}>{detectedProviderDetails.city}, {detectedProviderDetails.address}</div>
                </>
              )}
            </div>
          </div>
          </>
          )}
        </div>

        {machine.context.doctorPersonalNumber ? (
          <div
            style={{
              marginBottom: 30,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ marginRight: 30 }}>
              <StyledLabel htmlFor="doctor_personal_number">
                აირჩიე დღე
              </StyledLabel>
              {selectedDoctorFreeDaysLoading ? (
                <div>იტვირთება..</div>
              ) : selectedDoctorFreeDays.length == 0 ? (
                <div>ექიმს არ აქვს თავისუფალი დღეები</div>
              ) : (
                <StyledSelect
                  name="doctor_free_day"
                  defaultValue={''}
                  value={machine.context.day}
                  onChange={(event) => {
                    const day = event.target.value;
                    send({ type: "SELECT_DAY", value: day });
                  }}
                >
                  <option key="empty" value={""}></option>
                  {selectedDoctorFreeDays.map((d) => (
                    <option
                      key={d.toString()}
                      value={dayjs(d).format("DD.MM.YYYY")}
                    >
                      {dayjs(d).format("DD.MM.YYYY")}
                    </option>
                  ))}
                </StyledSelect>
              )}
            </div>
            {machine.context.day ? (
              <div style={{ marginRight: 30 }}>
                <StyledLabel htmlFor="doctor_free_hour">
                  აირჩიე საათი
                </StyledLabel>
                {selectedDoctorFreeHoursLoading ? (
                  <div>იტვირთება..</div>
                ) : selectedDoctorFreeHours.length == 0 ? (
                  <div>ამ დღეს ექიმს არ აქვს თავისუფალი საათები</div>
                ) : (
                  <StyledSelect
                    name="doctor_free_hour"
                    defaultValue={''}
                    value={machine.context.hour}
                    onChange={(event) => {
                      const hour = event.target.value;
                      send({ type: "SELECT_HOUR", value: hour });
                    }}
                  >
                    <option key="empty" value={""}></option>
                    {selectedDoctorFreeHours.map((h) => (
                      <option key={h} value={h}>
                        {h}
                      </option>
                    ))}
                  </StyledSelect>
                )}
              </div>
            ) : null}
          </div>
        ) : null}

        {policyPolid && machine.context.visitTypeKeyword && machine.context.hour ? (
          addVisitLoading ? (
            <div>გთხოვთ მოიცადოთ..</div>
          ) : (
            <VisitDoctorTabNewButton
              onClick={() => {
                send("SUBMIT");
              }}
            >
              ჩანიშნვა
            </VisitDoctorTabNewButton>
          )
        ) : null}
      </RecordDoctorContent>
    </RecordDoctorWrapper>
  );
};

export default AddVisit;
