import React, { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../slices";
import { AuthState } from "../../domain";
import { useAppDispatch } from "../../config/store";
import { checkPersonalNumber } from "../../slices/authSlice";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
import PopUpAcceptedIcon from "../../icons/popUp-accepted-icon.svg";
import Responsive from "../../config/Responsive";
import meaningfulErrorMessage from "../../utils/meaningfulErrorMessage";

// left side
// const RegistrationLeftSide = styled.div`
//   flex-basis: 50%;
//   height: 100vh;
//   position: relative;
// `;
// right side

const RegistrationRightSide = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
`;
// const RegisterContent = styled.div`
//   display: flex;
// `;
// const RegisterLeftFirstText = styled.h1`
//   font-size: 24px;
//   color: #ffffff;
//   margin: unset;
// `;
// const RegisterJoinUsText = styled.h1`
//   font-size: 70px;
//   color: #ffffff;
//   margin: unset;
//   line-height: 90px;
//   margin-top: 35px;
// `;
// const RegisterLeftTextBox = styled.div`
//   position: absolute;
//   left: 140px;
//   top: 30%;
// `;
const RightRegistrationText = styled.h1`
  font-size: 35px;
  /* color: #05124d; */
  color: #636381;
  margin: unset;
  margin-bottom: 60px;
  font-weight: 400;
  font-family: ${Fonts.FiraGOMtavruliRegular};
  ${Responsive.desktop} {
    font-size: 35px;
    margin-bottom: 60px;
  }
  ${Responsive.laptop} {
    font-size: 22px;
    margin-bottom: 40px;
  }
  ${Responsive.tablet} {
    font-size: 22px;
    margin-bottom: 40px;
    font-weight: 400;
  }
  ${Responsive.mobile} {
    font-size: 16px;
    margin-bottom: 35px;
    font-weight: 400;
    margin-top: 150px;
  }
`;
const RegisterRightLastText = styled.h1`
  font-size: 16px;
  color: ${Colors.Gray};
  margin: unset;
  margin-top: 40px;
  display: block;
  font-weight: 400;
  font-family: ${Fonts.FiraGORegular};
  ${Responsive.desktop} {
    font-size: 16px;
    margin-top: 40px;
  }
  ${Responsive.laptop} {
    font-size: 14px;
    margin-top: 30px;
    font-weight: 400;
  }
  ${Responsive.tablet} {
    font-size: 14px;
    margin-top: 30px;
    font-weight: 400;
  }
  ${Responsive.mobile} {
    font-size: 12px;
    margin-top: 25px;
    font-weight: 400;
  }
`;
// popUp
const ConfirmedPopUpBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
`;
const ConfirmedPopUp = styled.div`
  width: 660px;
  height: 360px;
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ConfirmedPopUpText = styled.h1`
  font-size: 20px;
  color: ${Colors.TitleBlue};
  margin: unset;
  margin-top: 50px;
  user-select: none;
  font-family: ${Fonts.FiraGOMtavruliBold};
`;
const RegisterIdInput = styled.input`
  min-width: 300px;
  max-width: 440px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  margin: unset;
  margin-bottom: unset;
  outline: none;
  color: #ced0dd;
  font-size: 14px;
  padding-left: 30px;
  border: 1px solid #ced0dd;
  font-family: ${Fonts.FiraGORegular};
  ${Responsive.desktop} {
    min-width: 300px;
    max-width: 440px;
    width: 100%;
    font-size: 14px;
  }
  ${Responsive.laptop} {
    min-width: 300px;
    max-width: 320px;
    width: 100%;
    border-radius: 6px;
    padding-left: 30px;
    height: 50px;
    font-size: 12px;
  }
  ${Responsive.tablet} {
    min-width: 300px;
    max-width: 300px;
    width: 100%;
    border-radius: 6px;
    padding-left: 30px;
    height: 50px;
    font-size: 12px;
  }
  ${Responsive.mobile} {
    min-width: 300px;
    max-width: 300px;
    width: 100%;
    border-radius: 6px;
    padding-left: 30px;
    height: 50px;
    font-size: 12px;
  }
`;
const RegisterRightButton = styled.button`
  min-width: 300px;
  max-width: 440px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background-color: ${Colors.Primary};
  outline: none;
  border: unset;
  color: #ffffff;
  font-size: 20px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  margin-top: 30px;
  &:focus,
  &:hover {
    outline: none;
    border: unset;
    background-color: ${Colors.HoverRed};
  }
  ${Responsive.desktop} {
    min-width: 300px;
    max-width: 440px;
    height: 70px;
    border-radius: 10px;
    font-size: 20px;
  }
  ${Responsive.laptop} {
    min-width: 300px;
    max-width: 320px;
    height: 50px;
    border-radius: 6px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    min-width: 300px;
    max-width: 300px;
    height: 50px;
    border-radius: 6px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    min-width: 300px;
    max-width: 300px;
    height: 50px;
    border-radius: 6px;
    font-size: 14px;
  }
`;
const RegisterMaxWidthDiv = styled.div`
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  ${Responsive.tablet} {
    width: 300px;
    z-index: 1;
  }
`;

const Register: React.FC = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  const [personalNumber, setPersonalNumber] = useState<string>("");

  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <RegisterMaxWidthDiv>
      <RegistrationRightSide>
        <RightRegistrationText>რეგისტრაცია</RightRegistrationText>
        <RegisterIdInput
          type="text"
          name="პირადი ნომერი"
          placeholder="პირადი ნომერი"
          onChange={(e) => setPersonalNumber(e.target.value)}
        />
        <br />
        <RegisterRightButton
          onClick={() => {
            dispatch(checkPersonalNumber({ personalNumber }))
              .then(unwrapResult)
              .then((result) => {
                if (result.success) {
                  history.push("/register-step-two");
                } else {
                  alert(
                    meaningfulErrorMessage(result, "გთხოვთ, სცადოთ თავიდან.")
                  );
                }
              })
              .catch((error) => {
                console.log(error);
                alert(meaningfulErrorMessage(error, "გთხოვთ, სცადოთ თავიდან."));
              });
          }}
        >
          შემდეგი
        </RegisterRightButton>
        <RegisterRightLastText>
          ანგარიშის შესაქმნელად შეყვანე მონაცემები{" "}
          {auth.checkPersonalNumberLoading ? "..." : ""}
        </RegisterRightLastText>
      </RegistrationRightSide>

      <ConfirmedPopUpBox>
        <ConfirmedPopUp>
          <img
            src={PopUpAcceptedIcon}
            alt="PopUpAcceptedIcon"
            style={{
              width: 90,
              height: 90,
              display: "block",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
          <ConfirmedPopUpText>
            რეგისტრაცია წარმატებით დასრულდა
          </ConfirmedPopUpText>
        </ConfirmedPopUp>
      </ConfirmedPopUpBox>
    </RegisterMaxWidthDiv>
  );
};

export default Register;
