/**
 * 
 * @param error object in the shape of { messages: { [fieldName]: string[] } }
 * @param defaultMessage 
 * @param onlyExtractFirstMessage 
 */
const meaningfulErrorMessage = (result: any, defaultMessage = "მოხდა შეცდომა.") => {
  if(!result){
    return defaultMessage;
  }
  try {
    const errorKeys = Object.keys(result.messages);
    const firstFoundMessageOrArray = result.messages[errorKeys[0]];
    if(!firstFoundMessageOrArray){
      throw new Error('no messages found');
    }
    if(firstFoundMessageOrArray.constructor === Array){
      return firstFoundMessageOrArray[0];
    } else {
      return firstFoundMessageOrArray;
    }
  } catch(error){
    return result.errorMessage || defaultMessage;
  }
};
export default meaningfulErrorMessage;
