import React from 'react'
// for styled
import styled from 'styled-components';
import Fonts from 'styles/Fonts';
import Colors from 'styles/Colors';
import Responsive from 'config/Responsive';
// icons
import PlusIcon from "icons/PlusIcon";
import HideIcon from "icons/ButtonFilter";
import { Link } from 'react-router-dom';
import { useState } from 'react';






const PayMentHistoryContainer = styled.div`
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    padding-left: 50px;
    padding-right: 50px;
        ${Responsive.laptop}{
            padding-left: 30px;
            padding-right: 30px;
        }
        ${Responsive.tablet}{
            padding-left: 20px;
            padding-right: 20px;
        }
        ${Responsive.mobile}{
            padding-left: 20px;
            padding-right: 20px;
        }
`;
const PayMentHistoryHeader = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
        ${Responsive.laptop}{
            height: 75px;
            margin-top: 25px;
        }
        ${Responsive.tablet}{
            height: 75px;
            margin-top: 25px;
        }
        ${Responsive.mobile}{
            height: unset;
            display: block;
            margin-top: 25px;
        }
`;


// header style
const PayMentHistoryTitle = styled.h1`
    font-size: 20px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin: unset;
    flex: 1;
        ${Responsive.laptop}{
            font-size: 16px;
        }
        ${Responsive.tablet}{
            font-size: 16px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
        }
`;
const PayMentHistorySelect = styled.select`
    max-width: 200px;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #E5E6ED;
    padding-left: 20px;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: ${Colors.TitleBlue};
        &:focus,
        &:hover{
            outline: none;
        }
    ${Responsive.laptop}{
        height: 42px;
        max-width: 150px;
        border-radius: 4px;
        padding-left: 15px;
        font-size: 14px;
    }
    ${Responsive.tablet}{
        height: 42px;
        max-width: 150px;
        border-radius: 4px;
        padding-left: 15px;
        font-size: 14px;
    }
    ${Responsive.mobile}{
        height: 38px;
        max-width: unset;
        border-radius: 4px;
        padding-left: 15px;
        font-size: 12px;
        width: 100%;
    }
`;
const PayMentHistoryPayButton = styled.button`
    height: 50px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid ${Colors.Primary};
    background-color: ${Colors.Primary};
    font-size: 14px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-weight: 400;
    color: #fff;
    line-height: 50px;
    overflow: hidden;
        &:focus,
        &:hover{
            outline: none;
            background-color: ${Colors.HoverRed};
            border-color: ${Colors.HoverRed};
        }
    ${Responsive.laptop}{
        height: 42px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 42px;
    }
    ${Responsive.tablet}{
        height: 42px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 42px;
    }
    ${Responsive.mobile}{
        height: 38px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 38px;
        margin-top: 15px;
        width: 80%;
    }
`;
const PayMentHistoryPayButtonLink = styled(Link)`
    margin-left: 30px;
    margin-right: 30px;
    max-width: 240px;
    width: 100%;
        ${Responsive.laptop}{
            margin-left: 20px;
            margin-right: 20px;
            max-width: 200px;
        }
        ${Responsive.tablet}{
            margin-left: 20px;
            margin-right: 20px;
            max-width: 200px;
        }
        ${Responsive.mobile}{
            margin-left: unset;
            margin-right: unset;
            max-width: unset;
        }
`;
const PayMentHistoryHideButton = styled.button`
    height: 50px;
    width: 50px;
    border-radius: 6px;
    border: 1px solid #ebe8fc;
    background-color: #ebe8fc;
    margin: unset;
        &:focus,
        &:hover{
            outline: none;
        }
    ${Responsive.laptop}{
        height: 42px;
        width: 42px;
        border-radius: 4px;
    }
    ${Responsive.tablet}{
        height: 42px;
        width: 42px;
        border-radius: 4px;
    }
    ${Responsive.mobile}{
        height: 38px;
        width: 38px;
        font-family: unset;
        font-size: inherit;
        margin-top: 15px;
        float: right;
        clear: both;
    }
`;
const PlusIconForPayMent = styled(PlusIcon)`
    height: 10px;
    width: 10px;
    margin-right: 10px;
    margin-top: 18px;
        ${Responsive.laptop}{
            margin-right: 10px;
            margin-top: 14px;
        }
        ${Responsive.tablet}{
            margin-right: 10px;
            margin-top: 14px;
        }
        ${Responsive.mobile}{
            margin-right: 10px;
            margin-top: 12px;
        }
`;
const HideIconForPayMent = styled(HideIcon)`
    width: 40%;
    height: 40%;
        ${Responsive.laptop}{
            margin-top: 2px;
        }
        ${Responsive.tablet}{
            margin-top: 2px;
        }
        ${Responsive.mobile}{
            /* margin-top: 2px; */
            width: 40%;
            height: 68%;
        }
`;
// header style

// payment selects
const PayMentSelectContainer = styled.div`
 border-top: 1px solid #F2F5F7;
 padding-top: 50px;
        ${Responsive.mobile}{
            padding-top: 15px;
        }
`;
const PayMentHName = styled.input`
    height: 50px;
    width: 30%;
    border: 1px solid #E5E6ED;
    border-radius: 6px;
    padding-left: 20px;
    color: #AAAABE;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    &:focus,
        &:hover{
            outline: none;
        }
    ${Responsive.laptop}{
        height: 42px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 14px;
    }
    ${Responsive.tablet}{
        height: 42px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 14px;
    }
    ${Responsive.mobile}{
        height: 38px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 12px;
        width: 48%;
        margin-right: 2%;
    }
`;
const PayMentHFromDate = styled.select`
    height: 50px;
    width: 30%;
    border: 1px solid #E5E6ED;
    border-radius: 6px;
    padding-left: 20px;
    color: #AAAABE;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin-left: 5%;
    margin-right: 5%;
    &:focus,
        &:hover{
            outline: none;
        }
    ${Responsive.laptop}{
    height: 42px;
    padding-left: 15px;
    border-radius: 4px;
    font-size: 14px;
    }
    ${Responsive.tablet}{
        height: 42px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 14px;
    }
    ${Responsive.mobile}{
        height: 38px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 12px;
        width: 48%;
        margin: unset;
        margin-left: 2%;
    }
`;
const PayMentHTomDate = styled.select`
    height: 50px;
    width: 30%;
    border: 1px solid #E5E6ED;
    border-radius: 6px;
    padding-left: 20px;
    color: #AAAABE;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    &:focus,
        &:hover{
            outline: none;
        }
    ${Responsive.laptop}{
    height: 42px;
    padding-left: 15px;
    border-radius: 4px;
    font-size: 14px;
    }
    ${Responsive.tablet}{
        height: 42px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 14px;
    }
    ${Responsive.mobile}{
        height: 38px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 12px;
        width: 48%;
        margin-right: 2%;
        margin-top: 15px;
    }
`;
const PayMentHFromAmount = styled.input`
    height: 50px;
    width: 22%;
    border: 1px solid #E5E6ED;
    border-radius: 6px;
    padding-left: 20px;
    color: #AAAABE;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin-top: 30px;
    &:focus,
        &:hover{
            outline: none;
        }
    ${Responsive.laptop}{
    height: 42px;
    padding-left: 15px;
    border-radius: 4px;
    font-size: 14px;
    }
    ${Responsive.tablet}{
        height: 42px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 14px;
    }
    ${Responsive.mobile}{
        height: 38px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 12px;
        width: 48%;
        margin: unset;
        margin-left: 2%;
        margin-top: 15px;
    }
`;
const PayMentHToAmount = styled.input`
    height: 50px;
    width: 22%;
    border: 1px solid #E5E6ED;
    border-radius: 6px;
    padding-left: 20px;
    color: #AAAABE;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin-top: 30px;
    margin-left: 4%;
    &:focus,
        &:hover{
            outline: none;
        }
    ${Responsive.laptop}{
    height: 42px;
    padding-left: 15px;
    border-radius: 4px;
    font-size: 14px;
    }
    ${Responsive.tablet}{
        height: 42px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 14px;
    }
    ${Responsive.mobile}{
        height: 38px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 12px;
        margin: unset;
        width: 48%;
        margin-right: 2%;
        margin-top: 15px;
    }
`;
const PayMentHStatus = styled.select`
    height: 50px;
    width: 22%;
    border: 1px solid #E5E6ED;
    border-radius: 6px;
    padding-left: 20px;
    color: #AAAABE;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin-top: 30px;
    margin-left: 4%;
    &:focus,
        &:hover{
            outline: none;
        }
    ${Responsive.laptop}{
    height: 42px;
    padding-left: 15px;
    border-radius: 4px;
    font-size: 14px;
    }
    ${Responsive.tablet}{
        height: 42px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 14px;
    }
    ${Responsive.mobile}{
        height: 38px;
        padding-left: 15px;
        border-radius: 4px;
        font-size: 12px;
        width: 48%;
        margin: unset;
        margin-left: 2%;
        margin-top: 15px;
    }
    
`;
const PayMentFilterButton = styled.button`
    height: 50px;
    width: 22%;
    border: 1px solid ${Colors.Primary};
    border-radius: 6px;
    color: ${Colors.Primary};
    background-color: inherit;
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliRegular};
    font-weight: 400;
    line-height: 50px;
    overflow: hidden;
    margin-left: 4%;
    margin-top: 30px;
    &:focus,
        &:hover{
            outline: none;
            background-color: ${Colors.HoverRed};
            color: #fff;
        }
    ${Responsive.laptop}{
    height: 42px;
    line-height: 44px;
    border-radius: 4px;
    font-size: 14px;
    }
    ${Responsive.tablet}{
    height: 42px;
    line-height: 44px;
    border-radius: 4px;
    font-size: 14px;
    }
    ${Responsive.mobile}{
    height: 38px;
    line-height: 41px;
    border-radius: 4px;
    font-size: 12px;
    margin: unset;
    margin-top: 15px;
    width: 100%;
    }
`;

// payment selects

// payment history table
const PayMentHistoryTable = styled.div`
    margin-top: 10px;
    padding-bottom: 50px;
    border-top: 1px solid #F2F5F7;

        ${Responsive.laptop}{
            margin-top: 30px;
        }
        ${Responsive.tablet}{
            margin-top: 30px;
        }
        ${Responsive.mobile}{
            margin-top: 20px;
        }
`;
const PayMentHistoryTableTitles = styled.div`
    margin-top: 50px;
        ${Responsive.laptop}{
            margin-top: 20px;
        }
        ${Responsive.tablet}{
            margin-top: 20px;
        }
        ${Responsive.mobile}{
            margin-top: 10px;
        }
`;
// payment history table
const PayMentHistoryTitleNames = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: #767880;
    margin: unset;
    user-select: none;
    line-height: 50px;
        &:nth-child(1){
           width: 5%;
           text-align: center;
           /* background-color: red; */
        }
        &:nth-child(2){
           width: 25%;
           text-align: left;
           /* background-color: green; */
        }
        &:nth-child(3){
           width: 25%;
           text-align: left;
           /* background-color: pink; */
        }
        &:nth-child(4){
           width: 25%;
           text-align: center;
           /* background-color: yellow; */
        }
        &:nth-child(5){
           width: 20%;
           text-align: right;
           /* background-color: orange; */
        }
    ${Responsive.mobile}{
        display: none;
    }
`;
const PayMentHistoryTableAnswer = styled.div`
    ${Responsive.mobile}{
        position: relative;
        /* background-color: gray; */
        height: 80px;
        border-bottom: 1px dashed #767880;
    }
`;
const PayMentHistoryTitleNamesAnswer = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: #767880;
    margin: unset;
    user-select: none;
    line-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
        &:nth-child(1){
           width: 5%;
           text-align: center;
           /* background-color: red; */
                ${Responsive.mobile}{
                    display: none;
                }
        }
        &:nth-child(2){
           width: 25%;
           text-align: left;
           /* background-color: green; */
                ${Responsive.mobile}{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 50%;
                }
        }
        &:nth-child(3){
           width: 25%;
           text-align: left;
           /* background-color: pink; */
                ${Responsive.mobile}{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 50%;
                }
        }
        &:nth-child(4){
           width: 25%;
           text-align: center;
           /* background-color: yellow; */
                ${Responsive.mobile}{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    text-align: right;
                    width: 50%;
                }
        }
        &:nth-child(5){
           width: 20%;
           text-align: right;
           /* background-color: orange; */
                ${Responsive.mobile}{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 50%;
                }
        }
    ${Responsive.mobile}{
        font-size: 12px;
        line-height: 40px;
    }
`;







function PaymentHistory() {

    const [show, setShow] = useState(true);

    return (
        <PayMentHistoryContainer>

            <PayMentHistoryHeader>
                <PayMentHistoryTitle>გადახდების ისტორია</PayMentHistoryTitle>
                <PayMentHistorySelect name="pay-ment-select" id="pay-ment-select">
                    <option selected value="pay-ment-option-one">SQ 432 WB</option>
                    <option value="pay-ment-option-two">HR 102 SW</option>
                </PayMentHistorySelect>
                <PayMentHistoryPayButtonLink to={"/"}> 
                    <PayMentHistoryPayButton> <PlusIconForPayMent />პრემიის გადახდა</PayMentHistoryPayButton>
                </PayMentHistoryPayButtonLink>
                <PayMentHistoryHideButton onClick={() => setShow(!show)}><HideIconForPayMent /></PayMentHistoryHideButton>
            </PayMentHistoryHeader>
            
                {/* selects */}
        {show ?  
            <PayMentSelectContainer>
                <PayMentHName type="text" placeholder="დასახელება"/>

                <PayMentHFromDate name="date" id="pay-ment-from-date">
                    <option disabled selected value="1">თარიღი-დან</option>
                    <option value="2">23/04/2020</option>
                </PayMentHFromDate>

                <PayMentHTomDate name="" id="pay-ment-to-date">
                    <option disabled selected value="1">თარიღი-მდე</option>
                    <option value="2">23/04/2021</option>
                </PayMentHTomDate>

                <PayMentHFromAmount type="number" placeholder="თანხა-დან"/>

                <PayMentHToAmount type="number" placeholder="თანხა-მდე"/>

                <PayMentHStatus name="" id="">
                    <option disabled selected value="">სტატუსი</option>
                    <option value="">მიმდინარე</option>
                </PayMentHStatus>

                <PayMentFilterButton>ფილტრი</PayMentFilterButton>
            </PayMentSelectContainer>
        : null}

            {/* payment history table */}
            <PayMentHistoryTable>
                <PayMentHistoryTableTitles>
                    <PayMentHistoryTitleNames>N</PayMentHistoryTitleNames>
                    <PayMentHistoryTitleNames>დასახელება</PayMentHistoryTitleNames>
                    <PayMentHistoryTitleNames>თარიღი</PayMentHistoryTitleNames>
                    <PayMentHistoryTitleNames>თანხა</PayMentHistoryTitleNames>
                    <PayMentHistoryTitleNames>სტატუსი</PayMentHistoryTitleNames>
                </PayMentHistoryTableTitles>

                <PayMentHistoryTableAnswer>
                    <PayMentHistoryTitleNamesAnswer>01</PayMentHistoryTitleNamesAnswer>
                    <PayMentHistoryTitleNamesAnswer>ავტო დაზღვევა</PayMentHistoryTitleNamesAnswer>
                    <PayMentHistoryTitleNamesAnswer>09 აგვისტო 2020</PayMentHistoryTitleNamesAnswer>
                    <PayMentHistoryTitleNamesAnswer>100 ₾</PayMentHistoryTitleNamesAnswer>
                    <PayMentHistoryTitleNamesAnswer>დადასტურებული</PayMentHistoryTitleNamesAnswer>
                </PayMentHistoryTableAnswer>
                <PayMentHistoryTableAnswer>
                    <PayMentHistoryTitleNamesAnswer>01</PayMentHistoryTitleNamesAnswer>
                    <PayMentHistoryTitleNamesAnswer>ავტო დაზღვევა</PayMentHistoryTitleNamesAnswer>
                    <PayMentHistoryTitleNamesAnswer>09 აგვისტო 2020</PayMentHistoryTitleNamesAnswer>
                    <PayMentHistoryTitleNamesAnswer>100 ₾</PayMentHistoryTitleNamesAnswer>
                    <PayMentHistoryTitleNamesAnswer>დადასტურებული</PayMentHistoryTitleNamesAnswer>
                </PayMentHistoryTableAnswer>
            </PayMentHistoryTable>

        </PayMentHistoryContainer>
    )
}

export default PaymentHistory
