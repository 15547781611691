import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from "@reduxjs/toolkit";
import { setAuthToken } from "../config";
import { getApiClient as api } from "../config";
import { requests } from "../config";
import { AuthState, User, UserData } from "../domain";

const sliceName = "auth";

// Data fetching Thunks

export const checkPersonalNumber = createAsyncThunk<
  { success: boolean; phoneMasked: string; messages?: string[] },
  { personalNumber: string },
  { rejectValue: SerializedError }
>(
  `${sliceName}/checkPersonalNumber`,
  async ({ personalNumber }, { rejectWithValue }) => {
    try {
      const { data } = await api().post(requests.auth.checkPersonalNumber, {
        personalNumber,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const enterRegisterSmsCode = createAsyncThunk<
  { success: boolean; messages?: string[] },
  { personalNumber: string; smsCode: string },
  { rejectValue: SerializedError }
>(
  `${sliceName}/enterRegisterSmsCode`,
  async ({ personalNumber, smsCode }, { rejectWithValue }) => {
    try {
      const { data } = await api().post(requests.auth.enterRegisterSmsCode, {
        personalNumber,
        smsCode,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const registerUser = createAsyncThunk<
  UserData,
  { personalNumber: string; password: string; passwordConfirmation: string; },
  { rejectValue: SerializedError }
>(
  `${sliceName}/registerUser`,
  async (
    { personalNumber, password, passwordConfirmation }, //{ firstName, lastName, personalNumber, email, password },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api().post(requests.auth.registerUser, {
        personalNumber,
        password,
        passwordConfirmation,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const loginUser = createAsyncThunk<
  UserData,
  Pick<User, "personalNumber" | "password">,
  { rejectValue: SerializedError }
>(
  `${sliceName}/loginUser`,
  async ({ personalNumber, password }, { rejectWithValue }) => {
    try {
      const { data } = await api().post(requests.auth.loginUser, {
        personalNumber,
        password,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const loadUser = createAsyncThunk<
  User,
  undefined,
  { rejectValue: SerializedError }
>(`${sliceName}/loadUser`, async (_, { rejectWithValue }) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthToken(localStorage.token);
    try {
      const { data } = await api().get(requests.auth.userData);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
});

export const resetPasswordCheckPersonalNumber = createAsyncThunk<
  { success: boolean; phoneMasked: string },
  { personalNumber: string },
  { rejectValue: SerializedError }
>(`${sliceName}/resetPasswordCheckPersonalNumber`, async ({ personalNumber }, { rejectWithValue }) => {
  try {
    const { data } = await api().post(requests.auth.resetPasswordCheckPersonalNumber, {
      personalNumber,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const resetPasswordEnterSmsCode = createAsyncThunk<
  { success: boolean; messages?: string[] },
  { smsCode: string, personalNumber: string },
  { rejectValue: SerializedError }
>(
  `${sliceName}/resetPasswordEnterSmsCode`,
  async ({ personalNumber, smsCode }, { rejectWithValue }) => {
    try {
      const { data } = await api().post(requests.auth.resetPasswordEnterSmsCode, {
        personalNumber,
        smsCode,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetPasswordProvideNewPassword = createAsyncThunk<
  UserData,
  { personalNumber: string; password: string; passwordConfirmation: string; },
  { rejectValue: SerializedError }
>(
  `${sliceName}/resetPasswordProvideNewPassword`,
  async (
    { personalNumber, password, passwordConfirmation }, //{ firstName, lastName, personalNumber, email, password },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api().post(requests.auth.resetPasswordProvideNewPassword, {
        personalNumber,
        password,
        passwordConfirmation,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

// update profile
type UpdateProfilePayload = { 
  email?: string;
  //avatar?: File; // not implemented below in POST request (doesn't use FormData)
  avatarPath?: string;
  idCardFrontPath?: string;
  idCardBackPath?: string;
  phone?: string;
  address?: string;
  bankName?: string;
  bankAccountNumber?: string;
  bankAccountHolder?: string;
};
type UpdatePasswordParams = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};
export const updateProfile = createAsyncThunk<
  { user: User; },
  UpdateProfilePayload | UpdatePasswordParams | (UpdateProfilePayload & UpdatePasswordParams),
  { rejectValue: SerializedError }
>(
  `${sliceName}/updateProfile`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await api().post<{ user: User }>(requests.auth.updateProfile, params);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendUpdatePasswordSMS = createAsyncThunk<
  { success: boolean; },
  undefined,
  { rejectValue: SerializedError }
>(
  `${sliceName}/sendUpdatePasswordSMS`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await api().post<{ success: boolean }>(requests.auth.sendUpdatePasswordSMS, params);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const checkUpdatePasswordSMS = createAsyncThunk<
  { isValid: boolean; },
  { smsCode: string; },
  { rejectValue: SerializedError }
>(
  `${sliceName}/sendUpdatePasswordSMS`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await api().post<{ isValid: boolean }>(requests.auth.checkUpdatePasswordSMS, params);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
 

// User Slice
const initialState: AuthState = {
  token: localStorage.getItem("token"),
  user: null,
  registrationPersonalNumber: "",
  checkPersonalNumberLoading: false,
  registrationPhoneMasked: "",
  enterRegisterSmsCodeLoading: false,
  registerLoading: false,
  userLoading: false,
  loginLoading: false,
  resetPasswordPersonalNumber: "",
  resetPasswordCheckPersonalNumberLoading: false,
  resetPasswordPhoneMasked: "",
  resetPasswordEnterSmsCodeLoading: false,
  resetPasswordProvideNewPasswordLoading: false,
  error: null,
  updateProfileLoading: false,
};
export const authInitialStateForMocking = initialState;

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    logoutUser(state) {
      localStorage.removeItem("token");
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkPersonalNumber.pending, (state, action) => {
        state.checkPersonalNumberLoading = true;
      })
      .addCase(checkPersonalNumber.fulfilled, (state, { meta, payload }) => {
        state.checkPersonalNumberLoading = false;
        state.registrationPersonalNumber = meta.arg.personalNumber;
        state.registrationPhoneMasked = payload.phoneMasked;
        state.error = null;
      })
      .addCase(checkPersonalNumber.rejected, (state, action) => {
        state.checkPersonalNumberLoading = false;
        state.registrationPersonalNumber = '';
        state.error = action.payload || action.error;
      })
      //
      .addCase(enterRegisterSmsCode.pending, (state) => {
        state.enterRegisterSmsCodeLoading = true;
      })
      .addCase(enterRegisterSmsCode.fulfilled, (state, { payload }) => {
        state.enterRegisterSmsCodeLoading = false;
        state.error = null;
      })
      .addCase(enterRegisterSmsCode.rejected, (state, action) => {
        state.enterRegisterSmsCodeLoading = false;
        state.error = action.payload || action.error;
      })
      //
      .addCase(registerUser.pending, (state) => {
        state.registerLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        localStorage.setItem("token", payload.token);
        // auto login after registration
        setAuthToken(payload.token);
        state.token = payload.token;
        state.registerLoading = false;
        state.user = payload.user;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registerLoading = false;
        // state.user = null;
        // state.token = null;
        state.error = action.payload || action.error;
      })
      .addCase(loginUser.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        localStorage.setItem("token", payload.token);
        setAuthToken(payload.token);
        state.token = payload.token;
        state.user = payload.user;
        state.loginLoading = false;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.user = null;
        setAuthToken("");
        state.token = null;
        state.loginLoading = false;
        state.error = action.payload || action.error;
      })
      .addCase(loadUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(loadUser.fulfilled, (state, { payload }) => {
        state.userLoading = false;
        state.user = payload;
        state.error = null;
      })
      .addCase(loadUser.rejected, (state, action) => {
        localStorage.removeItem("token");
        setAuthToken("");
        state.token = null;
        state.userLoading = false;
        state.user = null;
        state.error = action.payload || action.error;
      })
      //
      .addCase(resetPasswordCheckPersonalNumber.pending, (state, action) => {
        state.resetPasswordCheckPersonalNumberLoading = true;
      })
      .addCase(resetPasswordCheckPersonalNumber.fulfilled, (state, { meta, payload }) => {
        state.resetPasswordCheckPersonalNumberLoading = false;
        state.resetPasswordPersonalNumber = meta.arg.personalNumber;
        state.resetPasswordPhoneMasked = payload.phoneMasked;
        state.error = null;
      })
      .addCase(resetPasswordCheckPersonalNumber.rejected, (state, action) => {
        state.resetPasswordCheckPersonalNumberLoading = false;
        state.resetPasswordPersonalNumber = '';
        state.error = action.payload || action.error;
      })
      //
      .addCase(resetPasswordEnterSmsCode.pending, (state) => {
        state.resetPasswordEnterSmsCodeLoading = true;
      })
      .addCase(resetPasswordEnterSmsCode.fulfilled, (state, { payload }) => {
        state.resetPasswordEnterSmsCodeLoading = false;
        state.error = null;
      })
      .addCase(resetPasswordEnterSmsCode.rejected, (state, action) => {
        state.resetPasswordEnterSmsCodeLoading = false;
        state.error = action.payload || action.error;
      })
      //
      .addCase(resetPasswordProvideNewPassword.pending, (state) => {
        state.registerLoading = true;
      })
      .addCase(resetPasswordProvideNewPassword.fulfilled, (state, { payload }) => {
        localStorage.setItem("token", payload.token);
        // auto login after registration
        setAuthToken(payload.token);
        state.token = payload.token;
        state.registerLoading = false;
        state.user = payload.user;
        state.error = null;
      })
      .addCase(resetPasswordProvideNewPassword.rejected, (state, action) => {
        state.registerLoading = false;
        // state.user = null;
        // state.token = null;
        state.error = action.payload || action.error;
      })
      //
      .addCase(updateProfile.pending, (state, action) => {
        state.updateProfileLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, { meta, payload }) => {
        state.updateProfileLoading = false;
        state.user = payload.user;
        state.error = null;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.updateProfileLoading = false;
        state.error = action.payload || action.error;
      });
  },
});

export const { logoutUser } = slice.actions;

export default slice.reducer;
