import React from 'react';
// import Switch from 'react-input-switch';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
// images
import RegistrationBackground from '../../background-images/registration-background-1.png';
import AlphaLogo from '../../icons/alpha-white-logo.svg';
// import AlphaLogoJs from '../../icons/AlphaWhiteLogo';
import Fonts from '../../styles/Fonts';
import Responsive from "../../config/Responsive";
import Colors from '../../styles/Colors';
import AlphaOLdLogoSVG from '../../icons/alpha-old-logo.svg';


 
// left side
const RegistrationLeftSide = styled.div`
    flex-basis: 50%;
    height: 100vh;
    position: relative;
        ${Responsive.desktop}{
            height: 100vh;
        }
        ${Responsive.laptop}{
            height: 100vh;
        }
        ${Responsive.tablet}{
            width: 50%;
            height: 100%;
        }
        ${Responsive.mobile}{
            position: unset;
            margin: 0 auto;
            width: 300px;
            height: 100%;
        }
`;
const RegistrationBackgroundImg = styled.img`
    height: 100%;
        ${Responsive.tablet}{
            margin-left: -25%;
        }
        ${Responsive.mobile}{
            display: none;
        }
`;
// right side
const RegistrationRightSide = styled.div`
    flex-basis: 50%;
    margin-top: 29vh;
    display: flex;
    justify-content: center;
        ${Responsive.laptop}{
            margin-top: 0px;
            align-items: center;  
        }
        ${Responsive.tablet}{
            margin-top: 0px;
            align-items: center;  
        }
        ${Responsive.mobile}{
            margin-top: 0px;
            align-items: center;  
        }
`;
const RegisterContent = styled.div`
    display: flex;
    overflow: hidden;
    ${Responsive.mobile}{
        flex-direction: column-reverse;
    }
`;
const RegisterLeftFirstText = styled.h1`
    font-size: 24px;
    color: #ffffff;
    margin: unset;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
        ${Responsive.desktop}{
            font-size: 24px;
        }
        ${Responsive.laptop}{
            font-size: 16px;
        }
        ${Responsive.tablet}{
            font-size: 16px;
        }
`;
const RegisterJoinUsText = styled.h1`
    font-size: 70px;
    color: #ffffff;
    margin: unset;
    line-height: 90px;
    margin-top: 35px;
    font-family: ${Fonts.FiraGOMtavruliBold};
        ${Responsive.desktop}{
            font-size: 50px;
            line-height: 55px;
            margin-top: 35px;
        }
        @media (min-width: 1200px) and (max-width: 3200px) and (max-height: 700px){
            font-size: 35px !important;
            line-height: 45px !important;
        }
        ${Responsive.laptop}{
            font-size: 46px;
            line-height: 50px;
            margin-top: 25px;
        }
        ${Responsive.tablet}{
            font-size: 35px;
            line-height: 35px;
            margin-top: 25px;
        }
`;
 const RegisterLeftTextBox = styled.div`
    position: absolute;
    left: 130px;
    top: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
        ${Responsive.desktop}{
            left: 130px;
            top: 30%;
        }
        ${Responsive.laptop}{
            left: 100px;
            top: 33%;
        }
        ${Responsive.tablet}{
            left: 50px;
            top: 33%;
        }
        ${Responsive.mobile}{
            display: none;
        }
 `;
 const RegistrationLeftSwitchBox = styled.div`
    position: absolute;
    left: 130px;
    top: 58%;
    display: flex;
    justify-content: center;
    align-items: center;
        ${Responsive.desktop}{
            left: 130px;
            top: 58%;
        }
        ${Responsive.laptop}{
            left: 100px;
            top: 58%;
        }
        ${Responsive.tablet}{
            left: 50px;
            top: 58%;
        }
        ${Responsive.mobile}{
            position: absolute;
            left: unset;
            top: unset;
            bottom: 10%;
        }
 `;
const AuthorizationText = styled(Link)`
    font-size: 24px;
    color: #ffffff;
    margin: unset;
    margin-right: 23px;
    line-height: 30px;
    cursor: default;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    &:hover {
      color: white;
      cursor: pointer;
      text-decoration: none;
    }
        ${Responsive.desktop}{
            font-size: 24px;
            margin-right: 23px;
            line-height: 30px;
        }
        ${Responsive.laptop}{
            font-size: 20px;
            margin-right: 23px;
            line-height: 30px;
        }
        ${Responsive.tablet}{
            font-size: 16px;
            margin-right: 23px;
            line-height: 33px;
        }
        ${Responsive.mobile}{
            font-size: 16px;
            margin-right: 23px;
            line-height: 33px;
            color: ${Colors.Gray};
        }
 `;
const RegistrationText = styled(Link)`
    font-size: 24px;
    color: #ffffff;
    margin: unset;
    margin-left: 23px;
    line-height: 30px;
    cursor: default;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    &:hover {
      color: white;
      cursor: pointer;
      text-decoration: none;
    }
        ${Responsive.desktop}{
            font-size: 24px;
            margin-left: 23px;
            line-height: 30px;
        }
        ${Responsive.laptop}{
            font-size: 20px;
            margin-left: 23px;
            line-height: 30px;
        }
        ${Responsive.tablet}{
            font-size: 16px;
            margin-left: 23px;
            line-height: 33px;
        }
        ${Responsive.mobile}{
            font-size: 16px;
            margin-left: 23px;
            line-height: 33px;
            color: ${Colors.Gray};
        }
`;
const SwitchRegistrationButton = styled.div`
    width: 42px;
    height: 18px;
    margin: unset;
    border-radius: 19px;
    background-color: ${Colors.Primary};
    display: inline-block;
    margin-top: 9px;
    position: relative;
        ${Responsive.desktop}{
            width: 42px;
            height: 18px;
            border-radius: 19px;
            margin-top: 9px;
        }
        ${Responsive.laptop}{
            width: 37px;
            height: 15px;
            border-radius: 19px;
            margin-top: 9px;
        }
        
`;
const RegistrationSwitcherBall = styled.span`
    height: 28px;
    width: 28px;
    background-color: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -4px; 
    transition: left 2s ease-in;
        ${Responsive.desktop}{
            height: 28px;
            width: 28px;
            top: -5px;
            right: -4px; 
        }
        ${Responsive.laptop}{
            height: 24px;
            width: 24px;
            top: -4.5px;
            right: -4px; 
        }
        ${Responsive.mobile}{
            height: 24px;
            width: 24px;
            top: -3px;
            right: -4px; 
            background-color: ${Colors.Primary};
        }
`;
const SwitchLoginButton = styled.div`
    width: 42px;
    height: 18px;
    margin: unset;
    border-radius: 19px;
    background-color: ${Colors.Primary};
    display: inline-block;
    margin-top: 9px;
    position: relative;
        ${Responsive.desktop}{
            width: 42px;
            height: 18px;
            border-radius: 19px;
            margin-top: 9px;
        }
        ${Responsive.laptop}{
            width: 37px;
            height: 15px;
            border-radius: 19px;
            margin-top: 9px;
        }
`;
const LoginSwitcherBall = styled.span`
    height: 28px;
    width: 28px;
    background-color: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: -4px;
    transition: left 2s ease-in; 
        ${Responsive.desktop}{
            height: 28px;
            width: 28px;
            top: -5px;
            right: -4px; 
        }
        ${Responsive.laptop}{
            height: 24px;
            width: 24px;
            top: -4.5px;
            right: -4px; 
        }
        ${Responsive.mobile}{
            height: 24px;
            width: 24px;
            top: -3px;
            right: -4px; 
            background-color: ${Colors.Primary};
        }
`;
 //for now
const AlphaWhiteLogoSvg = styled.img`
    position: absolute;
    left: 140px;
    top: 65px;
    cursor: pointer;f
        ${Responsive.desktop}{
            left: 140px;
            top: 65px;
        }
        ${Responsive.laptop}{
            left: 100px;
            top: 65px;
        }
        ${Responsive.tablet}{
            left: 50px;
            top: 65px;
        }
        ${Responsive.mobile}{
            display: none;
        }
`;
const AlphaOldLogo = styled.img`
    display: none;
     ${Responsive.mobile}{
        display: block;
        position: absolute;
        top: 30px;
        left: 25px;
     }
`;

const AuthLayout: React.FC = ({ children }) => {

  const location = useLocation();

  const isLoginFlow = location.pathname.toLowerCase() === "/login" || location.pathname.toLowerCase() === "/reset-password" || location.pathname.toLowerCase() === "/reset-password-step-two" || location.pathname.toLowerCase() === "/reset-password-step-three";
  return (
    <RegisterContent>
      <RegistrationLeftSide>
          <RegistrationBackgroundImg src={RegistrationBackground} alt="RegistrationBackground" />
          <Link to='/login'>
          <AlphaWhiteLogoSvg src={AlphaLogo} alt="AlphaLogo" style={{height:60}} />
          </Link>
          <Link to='/login'>
          <AlphaOldLogo src={AlphaOLdLogoSVG} alt="AlphaOLdLogoSVG"/>
          </Link>
          <RegisterLeftTextBox>
            <RegisterLeftFirstText>გაიარე რეგისტრაცია</RegisterLeftFirstText>
            <RegisterJoinUsText>შემოგვიერთდი <br/> პორტალზე</RegisterJoinUsText>
          </RegisterLeftTextBox>
                <RegistrationLeftSwitchBox>
            <AuthorizationText to={'/login'}>ავტორიზაცია</AuthorizationText>
             <Link to={ isLoginFlow ? "/register" : "/login" }>
               {isLoginFlow ? <SwitchLoginButton> <LoginSwitcherBall /> </SwitchLoginButton> : <SwitchRegistrationButton> <RegistrationSwitcherBall /> </SwitchRegistrationButton>}
             </Link>
    <RegistrationText to={'/register'}>რეგისტრაცია</RegistrationText>
          </RegistrationLeftSwitchBox>
      </RegistrationLeftSide> 

      <RegistrationRightSide>
         {children}
      </RegistrationRightSide>
    </RegisterContent>
  );
}

export default AuthLayout;
