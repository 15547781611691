import React from "react";
import styled, { keyframes } from "styled-components";
import LoaderIcon from "../icons/LoaderSvg.svg";

const LoaderContainer = styled.div`
    user-select: none;
    background-color: rgba(0,0,0,.5);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingKeyFrame = keyframes`
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(360deg);
  }
`;
const LoadingIcon = styled.img`
  animation: ${LoadingKeyFrame};
  animation: ${LoadingKeyFrame} 2s linear infinite;
  user-select: none;
  pointer-events: none;
  height: 110px;
  width: 110px;
`;
function Loader() {
  return (
    <LoaderContainer>
      <LoadingIcon src={LoaderIcon} alt="LoaderIcon" />
    </LoaderContainer>
  );
}

export default Loader;
