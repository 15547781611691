import { SerializedError } from "@reduxjs/toolkit";
import { WatchOfFilesAndCompilerOptions } from "typescript";
import { PolicyTypeAbbr } from "./policyType";

// User types
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  firstNameEng: string;
  lastNameEng: string;
  gender: string;
  phone: string;
  email: string;
  address: string;
  password?: string;
  personalNumber: string;
  avatar: string;
  idCardFront?: string;
  idCardBack?: string;
  documentNumber: string;
  emailVerifiedAt: null;
  createdAt: string;
  updatedAt: string;
  bankName: string;
  bankAccountNumber: string;
  bankAccountHolder: string;
}

export interface AuthState {
  token: null | string;
  user: null | User;
  userLoading: boolean;
  loginLoading: boolean;
  //
  registrationPersonalNumber: string;
  checkPersonalNumberLoading: boolean;
  registrationPhoneMasked: string;
  enterRegisterSmsCodeLoading: boolean;
  registerLoading: boolean;
  //
  resetPasswordPersonalNumber: string;
  resetPasswordCheckPersonalNumberLoading: boolean;
  resetPasswordPhoneMasked: string;
  resetPasswordEnterSmsCodeLoading: boolean;
  resetPasswordProvideNewPasswordLoading: boolean;
  error: null | SerializedError;

  updateProfileLoading: boolean;
}

export interface UserData {
  user: User;
  token: string;
}

///

export type Policy = {
  title: string;
  insurancePrefix: PolicyTypeAbbr;
  policyNumber: string;
  cardNumber: string;
  packageName: string;
  policyPolid: string;
  policyCustomerId: string;
  objectName: string;
  personalNumber: string;
  birthday: Date;
  managerFirstName: string;
  managerLastName: string;
  managerPhone: string;
  managerEmail: string;
  managerPhotoUrl: string;
  managerPhotoId: string;
  vipManagerFirstName: string;
  vipManagerLastName: string;
  vipManagerPhone: string;
  vipManagerEmail: string;
  vipManagerPhotoUrl: string;
  vipManagerPhotoId: string;
  startsAt: Date;
  endsAt: Date;
  destroysAt: Date;
  totalCost: number;
  outstandingBalance: number;
  scheduledPaymentAmount: number;
  scheduledPaymentDate: Date;
  amountDueToday: number;
}

export type PolicyLimit = {
  id: number;
  isRootLevel: boolean;
  parentId: number;
  customerId: number;
  name: string;
  amount: number;
  isUnlimited: boolean;
  coveragePercent: number;
  usedAmount: number;
  isAppealNeeded: number;
  policyPolid: number;
  policyCustomerId: number;
  children: PolicyLimit[];
}

export const hasPersonalManager = (p: Policy) => p.managerFirstName?.trim().length && p.managerLastName?.trim().length;

export type UploadedFile = {
  fileName: string;
  url: string;
}
export type Claim = {
  id: number;
  policyNumber: string;
  policyPolid: string;
  status: string;
  title: string;
  comment: string;
  createdAt?: Date;
  updatedAt?: Date;
  // file upload fields:
  form100?: UploadedFile[];
  calculation?: UploadedFile[];
  invoice?: UploadedFile[];
  examResults?: UploadedFile[];
}

export type ClaimHistoryItem = {
  documentNumber: string;//"4000008",
  referenceNumber: string;// reference string sent by us (this codebase) when registering a new act; 'R#33' (claim, R for "Reference") or 'C#45' (Compensation)
  incidentNumber: number;//
  actNumber: number;//
  serviceType: string;//"მედიკამენტი",
  serviceName: string;//"WAA100 კონიუქტივის ზედაპირის ანესთეზია",
  providerName: string;//"შპს \"ავერსი-გეოფარმი\"",
  validFrom: Date;//Carbon instance
  validUntil: Date;//Carbon instance
  amount: number;// 0.0,
  compensatedPercent: number;// 80.0,
  outOfPocketPercent: number;// 0.0,
  statusText: string;//"განულებული",
  approved: boolean;//true
}

export type AddClaimUploadType = "form100" | "calculation" | "invoice" | "examResults";
export type AddReferenceUploadType = "form100" | "receipt";

export type Compensation = {
  id: number;
  policyNumber: string;
  policyPolid: string;
  type: 'ambulatory' | 'meds' | 'dental';
  status: string;
  title: string;
  comment: string;
  createdAt?: Date;
  updatedAt?: Date;
  // file upload fields:
  ambulatoryForm100?: UploadedFile[];
  ambulatoryCheck?: UploadedFile[];
  ambulatoryIncomeOrder?: UploadedFile[];
  ambulatoryCalculation?: UploadedFile[];
  ambulatoryInvoice?: UploadedFile[];
  ambulatoryExamResults?: UploadedFile[];
  medsForm100?: UploadedFile[];
  medsCheck?: UploadedFile[];
  medsIncomeOrder?: UploadedFile[];
  dentalForm100?: UploadedFile[];
  dentalCheck?: UploadedFile[];
  dentalCalculation?: UploadedFile[];
  dentalIncomeOrder?: UploadedFile[];
  dentalExamResults?: UploadedFile[];
  dentalXrayPhotos?: UploadedFile[];
}

export type CompensationHistoryItem = ClaimHistoryItem;// these come from the same API and have same structure

export type AddCompensationUploadType =
  // shared file types between compensation types
  // files specific to ambulatory type
  'ambulatoryForm100' |
  'ambulatoryCheck' |
  'ambulatoryIncomeOrder' |
  'ambulatoryCalculation' |
  'ambulatoryInvoice' |
  'ambulatoryExamResults' |
  // files specific to meds type
  'medsForm100' |
  'medsCheck' |
  'medsIncomeOrder' |
  // files specific to dental type
  'dentalForm100' |
  'dentalCheck' |
  'dentalCalculation' |
  'dentalIncomeOrder' |
  'dentalExamResults' |
  'dentalXrayPhotos';

export type ProviderClinic = {
  id: number;
  taxpayerId: string;
  name: string;
  country: string;
  region: string;
  city: string;
  address: string;
  phone: string;
  email: string;
  services: string;
}
export type Doctor = {
  id: number;
  personalNumber: string;
  firstName: string;
  lastName: string;
  birthday: Date;
  providerId: number;
  providerName: string;
  profession: string;
  phone?: string;
  workDays?: string;
  workingTime?: string;
}
export type VisitType = {
  code: 0 | 1 | 2 | 3;
  keyword: 'clinic' | 'home' | 'audio' | 'video';
  name: string;
}
export const VisitTypes: Record<VisitType['keyword'], VisitType> = {
  'clinic': { 'code': 0, 'keyword': 'clinic', 'name': 'ვიზიტი კლინიკაში', },
  'home':   { 'code': 1, 'keyword': 'home', 'name': 'ვიზიტი ბინაზე', },
  'audio':  { 'code': 2, 'keyword': 'audio', 'name': 'აუდიო კონსულტაცია', },
  'video':  { 'code': 3, 'keyword': 'video', 'name': 'ვიდეო კონსულტაცია', },
}
export type Visit = {
  coreVisitId: string;
  visitType?: VisitType['keyword'];
  visitingTime: Date;
  patientPersonalNumber: string;
  doctorPersonalNumber: string;
  rating?: number;
  comment?: string;
  policyNumber?: string;
  policyPolid?: string;
}
export interface InsuranceState {
  policies: Policy[];
  policiesLoading: boolean;
  policyLimits: PolicyLimit[];
  policyLimitsLoading: boolean;
  activeMedicalPolicy?: Policy;
  claims: Claim[];
  claimsLoading: boolean;
  claimHistory: ClaimHistoryItem[];
  claimHistoryLoading: boolean;
  addClaimLoading: boolean;
  compensations: Compensation[];
  compensationsLoading: boolean;
  compensationHistory: CompensationHistoryItem[];
  compensationHistoryLoading: boolean;
  addCompensationLoading: boolean;
  doctors: Doctor[];
  doctorsLoading: boolean;
  providers: ProviderClinic[];
  providersLoading: boolean;
  visits: Visit[];
  visitsLoading: boolean;
  visitsHistory: Visit[];
  visitsHistoryLoading: boolean;
  selectedDoctorPersonalNumber: string;
  selectedDoctorFreeDays: Date[];
  selectedDoctorFreeDaysLoading: boolean;
  selectedDoctorFreeHours: string[];
  selectedDoctorFreeHoursLoading: boolean;
  addVisitLoading: boolean;
  cancelVisitLoading: boolean;
}


// TODO remove this
export type UpdateProfile = {
  email: string;
  password: string;
  passwordConfirmation: string;
  avatar: string;
  phone: string;
  address: string;
  bankName: string;
  bankAccountNumber: string;
  bankAccountHolder: string;
}
