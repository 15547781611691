import HealthIcon from "../icons/HealthIcon";
import TravelIcon from "../icons/TravelIcon";
import AutoIcon from "../icons/AutoIcon";
import Harvest from "../icons/AgroIcon";
import Property from "../icons/PropertyIcon";
import Luggage from "../icons/LuggageIcon";
import Responsibility from "../icons/ResponsibilityIcon";
import Freight from "../icons/FreightIcon";
import BankGuaranty from "../icons/BankGuarantyIcon";
import { Policy } from "./types";

export type PolicyType = {
  abbr: PolicyTypeAbbr[];
  keyword: PolicyTypeKeyword;
  title: string;
  icon: (...args: any) => JSX.Element;
};

export type PolicyTypeAbbr =
  | "MED"
  | "HI"
  | "MHL"
  | "MI"
  | "AAUT"
  | "AUTO"
  | "MOR"
  | "TRW"
  | "TNT"
  | "HRT"
  | "HRB"
  | "PI"
  | "PFN"
  | "RST"
  | "FRH"
  | "PRB"
  | "BNK"
  | "UNKNOWN";
export type PolicyTypeKeyword =
  | "health"
  | "auto"
  | "travel"
  | "transport"
  | "harvest"
  | "harvestbusiness"
  | "property"
  | "professional"
  | "responsibility"
  | "freight"
  | "propertybusiness"
  | "banking"
  | "unknown";

export const policyTypes: { [key in PolicyTypeKeyword]: PolicyType } = {
  health: {
    abbr: ["HI", "MED"],
    keyword: "health",
    title: "ჯანმრთელობა",
    icon: HealthIcon,
  },
  auto: {
    abbr: ["AAUT", "MHL", "MOR", "AUTO", "MI"],
    keyword: "auto",
    title: "ავტო",
    icon: AutoIcon,
  },
  transport: {
    abbr: ["TNT"],
    keyword: "transport",
    title: "სატრანსპორტო",
    icon: AutoIcon,
  },
  travel: {
    abbr: ["TRW"],
    keyword: "travel",
    title: "სამოგზაურო",
    icon: TravelIcon,
  },
  harvest: {
    abbr: ["HRT"],
    keyword: "harvest",
    title: "მოსავალი",
    icon: Harvest,
  },
  harvestbusiness: {
    abbr: ["HRB"],
    keyword: "harvestbusiness",
    title: "მოსავალი ბიზნესისთვის",
    icon: Harvest,
  },
  property: {
    abbr: ["PI"],
    keyword: "property",
    title: "ქონება",
    icon: Property,
  },
  propertybusiness: {
    abbr: ["PRB"],
    keyword: "propertybusiness",
    title: "ქონება ბიზნესისთვის",
    icon: Property,
  },
  professional: {
    abbr: ["PFN"],
    keyword: "professional",
    title: "პროფესიული",
    icon: Luggage,
  },
  responsibility: {
    abbr: ["RST"],
    keyword: "responsibility",
    title: "პასუხისმგებლობა",
    icon: Responsibility,
  },
  freight: {
    abbr: ["FRH"],
    keyword: "freight",
    title: "ტვირთი",
    icon: Freight,
  },
  banking: {
    abbr: ["BNK"],
    keyword: "banking",
    title: "საბანკო გარანტია",
    icon: BankGuaranty,
  },
  unknown: {
    abbr: ["UNKNOWN"],
    keyword: "unknown",
    title: "უცნობი",
    icon: BankGuaranty,
  },
};
export const getPolicyTypeByAbbr = (abbr: PolicyTypeAbbr) =>
  Object.values(policyTypes).find((t) => t.abbr.indexOf(abbr) !== -1) ||
  policyTypes.unknown;
export const getPolicyType = (keyword: PolicyTypeKeyword) =>
  policyTypes[keyword] || policyTypes.unknown;

export const isOfPolicyType = (keyword: PolicyTypeKeyword) => (
  policy: Policy
) => {
  return getPolicyTypeByAbbr(policy.insurancePrefix).keyword === keyword;
};

export const typeOfPolicy = (policy: Policy) => {
  return getPolicyTypeByAbbr(policy.insurancePrefix);
};
