import React from 'react'

// for style
import styled from 'styled-components';
import Responsive from 'config/Responsive';
import Fonts from 'styles/Fonts';
import Colors from 'styles/Colors';

import Accordion from "../../components/TravelDetailAccordion";
import { Link } from 'react-router-dom';

import RightArrow from "../../icons/RightArrow";

const GrayLineForTravelDetail = styled.div`
    width: 100%;
    height: 1px;
    background-color: #F2F5F7;
    margin-top: 50px;
    margin-bottom: 50px;
        ${Responsive.laptop}{
            margin-top: 30px;
            margin-bottom: 30px;
        }
        ${Responsive.tablet}{
            margin-top: 30px;
            margin-bottom: 30px;
        }
        ${Responsive.mobile}{
            margin-top: 25px;
            margin-bottom: 25px;
        }
`;


const TravelPolicyDetailContainer = styled.div`
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    border-radius: 10px;

        ${Responsive.laptop}{
            
        }
        ${Responsive.tablet}{
           
        }
        ${Responsive.mobile}{
           
        }
`;
const TravelPolicyDetailMainWrapper = styled.div`
    padding: 50px;
        ${Responsive.laptop}{
            padding: 35px;
        }
        ${Responsive.tablet}{
            padding: 35px;
        }
        ${Responsive.mobile}{
            padding: 30px;
        }
`;
const TravelPolicyDetailTitle = styled.h1`
    font-size: 28px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    margin: unset;
    color: ${Colors.TitleBlue};
        ${Responsive.laptop}{
            font-size: 20px;
        }
        ${Responsive.tablet}{
            font-size: 20px;
        }
        ${Responsive.mobile}{
            font-size: 16px;
        }
`;
const TravelPolicyDetailTitleDescription = styled.h1`
    font-size: 20px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin: unset;
    color: ${Colors.Gray};
    display: block;
    margin-bottom: 50px;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 12px;
        }
`;

const TravelPolicyDetailWrapper = styled.div`

`;
const TravelPolicyDetailMainTitle = styled.h1`
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    margin: unset;
    color: ${Colors.Gray};
    margin-bottom: 30px;
        ${Responsive.laptop}{
            font-size: 16px;
            margin-bottom: 30px;
        }
        ${Responsive.tablet}{
            font-size: 16px;
            margin-bottom: 30px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
            margin-bottom: 25px;
        }
`;
const TravelPolicyDetailMainDescription = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin: unset;
    color: ${Colors.Gray};
    display: block;
    line-height: 30px;
        ${Responsive.laptop}{
            font-size: 14px;
            line-height: 25px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
            line-height: 25px;
        }
        ${Responsive.mobile}{
            font-size: 12px;
            line-height: 20px;
        }
`;


const TravelPolicyDetailInfromation = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
        ${Responsive.mobile}{
            flex-direction: column;
            align-items: flex-start;
        }
`;
const TravelPolicyDetailContractLink = styled(Link)`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: #261e56;
    text-decoration: none;
    margin-right: 50px;
        &:focus,
        &:hover{
            color: ${Colors.HoverRed};
            text-decoration: none;
        }
    ${Responsive.laptop}{
        margin-right: 30px;
        font-size: 12px;
    }
    ${Responsive.tablet}{
        margin-right: 20px;
        font-size: 12px;
        line-height: 25px;
    }
    ${Responsive.mobile}{
        margin-right: unset;
        font-size: 12px;
        line-height: 35px;
    }
`;
const TravelPolicyDetailContractArrow = styled(RightArrow)`
    width: 28px;
    padding-left: 10px;
    margin-top: 1px;
        ${Responsive.laptop}{
            padding-left: 8px;
            width: 25px;
            margin-top: 2px;
        }
        ${Responsive.tablet}{
            padding-left: 5px;
            width: 20px;
            margin-top: 2px;
        }
        ${Responsive.mobile}{
            padding-left: 5px;
            width: 20px;
            margin-top: 7px;
        }
`;
const TravelPolicyDetailContractButton = styled.button`
    height: 50px;
    width: 200px;
    border-radius: 6px;
    border: 1px solid ${Colors.Primary};
    background-color: ${Colors.Primary};
    outline: none;
    color: #fff;
    font-size: 14px;
    font-family: ${Fonts.FiraGOMtavruliBold};
        &:focus,
        &:hover{
            outline: none;
            background-color: ${Colors.HoverRed};
        }
    ${Responsive.laptop}{
        width: 190px;
        height: 42px;
        font-size: 12px;
        border-radius: 4px;
    }
    ${Responsive.tablet}{
        width: 190px;
        height: 42px;
        font-size: 12px;
        border-radius: 4px;
    }
    ${Responsive.mobile}{
        width: 100%;
        height: 38px;
        font-size: 12px;
        border-radius: 4px;
        margin-top: 20px;
    }
`;
const TravelPolicyDetailLinkWrapper = styled.div`
    ${Responsive.tablet}{
        display: flex;
        flex-direction: column;
    }
    ${Responsive.mobile}{
        display: flex;
        flex-direction: column;
    }
`;



function TravelPolicyDetail() {
    return (
        <TravelPolicyDetailContainer>
            <TravelPolicyDetailMainWrapper>

           <TravelPolicyDetailTitle>სამოგზაურო დაზღვევის პოლისის დეტალები</TravelPolicyDetailTitle>
           <TravelPolicyDetailTitleDescription>გაეცანი პირობებს და გააგზავნე განაცხადი</TravelPolicyDetailTitleDescription>

            <TravelPolicyDetailWrapper>
                <TravelPolicyDetailMainTitle>პასუხისმგებლობის დაზღვევა</TravelPolicyDetailMainTitle>
                <TravelPolicyDetailMainDescription>მესამე პირის სიცოცხლის დაზღვევა</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>მესამე პირის ჯანმრთელობის დაზღვევა</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>მესამე პირის ქონების დაზღვევა</TravelPolicyDetailMainDescription>
            </TravelPolicyDetailWrapper>

            <GrayLineForTravelDetail />

            <TravelPolicyDetailWrapper>
                <TravelPolicyDetailMainTitle>მძღოლისა და მგზავრების დაზღვევა</TravelPolicyDetailMainTitle>
                <TravelPolicyDetailMainDescription>დაზღვევა ითვალისწინებს ავტოსაგზაო შემთხვევის შედეგად მძღოლისა და მგზავრების სიცოცხლის და ჯანმრთელობის დაზღვევას.</TravelPolicyDetailMainDescription>
            </TravelPolicyDetailWrapper>

            <GrayLineForTravelDetail />

            <TravelPolicyDetailWrapper>
                <TravelPolicyDetailMainTitle>მოქნილი გადახდის გრაფიკი</TravelPolicyDetailMainTitle>
                <TravelPolicyDetailMainDescription>ერთჯერადი</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>ორჯერადი</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>კვარტლური</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>ყოველთვიური</TravelPolicyDetailMainDescription>
            </TravelPolicyDetailWrapper>

            <GrayLineForTravelDetail />

            <Accordion />

            <GrayLineForTravelDetail />

            <TravelPolicyDetailWrapper>
                <TravelPolicyDetailMainTitle>დამატებითი მომსახურება</TravelPolicyDetailMainTitle>
                <TravelPolicyDetailMainDescription>24 საათიანი ასისტანსი</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>ავტომობილის დაქოქვა</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>საბურავის გამოცვლა</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>საწვავის მიწოდება</TravelPolicyDetailMainDescription>
                <TravelPolicyDetailMainDescription>ევაკუატორით მომსახურება</TravelPolicyDetailMainDescription>    
            </TravelPolicyDetailWrapper>

            <GrayLineForTravelDetail />


            <TravelPolicyDetailInfromation>
                <TravelPolicyDetailLinkWrapper>
                    <TravelPolicyDetailContractLink to="/">
                        სადაზღვევო ხელშეკრულება <TravelPolicyDetailContractArrow />
                    </TravelPolicyDetailContractLink>

                    <TravelPolicyDetailContractLink to="/" style={{ marginRight: 'unset' }}>
                        დამატებითი დაფარვის პირობები <TravelPolicyDetailContractArrow />
                    </TravelPolicyDetailContractLink>
                </TravelPolicyDetailLinkWrapper>

                <TravelPolicyDetailContractButton>განაცხადის გაგზავნა</TravelPolicyDetailContractButton>

            </TravelPolicyDetailInfromation>

            </TravelPolicyDetailMainWrapper>
        </TravelPolicyDetailContainer>
    )
}

export default TravelPolicyDetail
