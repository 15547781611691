import React from 'react'
import styled from 'styled-components';

import { Doctor, InsuranceState, ProviderClinic,  } from '../../domain';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';
import Responsive from "../../config/Responsive";
import ButtonFilterIcon from '../../icons/ButtonFilter';
import { useState } from 'react';
import DoctorAvatar from "../../images/DoctorsAvatar.jpg";
import Close from "../../icons/CloseIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import Calendar from "../../icons/CalendarIcon";
import Checkmark from "../../icons/CheckMarkIcon";
import Clock from "../../icons/ClockIcon";
import { useSelector } from 'react-redux';
import { RootState } from 'slices';





const DoctorPopUpGrayLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #F2F5F7;
    margin-top: 30px;
    margin-bottom: 30px;
        ${Responsive.laptop}{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        ${Responsive.tablet}{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        ${Responsive.mobile}{
            margin-top: 20px;
            margin-bottom: 20px;
        }
`;


const SearchFamilyDoctorWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    min-height: 100vh;
`;
const DoctorFilterBox = styled.div`
    padding: 0px 50px;
    height: 135px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${Colors.GrayLine};
        ${Responsive.laptop}{
            height: 85px;
            padding: 0px 35px;
        }
        ${Responsive.tablet}{
            height: 85px;
            padding: 0px 35px;
        }
        ${Responsive.mobile}{
            height: 75px;
            padding: 0px 30px;
        }
`;
const DoctorFilterLeftTitle = styled.h2`
    font-size: 18px;
    color: ${Colors.TitleBlue};
    font-family: ${Fonts.FiraGOMtavruliBold};
    margin-bottom: 0px;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;
const DoctorFilterRightButton = styled.button`
    background-color: ${Colors.PrimaryVeryLight};
    border-radius: 6px;
    width: 50px;
    height: 50px;
    outline: none;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
        &:focus {
            outline: none;
        }
    ${Responsive.laptop}{
        border-radius: 4px;
        width: 42px;
        height: 42px;
    }
    ${Responsive.tablet}{
        border-radius: 4px;
        width: 42px;
        height: 42px;
    }
    ${Responsive.mobile}{
        border-radius: 4px;
        width: 38px;
        height: 38px;
    }
`;
const DoctorFilterRightButtonIcon = styled(ButtonFilterIcon)`
    height: 13px;
    width: 16px;
        ${Responsive.laptop} {
            height: 10px;
            width: 14px;
        }
        ${Responsive.tablet} {
            height: 10px;
            width: 14px;
        }
        ${Responsive.mobile} {
            height: 10px;
            width: 14px;
        }
`;


const DoctorsFilterInputsBox = styled.div`
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    margin-bottom: 15px;
        ${Responsive.laptop} {
            height: 110px;
            padding: 0px 35px;
        }
        ${Responsive.tablet} {
            height: 110px;
            padding: 0px 35px;
        }
        ${Responsive.mobile} {
            height: unset;
            padding: 0px 30px;
            flex-direction: column;
            margin-top: 10px;
        }
`;
const DoctorsName = styled.input`
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    padding-left: 30px;
    height: 50px;
    width: 24%;
    border-radius: 6px;
    border: 1px solid ${Colors.Border};
    background-color: inherit;
        &:Focus {
            outline: none;
        }
    ${Responsive.laptop} {
        height: 42px;
        border-radius: 4px;
        font-size: 14px;
        padding-left: 20px;
    }
    ${Responsive.tablet} {
        height: 42px;
        border-radius: 4px;
        font-size: 12px;
        padding-left: 20px;
    }
    ${Responsive.mobile} {
        min-height: 38px;
        border-radius: 4px;
        font-size: 12px;
        padding-left: 20px;
        width: 100%;
        margin-bottom: 10px;
    }
`;
const DoctorsProfession = styled.input`
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    padding-left: 30px;
    height: 50px;
    width: 24%;
    border-radius: 6px;
    border: 1px solid ${Colors.Border};
    background-color: inherit;
        &:Focus {
            outline: none;
    }
    ${Responsive.laptop} {
        height: 42px;
        border-radius: 4px;
        font-size: 14px;
        padding-left: 20px;
    }
    ${Responsive.tablet} {
        height: 42px;
        border-radius: 4px;
        font-size: 12px;
        padding-left: 20px;
    }
    ${Responsive.mobile} {
        min-height: 38px;
        border-radius: 4px;
        font-size: 12px;
        padding-left: 20px;
        width: 100%;
        margin-bottom: 10px;
    }
`;
const DoctorsClinic = styled.input`
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    padding-left: 30px;
    height: 50px;
    width: 24%;
    border-radius: 6px;
    border: 1px solid ${Colors.Border};
    background-color: inherit;
        &:Focus {
            outline: none;
        }
    ${Responsive.laptop} {
        height: 42px;
        border-radius: 4px;
        font-size: 14px;
        padding-left: 20px;
    }
    ${Responsive.tablet} {
        height: 42px;
        border-radius: 4px;
        font-size: 12px;
        padding-left: 20px;
    }
    ${Responsive.mobile} {
        min-height: 38px;
        border-radius: 4px;
        font-size: 12px;
        padding-left: 20px;
        width: 100%;
        margin-bottom: 10px;
    }
`;
const DoctorsFilterButton = styled.button`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliRegular};
    color: ${Colors.Primary};
    height: 50px;
    width: 24%;
    border-radius: 6px;
    background-color: inherit;
    border: 1px solid ${Colors.Primary};
        &:Focus {
            outline: none;
            background-color: ${Colors.HoverRed};
            color: #ffffff;
        }
    ${Responsive.laptop} {
        height: 42px;
        border-radius: 4px;
        font-size: 14px;
    }
    ${Responsive.tablet} {
        height: 42px;
        border-radius: 4px;
        font-size: 12px;
    }
    ${Responsive.mobile} {
        min-height: 38px;
        border-radius: 4px;
        font-size: 12px;
        width: 80%;
    }
`;



const DoctorListWrapper = styled.div`
    max-height: 100vh;
    overflow: scroll;
    ${Responsive.mobile}{
        padding: 0px 30px;
    }
`;
const DoctorListTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 50px;
        ${Responsive.laptop}{
            padding: 0px 35px;
        }
        ${Responsive.tablet}{
            padding: 0px 35px;
        }
        ${Responsive.mobile}{
            padding: 0px 30px;
        }
`;
const DoctorListTitle = styled.h6`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.LightGray};
    margin-bottom: 0px;
    line-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
        &:nth-child(1) {
            width: 7%;
            text-align: center;
            /* background-color: red; */
        }
        &:nth-child(2) {
            width: 28%;
            text-align: left;
            padding-left: 1%;
            /* background-color: green; */
        }
        &:nth-child(3) {
            padding-left: 1%;
            text-align: left;
            width: 20%;
            /* background-color: pink; */
        }
        &:nth-child(4) {
            padding-left: 1%;
            text-align: right;
            width: 45%;
            /* background-color: gray; */
        }
    ${Responsive.laptop}{
        font-size: 12px;
        line-height: 40px;
    }
    ${Responsive.tablet}{
        font-size: 12px;
        line-height: 40px;
    }
    ${Responsive.mobile}{
        display: none;
    }
`;

const DoctorListContentWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-top: 5px;
    background-color: inherit;
    padding: 0px 50px;
    
        &:focus,
        &:hover {
            background-color: ${Colors.PrimaryVeryLight};
            text-decoration: unset;
            cursor: pointer;
            transition: all 300ms;
        }
    ${Responsive.laptop}{
        padding: 0px 35px;
    }
    ${Responsive.tablet}{
        padding: 0px 35px;
    }
    ${Responsive.mobile}{
        padding: unset;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 30px;
        border-bottom: 1px solid ${Colors.Border};
    }
`;
const DoctorListContent = styled.div`
    line-height: 120px;
    height: 120px;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;

        &:nth-child(1) {
            width: 7%;
            text-align: center;
            /* background-color: red; */
                ${Responsive.mobile}{
                    display: none;
                }
        }

        &:nth-child(2) {
            width: 28%;
            text-align: left;
            padding-left: 1%;
            display: flex;
            align-items: center;
            /* background-color: green; */
                ${Responsive.mobile}{
                    width: 100%;
                    padding-left: 0px;
                    margin-top: 20px;
                }
        }

        &:nth-child(3) {
            padding-left: 1%;
            text-align: left;
            width: 20%;
            /* background-color: pink; */
                ${Responsive.mobile}{
                    width: 100%;
                    padding-left: 50px;
                    margin-top: -17px;
                    margin-bottom: 20px;
                }
        }

        &:nth-child(4) {
            padding-left: 1%;
            text-align: right;
            width: 45%;
            /* background-color: gray; */
                ${Responsive.mobile}{
                    width: 100%;
                    text-align: left;
                    padding-left: 0px;
                }
        }

    ${Responsive.laptop}{
        line-height: 80px;
        height: 80px;
        font-size: 14px;
    }
    ${Responsive.tablet}{
        line-height: 80px;
        height: 80px;
        font-size: 14px;
    }
    ${Responsive.mobile}{
        height: inherit;
        line-height: unset;
        font-size: 14px;
    }
`;
const DoctorImage = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
        ${Responsive.laptop}{
            height: 40px;
            width: 40px;
            margin-right: 10px;
        }
        ${Responsive.tablet}{
            height: 40px;
            width: 40px;
            margin-right: 10px;
        }
        ${Responsive.mobile}{
            height: 40px;
            width: 40px;
            margin-right: 10px;
        }
`;
const DoctorFirstLastName = styled.h3`
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0px;

        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            margin-top: -15px;
            font-size: 14px;
        }
`;


const DoctorDetailInfoPopUp = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
        ${Responsive.mobile}{
            align-items: flex-end;
        }
`;
const DoctorPopUpContent = styled.div`
    width: 550px;
    height: 650px;
    /* border-radius: 10px; */
    background-color: #FFFFFF;
    overflow: scroll;
        ${Responsive.laptop}{
            width: 440px;
            height: 540px;
        }
        ${Responsive.tablet}{
            width: 440px;
            height: 540px;
        }
        ${Responsive.mobile}{
            width: 100%;
            height: 500px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
`;
const CloseIcon = styled(Close)`
    height: 25px;
    width: 25px;
    color: ${Colors.Gray};
`;
const DoctorListCloseButton = styled.button`
    height: 30px;
    width: 30px;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 36px 36px 0px auto;
    background-color: inherit;
        &:focus {
            outline: none;
        }
        &:hover {
            ${CloseIcon} {
                transform: rotate(180deg);
                transition: all 300ms;
            }
        }
    ${Responsive.laptop}{
        margin: 30px 30px 0px auto;
    }
    ${Responsive.tablet}{
        margin: 30px 30px 0px auto;
    }
    ${Responsive.mobile}{
        margin: 30px 30px 0px auto;
    }
`;
const DoctorPopUpInsideContent = styled.div`
    /* background-color: green; */
    padding: 0px 50px 35px 50px;
    width: 100%;
        ${Responsive.mobile}{
            padding: 0px 30px 20px 30px;
        }
`;
const PopUpDoctorInfoWrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;
const PopUpDoctorImage = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 30px;
        ${Responsive.laptop}{
            width: 60px;
            height: 60px;
            margin-right: 20px;
        }
        ${Responsive.tablet}{
            width: 60px;
            height: 60px;
            margin-right: 20px;
        }
        ${Responsive.mobile}{
            width: 60px;
            height: 60px;
            margin-right: 20px;
        }
`;
const PopUpDoctorFirstLastName = styled.h3`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin-bottom: 0px;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;
const PopUpDoctorProfession = styled.h6`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    margin-top: 5px;
    margin-bottom: 0px;
        ${Responsive.laptop}{
            font-size: 12px;
            margin-top: 3px;
        }
        ${Responsive.tablet}{
            font-size: 12px;
            margin-top: 3px;
        }
        ${Responsive.mobile}{
            font-size: 12px;
            margin-top: 3px;
        }
`;
const PopUpDoctorPhone = styled.div`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    margin-bottom: 0px;
        ${Responsive.laptop}{
            font-size: 12px;
        }
        ${Responsive.tablet}{
            font-size: 12px;
        }
        ${Responsive.mobile}{
            font-size: 12px;
        }
`;
const DoctorPhoneIcon = styled(PhoneIcon)`
    height: 16px;
    width: 16px;
    margin-right: 23px;
        ${Responsive.laptop}{
            margin-right: 11px;
            height: 11px;
            width: 11px;
        }
        ${Responsive.tablet}{
            margin-right: 11px;
            height: 11px;
            width: 11px;
        }
        ${Responsive.mobile}{
            margin-right: 11px;
            height: 11px;
            width: 11px;
        }
`;
const DoctorClinicLocation = styled.div`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    margin-bottom: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
        ${Responsive.laptop}{
            font-size: 12px;
            margin-bottom: 14px;
        }
        ${Responsive.tablet}{
            font-size: 12px;
            margin-bottom: 14px;
        }
        ${Responsive.mobile}{
            font-size: 12px;
            margin-bottom: 14px;
        }
`;
const CheckmarkIcon = styled(Checkmark)`
    width: 15px;
    height: 18px;
    color: ${Colors.Gray};
    margin-right: 15px;
        ${Responsive.laptop}{
            width: 12px;
            height: 14px;
            margin-right: 10px;
        }
        ${Responsive.tablet}{
            width: 12px;
            height: 14px;
            margin-right: 10px;
        }
        ${Responsive.mobile}{
            width: 12px;
            height: 14px;
            margin-right: 10px;
        }
`;

const CalendarIcon = styled(Calendar)`
    width: 14px;
    height: 14px;
    color: ${Colors.Gray};
    margin-right: 15px;
        ${Responsive.laptop}{
            width: 11px;
            height: 11px;
            margin-right: 10px;
        }
        ${Responsive.tablet}{
            width: 11px;
            height: 11px;
            margin-right: 10px;
        }
        ${Responsive.mobile}{
            width: 11px;
            height: 11px;
            margin-right: 10px;
        }
`;
const ClockIcon = styled(Clock)`
    width: 16px;
    height: 16px;
    color: ${Colors.Gray};
    margin-right: 15px;
        ${Responsive.laptop}{
            width: 13px;
            height: 13px;
            margin-right: 10px;
        }
        ${Responsive.tablet}{
            width: 13px;
            height: 13px;
            margin-right: 10px;
        }
        ${Responsive.mobile}{
            width: 13px;
            height: 13px;
            margin-right: 10px;
        }
`;
const RecordWithDoctorButton = styled.button`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    line-height: 50px;
    height: 50px;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    border: 0px;
    background-color: ${Colors.Primary};
    margin-top: 30px;
        &:focus {
            outline: none;
        }
    ${Responsive.laptop}{
        font-size: 14px;
        line-height: 42px;
        height: 42px;
        border-radius: 4px;
        margin-top: 20px;
    }
    ${Responsive.tablet}{
        font-size: 14px;
        line-height: 42px;
        height: 42px;
        border-radius: 4px;
        margin-top: 20px;
    }
    ${Responsive.mobile}{
        font-size: 14px;
        line-height: 38px;
        height: 38px;
        border-radius: 4px;
        margin-top: 20px;
    }
`;



// fake doctors data
const FakeDoctorData = [
    {
        number: "01",
        avatar: DoctorAvatar,
        firstName: "მარიამ",
        lastName: "მამუკაშვილი",
        profession: "ტრავმატოლოგი",
        Clinics: "სამედიცინო ცენტრი ევექსი"
    },
    {
        number: "02",
        avatar: DoctorAvatar,
        firstName: "მირიან",
        lastName: "რამიშვილი",
        profession: "ოჯახის ექიმი",
        Clinics: "სამედიცინო ცენტრი ევექსი"
    },
    {
        number: "03",
        avatar: DoctorAvatar,
        firstName: "ნიკა",
        lastName: "ორჯოკინიძე",
        profession: "ოტო-რინი-ლარინგოლოგი",
        Clinics: "ავერსის კლინიკა"
    },
  ];





const SearchFamilyDoctor: React.FC = () => {
    const { policies, policiesLoading, doctors, doctorsLoading, providers, providersLoading } = useSelector<
    RootState,
    InsuranceState
  >((data) => data.insurance);

    const [show, setShow] = useState(true);
    // for popUp
    const [popUpIsOpen, setPopUpIsOpen] = useState(false);
    const [activeDoctor, setActiveDoctor] = useState<Doctor | undefined>(undefined);
    
    return (
        <SearchFamilyDoctorWrapper>
            


            <DoctorFilterBox>
                <DoctorFilterLeftTitle>ექიმები</DoctorFilterLeftTitle>
                <DoctorFilterRightButton onClick={() => setShow(!show)}><DoctorFilterRightButtonIcon /></DoctorFilterRightButton>
            </DoctorFilterBox>
            {show ?  
            <DoctorsFilterInputsBox>
                <DoctorsName type="text" placeholder="ექიმი" id="doctor-name" />
                <DoctorsProfession type="text" placeholder="პროფილი" id="doctor-profession" />
                <DoctorsClinic type="text" placeholder="კლინიკა" id="doctor-clinic"/>
                <DoctorsFilterButton>ფილტრი</DoctorsFilterButton>
            </DoctorsFilterInputsBox>
            : null}

            <DoctorListWrapper>
                <DoctorListTitleWrapper>
                    <DoctorListTitle>N</DoctorListTitle>
                    <DoctorListTitle>ექიმი</DoctorListTitle>
                    <DoctorListTitle>პროფილი</DoctorListTitle>
                    <DoctorListTitle>კლინიკა</DoctorListTitle>
                </DoctorListTitleWrapper>


                {doctors.map((doctor, index) => (
                    <DoctorListContentWrapper onClick={() => { setPopUpIsOpen(!popUpIsOpen); setActiveDoctor(doctor); }}>
                    <DoctorListContent>{`${index>8?'':'0'}${index+1}`}</DoctorListContent>
                    <DoctorListContent>
                        <DoctorImage src={DoctorAvatar}/>
                        <DoctorFirstLastName>{doctor.firstName} {doctor.lastName}</DoctorFirstLastName>
                    </DoctorListContent>
                    {doctor.profession ? <DoctorListContent>{doctor.profession}</DoctorListContent> : null}
                    {doctor.providerName ? <DoctorListContent>{doctor.providerName}</DoctorListContent> : null}
                  </DoctorListContentWrapper>
                ))}
                
            </DoctorListWrapper>
                    

                
            {popUpIsOpen && activeDoctor ?

                <DoctorDetailInfoPopUp >
                    <DoctorPopUpContent>
                        <DoctorListCloseButton onClick={() => setPopUpIsOpen(!popUpIsOpen)}>
                            <CloseIcon />
                        </DoctorListCloseButton>
                        
                        <DoctorPopUpInsideContent>
                            <PopUpDoctorInfoWrapper>
                                <PopUpDoctorImage src={DoctorAvatar} />
                                <div>
                                    <PopUpDoctorFirstLastName>{activeDoctor.firstName} {activeDoctor.lastName}</PopUpDoctorFirstLastName>
                                    <PopUpDoctorProfession>{activeDoctor.profession}</PopUpDoctorProfession>
                                </div>
                            </PopUpDoctorInfoWrapper>

                            <DoctorPopUpGrayLine />

                            {activeDoctor.phone ? <PopUpDoctorPhone> <DoctorPhoneIcon />{activeDoctor.phone}</PopUpDoctorPhone> : null}

                            <DoctorPopUpGrayLine />

                            {activeDoctor.providerName ? <DoctorClinicLocation> <CheckmarkIcon /> {activeDoctor.providerName}</DoctorClinicLocation> : null}
                            {activeDoctor.workDays ? <DoctorClinicLocation> <CalendarIcon />{activeDoctor.workDays}</DoctorClinicLocation> : null}
                            {activeDoctor.workingTime ? <DoctorClinicLocation> <ClockIcon />{activeDoctor.workingTime}</DoctorClinicLocation> : null}

                            <DoctorPopUpGrayLine />



                            <RecordWithDoctorButton>ექიმთან ჩაწერა</RecordWithDoctorButton>
                        </DoctorPopUpInsideContent>
                    

                    </DoctorPopUpContent>
                </DoctorDetailInfoPopUp>

            : null}
    

        </SearchFamilyDoctorWrapper>
    )
}

export default SearchFamilyDoctor;
