import * as React from "react";

function FileUploadIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.392 23.32"
      {...props}
    >
      <path
        d="M5.196 20.32a6.135 6.135 0 01-2.071-8.412l3.789-6.563a.817.817 0 011.11-.321.817.817 0 01.276 1.121l-3.79 6.563a4.5 4.5 0 001.52 6.169 4.5 4.5 0 006.102-1.77l4.583-7.938a2.863 2.863 0 00-.967-3.925 2.863 2.863 0 00-3.883 1.125L7.7 13.586a1.2 1.2 0 102.078 1.2l2.772-4.802a.8.8 0 111.386.8l-2.772 4.802a2.863 2.863 0 01-3.883 1.126 2.863 2.863 0 01-.967-3.926l4.167-7.217A4.5 4.5 0 0116.582 3.8 4.5 4.5 0 0118.1 9.969l-4.584 7.939a6.135 6.135 0 01-8.32 2.413zm0 0"
        fill="#767880"
      />
    </svg>
  );
}

export default FileUploadIcon;
