import * as React from "react";

function CalendarIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15.2 15.6"
      {...props}
    >
      <g
        data-name="Group 4813"
        transform="translate(.6 -.39)"
        strokeLinecap="round"
      >
        <rect
          data-name="Rectangle 2326"
          width={14}
          height={12}
          rx={1}
          transform="translate(0 3.39)"
          fill="currentColor"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
        <path
          data-name="Line 371"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          d="M3 1.39v4"
        />
        <path
          data-name="Line 372"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          d="M11 1.39v4"
        />
        <path
          data-name="Path 12901"
          d="M5.928 10.394H2.911"
          fill="none"
          stroke="#fefefe"
          strokeWidth={1.4}
        />
      </g>
    </svg>
  );
}

export default CalendarIcon;
