import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
// import CompensationType from "domain/compensationType";
import { getApiClient as api } from "../config";
import { requests } from "../config";
import {
  Doctor,
  Visit,
  ProviderClinic,
  InsuranceState,
  Policy,
  PolicyLimit,
  Claim,
  ClaimHistoryItem,
  AddClaimUploadType,
  Compensation,
  CompensationHistoryItem,
  AddCompensationUploadType, AddReferenceUploadType,
} from "../domain/types";
import { compensationType } from '../domain';
//import axios from "axios";
import dayjs from 'dayjs';
import { isOfPolicyType } from "domain/policyType";
import { VisitType } from "domain/types";

const sliceName = "insurance";

export const getPolicies = createAsyncThunk<
  Policy[],
  any,
  { rejectValue: SerializedError }
>(`${sliceName}/getPolicies`, async (noArgs, { rejectWithValue }) => {
  try {
    const { data } = await api().get<Policy[]>(requests.insurance.policies, {});
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getPolicyLimits = createAsyncThunk<
  PolicyLimit[],
  {
    policyPolid: string; 
    policyCustomerId: string; 
  },
  { rejectValue: SerializedError }
>(`${sliceName}/getPolicyLimits`, async ({ policyPolid, policyCustomerId }, { rejectWithValue }) => {
  try {
    const { data } = await api().post<PolicyLimit[]>(requests.insurance.policyLimits, { policyPolid, policyCustomerId  });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});


export const getDoctorFreeDays = createAsyncThunk<
  string[],
  { doctorPersonalNumber: string },
  { rejectValue: SerializedError }
>(`${sliceName}/getDoctorFreeDays`, async ({ doctorPersonalNumber }, { rejectWithValue }) => {
  try {
    const { data } = await api().post<string[]>(requests.insurance.visit.getDoctorFreeDays, { doctorPersonalNumber });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});



export const getDoctorFreeHours = createAsyncThunk<
  string[],
  { doctorPersonalNumber: string; day: string },
  { rejectValue: SerializedError }
>(`${sliceName}/getDoctorFreeHours`, async ({ doctorPersonalNumber, day }, { rejectWithValue }) => {
  try {
    const { data } = await api().post<string[]>(requests.insurance.visit.getDoctorFreeHours, {
      doctorPersonalNumber, day,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const addVisit = createAsyncThunk<
  Visit,
{
    policyNumber?: string;
    policyPolid?: string;
    visitTypeKeyword: VisitType['keyword'];
    doctorPersonalNumber: string;
    patientPersonalNumber: string;
    day: string;
    hour: string;
  },
  { rejectValue: SerializedError }
  >(`${sliceName}/addVisit`, async ({
    policyNumber,
    policyPolid,
    visitTypeKeyword,
    doctorPersonalNumber,
    patientPersonalNumber,
    day,
    hour,
  }, { rejectWithValue }) => {
  try {
    const { data } = await api().post<Visit>(requests.insurance.visit.addVisit, {
      policyNumber,
      policyPolid,
      visitTypeKeyword,
      doctorPersonalNumber,
      patientPersonalNumber,
      day,
      hour,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const cancelVisit = createAsyncThunk<
  { success: boolean; errorMessage?: string },
{
    coreVisitId: string; patientPersonalNumber: string;
  },
  { rejectValue: SerializedError }
  >(`${sliceName}/cancelVisit`, async ({
    coreVisitId,
    patientPersonalNumber
  }, { rejectWithValue }) => {
  try {
    const { data } = await api().post<{ success: boolean; errorMessage?: string }>(requests.insurance.visit.cancelVisit, {
      coreVisitId,
      patientPersonalNumber
    });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getDoctors = createAsyncThunk<
  Doctor[],
  any,
  { rejectValue: SerializedError }
>(`${sliceName}/getDoctors`, async (noArgs, { rejectWithValue }) => {
  try {
    const { data } = await api().get<Doctor[]>(requests.insurance.doctors, {});
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getProviders = createAsyncThunk<
  ProviderClinic[],
  any,
  { rejectValue: SerializedError }
>(`${sliceName}/getProviders`, async (noArgs, { rejectWithValue }) => {
  try {
    const { data } = await api().get<ProviderClinic[]>(requests.insurance.providers, {});
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getVisits = createAsyncThunk<
  Visit[],
any,
{ rejectValue: SerializedError }
  >(`${sliceName}/getVisits`, async (noArgs, { rejectWithValue }) => {
    try {
      const { data } = await api().get<Visit[]>(requests.insurance.visits, {});
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  });

export const getVisitsHistory = createAsyncThunk<
  Visit[],
  any,
  { rejectValue: SerializedError }
>(`${sliceName}/getVisitsHistory`, async (noArgs, { rejectWithValue }) => {
  try {
    const { data } = await api().get<Visit[]>(requests.insurance.visitsHistory, {});
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getClaims = createAsyncThunk<
  Claim[],
  any,
  { rejectValue: SerializedError }
>(`${sliceName}/getClaims`, async (noArgs, { rejectWithValue }) => {
  try {
    const { data } = await api().get<Claim[]>(requests.insurance.claims, {});
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getClaimHistory = createAsyncThunk<
  ClaimHistoryItem[],
  { policyPolid: string },
  { rejectValue: SerializedError }
>(`${sliceName}/getClaimHistory`, async ({ policyPolid }, { rejectWithValue }) => {
  try {
    const { data } = await api().post<ClaimHistoryItem[]>(
      requests.insurance.claimHistory,
      {
        policyPolid
      }
    );
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const addClaim = createAsyncThunk<
  Claim,
  {
    policyNumber: string;
    policyPolid: string;
    insuredPersonalNumber: string;
    status: "request";
    title: string;
    comment: string;
    files: Record<AddClaimUploadType, string[]>; // e.g. ['uploads/scan1.pdf', 'uploads/scan2.pdf']
  },
  { rejectValue: SerializedError }
>(`${sliceName}/addClaim`, async (requestData, { rejectWithValue }) => {
  try {
    const { data } = await api().post(requests.insurance.addClaim, {
      ...requestData,
      files: undefined,
      ...requestData.files,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});



//NEW METHOD
export const addReference = createAsyncThunk<
    Claim,
    {
      policyNumber: string;
      policyPolid: string;
      insuredPersonalNumber: string;
      status: "request";
      title: string;
      comment: string;
      serviceType: string;
      files: Record<AddReferenceUploadType, string[]>; // e.g. ['uploads/scan1.pdf', 'uploads/scan2.pdf']
    },
    { rejectValue: SerializedError }
>(`${sliceName}/addClaim`, async (requestData, { rejectWithValue }) => {
  try {
    const { data } = await api().post(requests.insurance.addReference, {
      ...requestData,
      files: undefined,
      ...requestData.files,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});




export const getCompensations = createAsyncThunk<
  Compensation[],
  any,
  { rejectValue: SerializedError }
>(`${sliceName}/getCompensations`, async (noArgs, { rejectWithValue }) => {
  try {
    const { data } = await api().get<Compensation[]>(
      requests.insurance.compensations,
      {}
    );
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getCompensationHistory = createAsyncThunk<
  CompensationHistoryItem[],
  { policyPolid: string },
  { rejectValue: SerializedError }
>(
  `${sliceName}/getCompensationHistory`,
  async ({ policyPolid }, { rejectWithValue }) => {
    try {
      const { data } = await api().post<CompensationHistoryItem[]>(
        requests.insurance.compensationHistory,
        {
          policyPolid
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addCompensation = createAsyncThunk<
  Compensation,
  {
    policyNumber: string;
    policyPolid: string;
    insuredPersonalNumber: string;
    type: compensationType.CompensationTypeKeyword;
    status: "request";
    title: string;
    comment: string;
    files: Record<AddCompensationUploadType, string[]>; // e.g. ['uploads/scan1.pdf', 'uploads/scan2.pdf']
  },
  { rejectValue: SerializedError }
>(`${sliceName}/addCompensation`, async (requestData, { rejectWithValue }) => {
  try {
    const { data } = await api().post(requests.insurance.addCompensation, {
      ...requestData,
      files: undefined,
      ...requestData.files,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const uploadFile = createAsyncThunk<
  { path: string },// the uploaded file path
  { file: File },
  { rejectValue: SerializedError }
>(`${sliceName}/uploadFile`, async ({ file }, { rejectWithValue }) => {
  try {
    const uploadFormData = new FormData();
    uploadFormData.append(
      // if we were submitting a single multipart/form-data request with all the fields, we'd use .append(`${fileType}[]`, ..), as in 'form100[]'
      'upload',
      file);
    const { data } = await api().post(requests.uploadFile, uploadFormData);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

// Insurance Slice
const initialState: InsuranceState = {
  policies: [],
  policiesLoading: true,
  policyLimits: [],
  policyLimitsLoading: true,
  activeMedicalPolicy: undefined,
  claims: [],
  claimsLoading: true,
  claimHistory: [],
  claimHistoryLoading: true,
  addClaimLoading: false,
  compensations: [],
  compensationsLoading: true,
  compensationHistory: [],
  compensationHistoryLoading: true,
  addCompensationLoading: false,
  doctors: [],
  doctorsLoading: true,
  providers: [],
  providersLoading: true,
  visits: [],
  visitsLoading: true,
  visitsHistory: [],
  visitsHistoryLoading: true,
  selectedDoctorPersonalNumber: '',
  selectedDoctorFreeDays: [],
  selectedDoctorFreeDaysLoading: false,
  selectedDoctorFreeHours: [],
  selectedDoctorFreeHoursLoading: false,
  addVisitLoading: false,
  cancelVisitLoading: false,
};
export const insuranceInitialStateForMocking = initialState;

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //   logoutUser(state) {
    //     localStorage.removeItem("token");
    //     state.user = null;
    //     state.token = null;
    //   },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPolicies.pending, (state, action) => {
        state.policiesLoading = true;
      })
      .addCase(getPolicies.fulfilled, (state, { meta, payload }) => {
        state.policiesLoading = false;
        state.policies = payload;
        // auto-select active medical policy
        const medicalPolicies = payload.filter(isOfPolicyType('health'));
        if(medicalPolicies.length){
          state.activeMedicalPolicy = medicalPolicies[0];
        }
        //state.error = null;
      })
      .addCase(getPolicies.rejected, (state, action) => {
        state.policiesLoading = false;
        console.log("getPolicies error", action.error);
        console.log(
          "მოხდა შეცდომა პოლისების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
      //policy limits
      .addCase(getPolicyLimits.pending, (state, action) => {
        state.policyLimitsLoading = true;
      })
      .addCase(getPolicyLimits.fulfilled, (state, { meta, payload }) => {
        state.policyLimitsLoading = false;
        state.policyLimits = payload;
        
      })
      .addCase(getPolicyLimits.rejected, (state, action) => {
        state.policyLimitsLoading = false;
        console.log("getPolicyLimits error", action.error);
        console.log(
          "მოხდა შეცდომა პოლისის ლიმიტების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
      //
      .addCase(getClaims.pending, (state, action) => {
        state.claimsLoading = true;
      })
      .addCase(getClaims.fulfilled, (state, { meta, payload }) => {
        state.claimsLoading = false;
        state.claims = payload;
        //state.error = null;
      })
      .addCase(getClaims.rejected, (state, action) => {
        state.claimsLoading = false;
        console.log("getClaims error", action.error);
        console.log(
          "მოხდა შეცდომა პოლისების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
      .addCase(getClaimHistory.pending, (state, action) => {
        state.claimHistoryLoading = true;
      })
      .addCase(getClaimHistory.fulfilled, (state, { meta, payload }) => {
        state.claimHistoryLoading = false;
        state.claimHistory = payload;
        //state.error = null;
      })
      .addCase(getClaimHistory.rejected, (state, action) => {
        state.claimHistoryLoading = false;
        console.log("getClaimHistory error", action.error);
        console.log(
          "მოხდა შეცდომა პოლისების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
      // add claim
      .addCase(addClaim.pending, (state, action) => {
        state.addClaimLoading = true;
      })
      .addCase(addClaim.fulfilled, (state, { meta, payload }) => {
        state.addClaimLoading = false;
        // state.error = null;
      })
      .addCase(addClaim.rejected, (state, action) => {
        state.addClaimLoading = false;
        // state.error = action.payload || action.error;
      })
      //
      .addCase(getCompensations.pending, (state, action) => {
        state.compensationsLoading = true;
      })
      .addCase(getCompensations.fulfilled, (state, { meta, payload }) => {
        state.compensationsLoading = false;
        state.compensations = payload;
        //state.error = null;
      })
      .addCase(getCompensations.rejected, (state, action) => {
        state.compensationsLoading = false;
        console.log("getCompensations error", action.error);
        console.log(
          "მოხდა შეცდომა პოლისების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
      .addCase(getCompensationHistory.pending, (state, action) => {
        state.compensationHistoryLoading = true;
      })
      .addCase(getCompensationHistory.fulfilled, (state, { meta, payload }) => {
        state.compensationHistoryLoading = false;
        state.compensationHistory = payload;
        //state.error = null;
      })
      .addCase(getCompensationHistory.rejected, (state, action) => {
        state.compensationHistoryLoading = false;
        console.log("getCompensationHistory error", action.error);
        console.log(
          "მოხდა შეცდომა პოლისების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
      // add compensation
      .addCase(addCompensation.pending, (state, action) => {
        state.addCompensationLoading = true;
      })
      .addCase(addCompensation.fulfilled, (state, { meta, payload }) => {
        state.addCompensationLoading = false;
        // state.error = null;
      })
      .addCase(addCompensation.rejected, (state, action) => {
        state.addCompensationLoading = false;
        // state.error = action.payload || action.error;
      })
      //
      .addCase(getDoctors.pending, (state, action) => {
        state.doctorsLoading = true;
      })
      .addCase(getDoctors.fulfilled, (state, { meta, payload }) => {
        state.doctorsLoading = false;
        state.doctors = payload;
        //state.error = null;
      })
      .addCase(getDoctors.rejected, (state, action) => {
        state.doctorsLoading = false;
        console.log("getPolicies error", action.error);
        console.log(
          "მოხდა შეცდომა პოლისების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
    //
      .addCase(getProviders.pending, (state, action) => {
        state.providersLoading = true;
      })
      .addCase(getProviders.fulfilled, (state, { meta, payload }) => {
        state.providersLoading = false;
        state.providers = payload;
        //state.error = null;
      })
      .addCase(getProviders.rejected, (state, action) => {
        state.providersLoading = false;
        console.log("getPolicies error", action.error);
        console.log(
          "მოხდა შეცდომა პოლისების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
        .addCase(getDoctorFreeDays.pending, (state, action) => {
          state.selectedDoctorFreeDaysLoading = true;
        })
        .addCase(getDoctorFreeDays.fulfilled, (state, { meta, payload }) => {
          state.selectedDoctorFreeDaysLoading = false;
          state.selectedDoctorFreeDays = payload.map((dayStr: string) => dayjs(dayStr).toDate());
          //state.error = null;
        })
    ///
      .addCase(getVisits.pending, (state, action) => {
        state.visitsLoading = true;
      })
      .addCase(getVisits.fulfilled, (state, { meta, payload }) => {
        state.visitsLoading = false;
        state.visits = payload;
        //state.error = null;
      })
      .addCase(getVisits.rejected, (state, action) => {
        state.visitsLoading = false;
        console.log("getVisits error", action.error);
        console.log(
          "მოხდა შეცდომა მიმდინარე ვიზიტების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
      .addCase(getVisitsHistory.pending, (state, action) => {
        state.visitsHistoryLoading = true;
      })
      .addCase(getVisitsHistory.fulfilled, (state, { meta, payload }) => {
        state.visitsHistoryLoading = false;
        state.visitsHistory = payload;
        //state.error = null;
      })
      .addCase(getVisitsHistory.rejected, (state, action) => {
        state.visitsHistoryLoading = false;
        console.log("getVisitsHistory error", action.error);
        console.log(
          "მოხდა შეცდომა დასრულებული ვიზიტების ჩატვირთვისას. " + action.error.message
        ); // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
    ///
      .addCase(getDoctorFreeDays.rejected, (state, action) => {
        state.selectedDoctorFreeDaysLoading = false;
        state.selectedDoctorFreeDays = [];
        console.log("getDoctorFreeDays error", action.error);
        console.log("მოხდა შეცდომა თავისუფალი საათების ჩატვირთვისას. " + action.error.message); 
        // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
        //
        .addCase(getDoctorFreeHours.pending, (state, action) => {
          state.selectedDoctorFreeHoursLoading = true;
        })
        .addCase(getDoctorFreeHours.fulfilled, (state, { meta, payload }) => {
          state.selectedDoctorFreeHoursLoading = false;
          state.selectedDoctorFreeHours = payload;
          //state.error = null;
        })
      .addCase(getDoctorFreeHours.rejected, (state, action) => {
        state.selectedDoctorFreeHoursLoading = false;
        state.selectedDoctorFreeHours = [];
        console.log("getDoctorFreeDays error", action.error);
        console.log("მოხდა შეცდომა თავისუფალი საათების ჩატვირთვისას. " + action.error.message); 
        // TODO change to toastAction
        //state.error = action.payload || action.error;
      })
      // add visit
      .addCase(addVisit.pending, (state, action) => {
        state.addVisitLoading = true;
      })
      .addCase(addVisit.fulfilled, (state, { meta, payload }) => {
        state.addVisitLoading = false;
        // state.error = null;
      })
      .addCase(addVisit.rejected, (state, action) => {
        state.addVisitLoading = false;
        // state.error = action.payload || action.error;
      })
      // cancel visit
      .addCase(cancelVisit.pending, (state, action) => {
        state.cancelVisitLoading = true;
      })
      .addCase(cancelVisit.fulfilled, (state, { meta, payload }) => {
        state.cancelVisitLoading = false;
        // state.error = null;
      })
      .addCase(cancelVisit.rejected, (state, action) => {
        state.cancelVisitLoading = false;
        // state.error = action.payload || action.error;
      });
      
  },
});

//export const { logoutUser } = slice.actions;

export default slice.reducer;
