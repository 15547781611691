import React from 'react';

function CompensationList(){
    return (
      <div>
        ფილტრაცია კლინიკების სიის გვერდზე
      </div>
    );
}

export default CompensationList;