import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../slices';
import {  resetPasswordProvideNewPassword } from '../../slices/authSlice';
// registerUser
import { AuthState } from '../../domain';
import { useAppDispatch } from '../../config/store';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';
import Responsive from "../../config/Responsive";

const ResetPasswordRightSide = styled.div`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const RightResetText = styled.div`
    font-size: 35px;
    color: #05124D;
    margin: unset;
    margin-bottom: 60px;
    cursor: default;
    font-family: ${Fonts.FiraGOMtavruliRegular};
        ${Responsive.desktop}{
            font-size: 35px;
            margin-bottom: 60px;
        }
        ${Responsive.laptop}{
            font-size: 22px;
            margin-bottom: 40px;
        }
        ${Responsive.tablet}{
            font-size: 22px;
            margin-bottom: 40px;
        }
        ${Responsive.mobile}{
            font-size: 16px;
            margin-bottom: 30px;
            margin-top: 150px;
        }
`;
const ResetRightButtonThree = styled.button`
    max-width: 440px;
    min-width: 300px;
    width: 100%;
    height: 70px;
    border-radius: 10px;
    background-color: ${Colors.Primary};
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-size: 20px;
    outline: none;
    border: unset;
    color: #ffffff;
    margin-top: 30px;
        &:focus, &:hover{
            outline: none;
            border: unset;
            background-color: ${Colors.HoverRed};
        }
        ${Responsive.desktop}{
            max-width: 440px;
            min-width: 300px;
            width: 100%;
            height: 70px;
            border-radius: 10px;
            font-size: 20px;
        }
        ${Responsive.laptop}{
            max-width: 320px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            border-radius: 6px;
            font-size: 14px;
        }
        ${Responsive.tablet}{
            max-width: 300px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            border-radius: 6px;
            font-size: 14px;
        }
        ${Responsive.mobile}{
            max-width: 300px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            border-radius: 6px;
            font-size: 14px;
        }
`;
const ResetStepTwoLastText = styled.h1`
    font-size: 14px;
    color: ${Colors.Gray};
    margin: unset;
    margin-top: 40px;
    cursor: default;
`;
const ResetPasswordPassInput = styled.input`
    max-width: 440px;
    min-width: 300px;
    width: 100%;
    height: 70px;
    font-size: 14px;
    color: #767880;
    margin: unset;
    outline: unset;
    padding-left: 30px;
    font-family: ${Fonts.FiraGORegular};
    border: 1px solid #767880;
    border-radius: 10px;
        ${Responsive.desktop}{
            max-width: 440px;
            min-width: 300px;
            width: 100%;
            height: 70px;
            font-size: 14px;
            border-radius: 10px;
        }
        ${Responsive.laptop}{
            max-width: 320px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            font-size: 12px;
            border-radius: 6px;
        }
        ${Responsive.tablet}{
            max-width: 300px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            font-size: 12px;
            border-radius: 6px;
        }
        ${Responsive.mobile}{
            max-width: 300px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            font-size: 12px;
            border-radius: 6px;
        }
`;
const ResetPaswordRetyPassInput = styled.input`
    max-width: 440px;
    min-width: 300px;
    width: 100%;
    height: 70px;
    font-size: 14px;
    color: #767880;
    margin: unset;
    margin-top: 20px;
    outline: unset;
    padding-left: 30px;
    font-family: ${Fonts.FiraGORegular};
    border: 1px solid #767880;
    border-radius: 10px;
        ${Responsive.desktop}{
            max-width: 440px;
            min-width: 300px;
            width: 100%;
            height: 70px;
            font-size: 14px;
            margin-top: 20px;
            border-radius: 10px;
        }
        ${Responsive.laptop}{
            max-width: 320px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            font-size: 12px;
            margin-top: 20px;
            border-radius: 6px;
        }
        ${Responsive.tablet}{
            max-width: 300px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            font-size: 12px;
            margin-top: 20px;
            border-radius: 6px;
        }
        ${Responsive.mobile}{
            max-width: 300px;
            min-width: 300px;
            width: 100%;
            height: 50px;
            font-size: 12px;
            margin-top: 20px;
            border-radius: 6px;
        }
`;
const ResetPassThreeDiv = styled.div`
    width: 100%;
    z-index: 1;
`;



const ResetPasswordStepThree: React.FC = () => {
  const auth = useSelector<RootState, AuthState>(state => state.auth);
  const personalNumber = useSelector<RootState, string>(state => state.auth.resetPasswordPersonalNumber);

  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

  const dispatch = useAppDispatch();
  const history = useHistory();
  return (
    <ResetPassThreeDiv>

      <ResetPasswordRightSide>
        <RightResetText>პაროლის აღდგენა</RightResetText>
        <ResetPasswordPassInput type="password" placeholder="ახალი პაროლი"
          onChange={e => setPassword(e.target.value)} />
        <ResetPaswordRetyPassInput type="password" placeholder="გაიმეორეთ პაროლი"
          onChange={e => setPasswordConfirmation(e.target.value)} />
        <ResetRightButtonThree disabled={password === '' || password !== passwordConfirmation} onClick={() => {
          dispatch(resetPasswordProvideNewPassword({ personalNumber, password, passwordConfirmation }))
            .then(unwrapResult)
            .then(result => {
              history.push('/dashboard');
            })
            .catch(error => {
              console.log(error);
              alert(error.messages && error.messages[0] || 'გთხოვთ, სცადოთ თავიდან.');
            });
        }}>შემდეგი</ResetRightButtonThree>
      </ResetPasswordRightSide>

    </ResetPassThreeDiv>
  );
}

export default ResetPasswordStepThree;


