import React from 'react';

function MyCompensation(){
    return (
      <div>
        ჩემი ელექტრონული ანაზღაურებები
      </div>
    );
}

export default MyCompensation;
