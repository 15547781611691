import React from 'react';
// for style
import Responsive from "config/Responsive";
import styled from "styled-components";
import Colors from "styles/Colors";
import Fonts from "styles/Fonts";
import NotificationFilter from "../../icons/Filter";
import { useState } from 'react';

import GarbageIcon from "icons/GarbageIcon";
import NotificationIcon from "icons/NotificationIcon";

const UserNotificationsContainer = styled.div`
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    border-radius: 10px;
`;
// user notificationtitle
const UserNotificationsWrapper = styled.div`
    padding: 50px;
        ${Responsive.laptop}{
            padding: 35px;
        }
        ${Responsive.tablet}{
            padding: 35px;
        }
        ${Responsive.mobile}{
            padding: 30px;
        }
`;
const UserInputsBox = styled.div`
    height: 120px;
    border-bottom: 1px solid #F2F5F7;
    display: flex;
    justify-content: space-between;
    align-items: center;
        ${Responsive.laptop}{
            height: 110px;
        }
        ${Responsive.tablet}{
            height: 110px;
        }
        ${Responsive.mobile}{
            height: 75px;
        }
`;
const UserTitle = styled.h1`
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    margin: unset;
    color: ${Colors.TitleBlue};
    user-select: none;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;
const UserInputsButton = styled.button`
    width: 50px;
    height: 50px;
    border: 1px solid #261e56;
    border-radius: 6px;
    background-color: #ebe8fc;
        &:focus,
        &:hover{
            outline: none;
            border: 1px solid ${Colors.HoverRed};
        }
    ${Responsive.laptop}{
        height: 40px;
        width: 40px;
        border-radius: 4px;
    }
    ${Responsive.tablet}{
        height: 40px;
        width: 40px;
        border-radius: 4px;
    }
    ${Responsive.mobile}{
        height: 40px;
        width: 40px;
        border-radius: 4px;
    }
`;
const NotificationFilterIcon = styled(NotificationFilter)`
    height: 12px;
    margin-top: 4px;
        ${Responsive.laptop}{
            height: 10px;
            margin-top: 5px;
        }
        ${Responsive.tablet}{
            height: 10px;
            margin-top: 5px;
        }
        ${Responsive.mobile}{
            height: 10px;
            margin-top: 5px;
        }
`;

// user notification inputs
const UserNotificationInputDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    border-bottom: 1px solid #F2F5F7;
        ${Responsive.laptop}{
            height: 120px;
        }
        ${Responsive.tablet}{
            height: 120px;
        }
        ${Responsive.mobile}{
            height: fit-content;
            flex-direction: column;
        }
`;
const UserNotificationInput = styled.input`
    width: 24%;
    height: 50px;
    border: 1px solid #E5E6ED;
    border-radius: 6px;
    color: #E5E6ED;
    padding-left: 15px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    font-size: 14px;
    opacity: unset;
        &:focus{
            outline: none;
        }
    ${Responsive.laptop}{
        height: 42px;
        font-size: 12px;
        border-radius: 4px;
    }
    ${Responsive.tablet}{
        height: 42px;
        font-size: 12px;
        border-radius: 4px;
    }
    ${Responsive.mobile}{
        width: 100%;
        height: 38px;
        font-size: 12px;
        border-radius: 4px;
        margin-top: 10px;
    }
`;
const UserNotificationSelect = styled.select`
    width: 24%;
    height: 50px;
    border: 1px solid #E5E6ED;
    border-radius: 6px;
    color: #E5E6ED;
    padding-left: 15px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    font-size: 14px;
        &:focus{
            outline: none;
        }
    ${Responsive.laptop}{
        height: 42px;
        font-size: 12px;
        border-radius: 4px;
    }
    ${Responsive.tablet}{
        height: 42px;
        font-size: 12px;
        border-radius: 4px;
    }
    ${Responsive.mobile}{
        width: 100%;
        margin-top: 10px;
        height: 38px;
        font-size: 12px;
        border-radius: 4px;
    }
`;
const UserNotificationFilterButto = styled.button`
    width: 24%;
    height: 50px;
    border: 1px solid ${Colors.Primary};
    border-radius: 6px;
    background-color: #fff;
    color: ${Colors.Primary};
    font-size: 14px;
    font-family: ${Fonts.FiraGOMtavruliRegular};
    font-weight: 400;

        &:focus{
            outline: none;
        }
    ${Responsive.laptop}{
        height: 42px;
        font-size: 12px;
        border-radius: 4px;
    }
    ${Responsive.tablet}{
        height: 42px;
        font-size: 12px;
        border-radius: 4px;
    }
    ${Responsive.mobile}{
        width: 100%;
        margin-top: 10px;
        height: 38px;
        font-size: 12px;
        border-radius: 4px;
    }
`;

// user notification messages
const UserNotificationMessageWrapper = styled.div`
    width: 100%;
    /* background-color: green; */
`;
const UserNotificationMessageTitleWrapper = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
        ${Responsive.laptop}{
            height: 35px;
        }
        ${Responsive.tablet}{
            height: 35px;
        }
        ${Responsive.mobile}{
            display: none;
        }
`;
const UserNotificationMessageTitle = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: #767880;
    margin: unset;
    line-height: 50px;
        &:nth-child(1){
            width: 5%;
            text-align: center;
            /* background-color: aqua; */
        }
        &:nth-child(2){
            width: 20%;
            text-align: left;
            /* background-color: pink; */
        }
        &:nth-child(3){
            width: 15%;
            text-align: left;
            /* background-color: orange; */
            padding-left: 5px;
        }
        &:nth-child(4){
            width: 50%;
            text-align: left;
            /* background-color: blue; */
            padding-left: 5px;
        }
    ${Responsive.laptop}{
        font-size: 12px;
        line-height: 35px;
    }
    ${Responsive.tablet}{
        font-size: 12px;
        line-height: 35px;
    }
`;
const UserNotificationGarbageDiv = styled.div`
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
`;
const NotificationGarbageIcon = styled(GarbageIcon)`
    height: 18px;
    vertical-align: middle;
    color: ${Colors.Gray};
        ${Responsive.laptop}{
            height: 16px;   
        }
        ${Responsive.tablet}{
            height: 16px;
            width: 20px;
        }
        ${Responsive.mobile}{
            height: 16px;
            width: 20px;
        }
`;
const GarbageNotificationButton = styled.button`
    background-color: inherit;
    padding: 1px;
    margin: unset;
    border: unset;
        &:focus,
        &:hover{
            outline: none;
        }
`;
const UserNotificationIcon = styled(NotificationIcon)`
    height: 15px;
    margin-top: 2px;
    vertical-align: middle;
    /* margin-right: 10px;  */
    color: ${Colors.Gray};
        ${Responsive.laptop}{
            height: 14px;
            margin-right: 5px; 
        }
        ${Responsive.tablet}{
            height: 14px;
            width: 20px;
            margin-right: 7px; 
        }
        ${Responsive.mobile}{
            height: 14px;
            width: 20px;
            margin-right: 12px;
        }
`;
const UserNotificationIconButton = styled.button`
    background-color: inherit;
    padding: 1px;
    margin: unset;
    border: unset;
        &:Focus{
            outline: none;
        }
`;

const UserNotificationTitleAnswerDiv = styled.div`
    ${Responsive.mobile}{
        height: 130px;
        /* background-color: green; */
        position: relative;
        display: flex;
        flex-direction: column;
            ${Responsive.mobile}{
                border-bottom: 1px dashed ${Colors.Gray};
            }
    }
`;
const UserNotificationTitleAnswer = styled.h1`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: #767880;
    margin: unset;
    line-height: 80px;
        &:nth-child(1){
            width: 5%;
            text-align: center;
            /* background-color: aqua; */
                ${Responsive.mobile}{
                    display: none;
                }
        }
        &:nth-child(2){
            width: 20%;
            text-align: left;
            /* background-color: pink; */
                ${Responsive.mobile}{
                    width: 50%;
                    margin-top: 35px;
                    line-height: 20px;
                }
        }
        &:nth-child(3){
            width: 15%;
            text-align: left;
            /* background-color: orange; */
            padding-left: 5px;
                ${Responsive.laptop}{
                    line-height: 50px;
                }
                ${Responsive.mobile}{
                    padding-left: unset;
                    text-align: left;
                    line-height: 30px;
                }
        }
        &:nth-child(4){
            width: 50%;
            text-align: left;
            /* background-color: blue; */
            padding-left: 5px;
                ${Responsive.laptop}{
                    padding-right: 25px;
                }
                ${Responsive.tablet}{
                    padding-right: 25px;
                }
                ${Responsive.mobile}{
                    line-height: 20px;
                    width: 78%;
                    text-overflow: unset;
                    white-space: unset;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    padding-left: unset;
                }
        }
        &:nth-child(5){
            width: 10%;
            /* background-color: yellowgreen; */
            line-height: 78px;
                ${Responsive.laptop}{
                    line-height: 48px;
                }  
                ${Responsive.tablet}{
                    line-height: 48px;
                }
                ${Responsive.mobile}{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 24%;
                    line-height: unset;
                    margin-top: 33px;
                }
        }
    ${Responsive.laptop}{
        font-size: 14px;
        line-height: 50px;
    }
    ${Responsive.tablet}{
        font-size: 13px;
        line-height: 50px;
    }
    ${Responsive.mobile}{
        font-size: 12px;
        line-height: unset;
    }
`;




function Notifications() {
    const [show, setShow] = useState(false);
    return (
        <UserNotificationsContainer>
            <UserNotificationsWrapper>

                <UserInputsBox>
                    <UserTitle>მიღებული შეტყობინებები</UserTitle>
                    <UserInputsButton onClick={() => setShow(!show)}><NotificationFilterIcon /></UserInputsButton>
                </UserInputsBox>
                {show ?  
                <UserNotificationInputDiv>
                    <UserNotificationInput type="number" id="from-the-amount"  placeholder="თანხი-დან"/>
                    <UserNotificationInput type="number" id="before-the-amount" placeholder=" თანხა-მდე" />
                    <UserNotificationSelect name="status" id="notification-status">
                        <option value="status" selected disabled>სტატუსი</option>
                        <option value="status-one">აქტიური</option>
                        <option value="status-two">გაუქმებული</option>
                    </UserNotificationSelect>
                    <UserNotificationFilterButto>ფილტრი</UserNotificationFilterButto>
                </UserNotificationInputDiv>
                  : null}


                <UserNotificationMessageWrapper>

                    <UserNotificationMessageTitleWrapper>
                        <UserNotificationMessageTitle>N</UserNotificationMessageTitle>
                        <UserNotificationMessageTitle>დასახელება</UserNotificationMessageTitle>
                        <UserNotificationMessageTitle>თარიღი</UserNotificationMessageTitle>
                        <UserNotificationMessageTitle>შეტყობინება</UserNotificationMessageTitle>
                        <UserNotificationGarbageDiv>
                          
                        </UserNotificationGarbageDiv>
                    </UserNotificationMessageTitleWrapper>
                    
                    <UserNotificationTitleAnswerDiv>
                        <UserNotificationTitleAnswer>01</UserNotificationTitleAnswer>
                        <UserNotificationTitleAnswer>ჯანმრთელობის პოლისი</UserNotificationTitleAnswer>
                        <UserNotificationTitleAnswer>10 იან 2020</UserNotificationTitleAnswer>
                        <UserNotificationTitleAnswer>სისტემაში მოხდა ახალი მოთხოვნის რეგისტრაცია გთხივთ დაადასტუროთ ვინაობა</UserNotificationTitleAnswer>
                        <UserNotificationTitleAnswer>
                        <UserNotificationGarbageDiv>
                            <GarbageNotificationButton><UserNotificationIcon /></GarbageNotificationButton>
                            <UserNotificationIconButton><NotificationGarbageIcon /></UserNotificationIconButton>
                        </UserNotificationGarbageDiv>
                        </UserNotificationTitleAnswer>
                    </UserNotificationTitleAnswerDiv>

                    <UserNotificationTitleAnswerDiv>
                        <UserNotificationTitleAnswer>01</UserNotificationTitleAnswer>
                        <UserNotificationTitleAnswer>ჯანმრთელობის პოლისი</UserNotificationTitleAnswer>
                        <UserNotificationTitleAnswer>10 იან 2020</UserNotificationTitleAnswer>
                        <UserNotificationTitleAnswer>სისტემაში მოხდა ახალი მოთხოვნის რეგისტრაცია გთხივთ დაადასტუროთ ვინაობა</UserNotificationTitleAnswer>
                        <UserNotificationTitleAnswer>
                        <UserNotificationGarbageDiv>
                            <GarbageNotificationButton><UserNotificationIcon /></GarbageNotificationButton>
                            <UserNotificationIconButton><NotificationGarbageIcon /></UserNotificationIconButton>
                        </UserNotificationGarbageDiv>
                        </UserNotificationTitleAnswer>
                    </UserNotificationTitleAnswerDiv>

                </UserNotificationMessageWrapper>


            </UserNotificationsWrapper>
        </UserNotificationsContainer>
    )
}

export default Notifications
