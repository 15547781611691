import React from 'react'
// import Colors from '../styles/Colors';
// import Fonts from '../styles/Fonts';



function Appeals() {
    return (
        <div>
            მიმმართვები
        </div>
    )
}

export default Appeals

