import * as React from "react";

function VisitDoctorIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 29"
      {...props}
    >
      <g data-name="Group 2745">
        <path
          data-name="Path 9546"
          d="M27.346 7.21h-3.283V3.563A3.608 3.608 0 0020.409 0h-9.817a3.608 3.608 0 00-3.654 3.563V7.21H3.654a3.592 3.592 0 00-3.636 3.563L0 25.434a3.608 3.608 0 003.654 3.563h23.692A3.608 3.608 0 0031 25.434V10.773a3.608 3.608 0 00-3.654-3.563zm-7.668 0h-8.355V3.563h8.355z"
          fill="#261e56"
        />
        <g
          data-name="Group 2743"
          fill="#767880"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.4}
        >
          <path data-name="Path 9544" d="M18.465 18.04h-5" />
          <path data-name="Path 9545" d="M15.966 20.54v-5" />
        </g>
      </g>
    </svg>
  );
}

export default VisitDoctorIcon;

