import * as React from "react";

function NavBarCube(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        data-name="Path 9424"
        d="M11.273 0h5.454A1.3 1.3 0 0118 1.329v3.418a1.3 1.3 0 01-1.273 1.329h-5.454A1.3 1.3 0 0110 4.747V1.329A1.3 1.3 0 0111.273 0zm0 0"
        fill="currentColor"
      />
      <path
        data-name="Path 9425"
        d="M11.273 8.239h5.454A1.3 1.3 0 0118 9.568v7.075a1.3 1.3 0 01-1.273 1.329h-5.454A1.3 1.3 0 0110 16.643V9.568a1.3 1.3 0 011.273-1.329zm0 0"
        fill="currentColor"
      />
      <path
        data-name="Path 9427"
        d="M1.426 0h5.147A1.374 1.374 0 018 1.31v15.379A1.374 1.374 0 016.574 18H1.427a1.374 1.374 0 01-1.426-1.311V1.31A1.374 1.374 0 011.426 0zm0 0"
        fill="currentColor"
      />
    </svg>
  );
}

export default NavBarCube;
