import React, { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../slices";
import { enterRegisterSmsCode } from "../../slices/authSlice";
import { AuthState } from "../../domain";
import { useAppDispatch } from "../../config/store";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
import Responsive from "../../config/Responsive";
import meaningfulErrorMessage from "utils/meaningfulErrorMessage";

// right side
const RegistrationRightSide = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const RightRegistrationText = styled.div`
  font-size: 35px;
  /* color: #05124d; */
  color: #636381;
  margin: unset;
  margin-bottom: 28px;
  cursor: default;
  font-weight: 400;
  font-family: ${Fonts.FiraGOMtavruliRegular};
  ${Responsive.desktop} {
    font-size: 35px;
    margin-bottom: 28px;
  }
  ${Responsive.laptop} {
    font-size: 22px;
    margin-bottom: 20px;
  }
  ${Responsive.tablet} {
    font-size: 22px;
    margin-bottom: 20px;
  }
  ${Responsive.mobile} {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 150px;
  }
`;
const RightSideSmsText = styled.p`
  font-size: 14px;
  color: ${Colors.Gray};
  margin: unset;
  cursor: default;
  line-height: 24px;
  text-align: center;
  font-family: ${Fonts.FiraGORegular};
  ${Responsive.desktop} {
    font-size: 14px;
    line-height: 24px;
  }
  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 18px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 18px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
    line-height: 16px;
  }
`;
const RegisterStepTwoLastText = styled.h1`
  font-size: 14px;
  color: ${Colors.Gray};
  font-family: ${Fonts.FiraGORegular};
  margin: unset;
  margin-top: 40px;
  cursor: default;
  font-weight: 400;
  ${Responsive.desktop} {
    font-size: 14px;
    margin-top: 40px;
  }
  ${Responsive.laptop} {
    font-size: 12px;
    margin-top: 30px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    margin-top: 30px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
    margin-top: 25px;
    text-align: center;
  }
`;
const RegisterStepTwoInput = styled.input`
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  margin: unset;
  margin-top: 40px;
  outline: none;
  color: ${Colors.LightGray};
  font-size: 14px;
  padding-left: 30px;
  border: 1px solid ${Colors.LightGray};
  font-family: ${Fonts.FiraGORegular};
  ${Responsive.desktop} {
    max-width: 440px;
    min-width: 300px;
    height: 70px;
    margin-top: 40px;
    font-size: 14px;
    border-radius: 10px;
  }
  ${Responsive.laptop} {
    max-width: 320px;
    min-width: 300px;
    height: 50px;
    margin-top: 35px;
    border-radius: 6px;
    font-size: 12px;
  }
  ${Responsive.tablet} {
    max-width: 300px;
    min-width: 300px;
    height: 50px;
    margin-top: 35px;
    border-radius: 6px;
    font-size: 12px;
  }
  ${Responsive.mobile} {
    max-width: 300px;
    min-width: 300px;
    height: 50px;
    margin-top: 25px;
    border-radius: 6px;
    font-size: 12px;
  }
`;
const RegisterRightButtonTwo = styled.button`
  max-width: 440px;
  min-width: 300px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background-color: ${Colors.Primary};
  font-family: ${Fonts.FiraGOMtavruliBold};
  outline: none;
  border: unset;
  color: #ffffff;
  font-size: 20px;
  margin-top: 30px;
  &:focus,
  &:hover {
    outline: none;
    border: unset;
    background-color: ${Colors.HoverRed};
  }
  ${Responsive.desktop} {
    max-width: 440px;
    min-width: 300px;
    height: 70px;
    border-radius: 10px;
    margin-top: 30px;
    font-size: 20px;
  }
  ${Responsive.laptop} {
    max-width: 320px;
    min-width: 300px;
    height: 50px;
    border-radius: 6px;
    margin-top: 22px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    max-width: 300px;
    min-width: 300px;
    height: 50px;
    border-radius: 6px;
    margin-top: 22px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    max-width: 300px;
    min-width: 300px;
    height: 50px;
    border-radius: 6px;
    margin-top: 20px;
    font-size: 14px;
  }
`;

const IndexDiv = styled.div`
  ${Responsive.tablet} {
    z-index: 1;
    margin-right: 10px;
  }
  ${Responsive.mobile} {
    margin-right: unset;
  }
`;

const RegisterStepTwo: React.FC = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  const [smsCode, setSmsCode] = useState<string>("");

  const dispatch = useAppDispatch();
  const history = useHistory();
  return (
    <IndexDiv>
      <RegistrationRightSide>
        <RightRegistrationText>რეგისტრაცია</RightRegistrationText>
        <RightSideSmsText>
          რეგისტრაციისთვის შეიყვანე {auth.registrationPhoneMasked} <br />
          ნომერზე გამოგზავნილი SMS კოდი
        </RightSideSmsText>

        <RegisterStepTwoInput
          type="text"
          placeholder="შეიყვანე SMS კოდი"
          onChange={(e) => setSmsCode(e.target.value)}
        />

        <RegisterRightButtonTwo
          onClick={() => {
            dispatch(
              enterRegisterSmsCode({
                personalNumber: auth.registrationPersonalNumber,
                smsCode,
              })
            )
              .then(unwrapResult)
              .then((result) => {
                if (result.success) {
                  history.push("/register-step-three");
                } else {
                  alert(
                  meaningfulErrorMessage(result,
                    "გთხოვთ, სცადოთ თავიდან.")
                  );
                }
              })
              .catch((error) => {
                console.log(error);
                alert(
                  meaningfulErrorMessage(error,
                    "გთხოვთ, სცადოთ თავიდან.")
                );
              });
          }}
        >
          გაგზავნა
        </RegisterRightButtonTwo>
        <RegisterStepTwoLastText>
          არასწორი ნომრის მიმართე ქოლ ცენტრს: 032 2 640 640
        </RegisterStepTwoLastText>
      </RegistrationRightSide>
    </IndexDiv>
  );
};

export default RegisterStepTwo;
