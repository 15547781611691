import React, { useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import Colors from "../styles/Colors";
import Fonts from "../styles/Fonts";

import { useSelector } from "react-redux";
// import { getPolicies, getClaims, getClaimHistory } from "../slices/insuranceSlice";
import { RootState } from "../slices";
import { InsuranceState } from "../domain";
// import { useAppDispatch } from "../config/store";
import openPolicyPaymentUrl from "utils/openPolicyPaymentUrl";
import { Policy } from "../domain";
import Responsive from "../config/Responsive";

const PolicyPaymentModalStart = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PolicyPaymentModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 80%;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  ${Responsive.laptop} {
    height: 80%;
    width: 90%;
  }
  ${Responsive.tablet} {
    height: 80%;
    width: 80%;
  }
  ${Responsive.mobile} {
    height: 40%;
    width: 94%;
    // border-bottom-left-radius: unset;
    // border-bottom-right-radius: unset;
  }
`;
const PolicyPaymentModalTitle = styled.h1`
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  color: ${Colors.TitleBlue};
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-style: normal;
  font-weight: 400;
`;
const CustomPayButton = styled.button`
  max-width: 300px;
  min-width: 100px;
  width: 100%;
  height: 50px;
  outline: none;
  border: unset;
  background-color: ${Colors.Primary};
  cursor: pointer;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-size: 16px;
  color: #fff;
  border-radius: 6px;
  margin-top: 30px;
  margin-bottom: 30px;
  &:focus,
  &:hover {
    outline: none;
    background-color: ${Colors.HoverRed};
  }
`;

const styles = {
  disclaimerWrapper: {
    width: "100%",
    maxWidth: "80%",
    //marginTop: 45,
    //marginBottom: 30,
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 13,
    color: "#aaaabe",
    height: "100%",
    overflowY: "auto" as const,
  },
  disclaimerDefaultElement: {},
};
const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    //   height: 45,
    fontFamily: "DejaVu_sans",
    ":hover": {
      backgroundColor: "#261e56",
      color: "#fff",
    },
    cursor: "pointer",
    color: state.isSelected ? "#fff" : "#636381",
    backgroundColor: state.isSelected ? "#261e56" : "white",
  }),

  control: (provided: any) => ({
    ...provided,
    height: 50,
    border: "1px solid #E5E6ED",
    borderRadius: 6,
    boxShadow: "unset",
    cursor: "pointer",
    fontFamily: "DejaVu_sans",
    ":hover": {
      border: "1px solid #E5E6ED",
    },
  }),
};
//   close
const ClosePopUpBox = styled.button`
  position: absolute;
  right: 25px;
  top: 20px;
  padding: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 14.5px;
  border: unset;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;
const ClosePopLeftLine = styled.span`
  height: 20px;
  width: 2px;
  border-radius: 10px;
  background: #aaaabe;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  right: 13px;
  top: 5px;
`;
const ClosePopRightLine = styled.span`
  height: 20px;
  width: 2px;
  border-radius: 10px;
  background: #aaaabe;
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  right: 13px;
  top: 5px;
`;
const AmountPayable = styled.span`
  font-family: ${Fonts.FiraGORegular};
  font-size: 16px;
  color: #636381;
`;
const Debt = styled.span`
  font-family: ${Fonts.FiraGORegular};
  font-size: 16px;
  // color: #636381;
`;

const PolicyPaymentModal = ({
  onAccept,
  onClose,
  disclaimerText,
  disclaimerTextComponent,
}: {
  onAccept: () => void;
  onClose: () => void;
  disclaimerText?: string;
  disclaimerTextComponent?: JSX.Element;
}) => {
  return (
    <div>
      <PolicyPaymentModalStart>
        <PolicyPaymentModalContent>
          <ClosePopUpBox onClick={onClose}>
            <ClosePopLeftLine />
            <ClosePopRightLine />
          </ClosePopUpBox>

          <PolicyPaymentModalTitle>დადასტურება</PolicyPaymentModalTitle>

          <div style={styles.disclaimerWrapper}>
            {disclaimerTextComponent ? (
              disclaimerTextComponent
            ) : (
              <div style={styles.disclaimerDefaultElement}>
                {disclaimerText ? (
                  <p>{disclaimerText}</p>
                ) : (
                  <p>
                    ვეთანხმები ჩემს მიერ საიტზე შევსებულ ყველა ინფორმაციას და
                    ვადასტურებ, რომ ის არის უტყუარი და მე ვარ პასუხისმგებელი
                    ჩემი მხრიდან არაზუსტი/არასრული ინფორმაციის მოწოდებით
                    გამოწვეულ ნებისმიერ შედეგზე.
                  </p>
                )}
              </div>
            )}
          </div>

          <CustomPayButton disabled={false} onClick={() => onAccept()}>
            გაგრძელება
          </CustomPayButton>
        </PolicyPaymentModalContent>
      </PolicyPaymentModalStart>
    </div>
  );
};

export default PolicyPaymentModal;
