import * as React from "react";

function NotificationIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 15"
      {...props}
    >
      <g data-name="Group 4574">
        <path
          data-name="Path 8273"
          d="M17.1 0H1.9A1.893 1.893 0 000 1.875v11.25A1.893 1.893 0 001.9 15h15.2a1.893 1.893 0 001.9-1.875V1.875A1.893 1.893 0 0017.1 0z"
          fill="currentColor"
        />
        <path
          data-name="Path 12854"
          d="M11.5 6.4l-2 1.5-2-1.5"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
        />
      </g>
    </svg>
  );
}

export default NotificationIcon;
