import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from '../styles/Colors'
import AlphaLogo from "../icons/alpha-logo.svg";


// icons
import NavBarCubeICon from '../icons/NavBarCubeIcon';import Fonts from '../styles/Fonts';
import Responsive from 'config/Responsive';


const NavBarContainer = styled.div`
    position: relative;
    width: 440px;
`;
const SideBarNav = styled.nav<{ $sidebar: boolean }>`
    margin-left: ${({ $sidebar }) => ($sidebar ? '0' : '-440px')};
    transition: all 200ms;
`;
const SideBarUl = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const SideBarLi = styled.li`
    height: 80px;
`;
const SideBarLink = styled(Link)`
    line-height: 80px;
    width: 440px;
    height: 100%;
    // background-color: green; 
    display: block;
    text-decoration: none;
    color: ${Colors.Gray};
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliRegular};
    font-weight: 400;
    transition: all 100ms;
        &:hover,
        &:focus{
            text-decoration: none;
            background-color: #ebe8fc;
            border-left: 3px solid ${Colors.Primary};
            color: ${Colors.Primary};
        }
`;
const SideBarIcon = styled(NavBarCubeICon)`
    height: 18px;
    margin-left: 100px;
    margin-top: 28px;
    margin-right: 30px;
`;
const CloseButton = styled.button`
    height: 25px;
    width: 30px;
    background-color: #fff;
    outline: none;
    border: unset;
    position: absolute;
    top: 45px;
    right: 30px;
        &:focus{
            outline: none;
        }
`;
const CloseButtonTopSpan = styled.span`
    width: 17px;
    height: 3px;
    background-color: #767880;
    display: block;
    border-radius: 6px;
    margin-left: 6px;
`;
const CloseButtonBottomSpan = styled.span`
    width: 12px;
    height: 3px;
    background-color: #767880;
    display: block;
    border-radius: 6px;
    margin-top: 5px;
    margin-left: 6px;
`;
const AlphaLogoLink = styled(Link)`
    margin-top: 80px;
    margin-left: 100px;
    margin-bottom: 80px;
    display: block;
        ${Responsive.laptop}{
            margin-left: 50px;
        }
`;



const  NewSideBar = () => {
    const [sidebar, setSidebar] = useState(true)
    const showSidebar = () => setSidebar(!sidebar)
    
    return (
        <NavBarContainer >

            <div>
                <AlphaLogoLink to="/dashboard">
                    <img src={AlphaLogo} alt="AlphaLogo"/>
                </AlphaLogoLink  >

                <CloseButton onClick={showSidebar} >
                    <CloseButtonTopSpan />
                    <CloseButtonBottomSpan />
                </CloseButton>
            </div>

            <div>
                <SideBarNav $sidebar={sidebar}>
                <SideBarUl>
                    <SideBarLi>

                        <SideBarLink to="/dashboard">
                            <SideBarIcon />  მთავარი
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/my-policies">
                            <SideBarIcon />ჩემი პოლისები
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>
                        
                        <SideBarLink to="/remuneration">
                            <SideBarIcon />ანაზღაურებები
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/appeals">
                            <SideBarIcon />მიმართვები
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/warranty">
                            <SideBarIcon />საგარანტიო
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/visit-doctor">
                            <SideBarIcon />ექიმთან ვიზიტები
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/">
                            <SideBarIcon />პრემიის გადახდა
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/">
                            <SideBarIcon />პოლისის შეძენა
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/">
                            <SideBarIcon />შეთავაზებები
                        </SideBarLink>
                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/">
                            <SideBarIcon />ENGLISH
                        </SideBarLink>

                    </SideBarLi>
                    <SideBarLi>

                        <SideBarLink to="/">
                            <SideBarIcon />გასვლა
                        </SideBarLink>

                    </SideBarLi>
                </SideBarUl>
                </SideBarNav>
            </div>

        </NavBarContainer>
    )
}

export default NewSideBar;
