import * as React from "react";

function SvgDoctor(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} {...props}>
      <path
        data-name="Path 9546"
        d="M14.996 3.978h-1.8V1.966a1.985 1.985 0 00-2-1.966H5.808a1.985 1.985 0 00-2 1.966v2.012h-1.8A1.976 1.976 0 00.014 5.944l-.01 8.091a1.984 1.984 0 002 1.966h12.992a1.985 1.985 0 002-1.966V5.944a1.985 1.985 0 00-2-1.966zm-4.205 0H6.209V1.966h4.582z"
        fill="currentColor"
      />
      <path
        data-name="Union 13"
        d="M8.718 9.727h0z"
        fill="#767880"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgDoctor;
