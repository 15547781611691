import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import {
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";
//import storage from "redux-persist/lib/storage";
import { rootReducer } from "../slices";

import { useDispatch } from "react-redux";

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
//   //blacklist: ["auth"],//if blacklisted, cannot stay logged in
// };

//const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  //reducer: persistedReducer,
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
  }),
});
export default store;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
