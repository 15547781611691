import React from 'react';
import styled from 'styled-components';
import Responsive from 'config/Responsive';
import Fonts from 'styles/Fonts';
import Colors from 'styles/Colors';

// icons
import HealthIcon from "../../icons/HealthIcon";
// import TravelIcon from "../../icons/TravelIcon";
import AutoIcon from "../../icons/AutoIcon";
import Harvest from "../../icons/AgroIcon";
import Property from "../../icons/PropertyIcon";
import Luggage from "../../icons/LuggageIcon";
// import Responsibility from "../../icons/ResponsibilityIcon";
// import Freight from "../../icons/FreightIcon";
// import BankGuaranty from "../../icons/BankGuarantyIcon";
import { Link } from 'react-router-dom';




const PurchaseOfPilicyContainer = styled.div`
       
        ${Responsive.laptop}{
            
        }
        ${Responsive.tablet}{
            
        }
        ${Responsive.mobile}{
           
        }
`;
const PurchaseOfPilicyOnlineWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
        ${Responsive.mobile}{
            flex-direction: column;
        }
`;
const PurchaseOfPilicyStatementWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;
const PurchaseOfPolicyBox  = styled(Link)`
    width: 32%;
    height: 140px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 0.66%;
    margin-right: 0.66%;
    transition: all 300ms;
        ${Responsive.laptop}{
            height: 100px;
            border-radius: 6px;
        }
        ${Responsive.tablet}{
            height: 100px;
            border-radius: 6px;
        }
        ${Responsive.mobile}{
            height: 80px;
            border-radius: 6px;
            width: 100%;
        }
`;
const PurchaseOfPolicyIconBox  = styled.div`
    height: 50px;
    width: 60px;
    transition: all 300ms;
    margin-left: 20px;
        ${Responsive.laptop}{
            height: 35px;
            width: 40px;
        }
        ${Responsive.tablet}{
            height: 35px;
            width: 40px;
        }
        ${Responsive.mobile}{
            height: 35px;
            width: 40px;
        }
`;
const PurchaseOfPolicyTitleDiv  = styled.div`

`;
const PurchaseOfPolicyTitle  = styled.h1`
    margin: unset;
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    width: 100%;
    padding-left: 30px;
    transition: all 300ms;
        ${Responsive.laptop}{
            font-size: 14px;
            padding-left: 20px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
            padding-left: 20px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
            padding-left: 20px;
        }
`;
const PurchaseOfPolicyOnlineButTitle = styled.h1`
    margin: unset;
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin-bottom: 10px;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;
const PurchaseOfPolicyStateMentTitle = styled.h1`
    margin: unset;
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin-bottom: 10px;
    margin-top: 80px;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;


function PurchaseOfPolicy() {
    return (
        <PurchaseOfPilicyContainer>

            <PurchaseOfPolicyOnlineButTitle>ონლაინ შეძენა</PurchaseOfPolicyOnlineButTitle>


           <PurchaseOfPilicyOnlineWrapper>

             <PurchaseOfPolicyBox to="/">
                <PurchaseOfPolicyIconBox>
                   <HealthIcon style={{ width: 100 + "%", height: 100 + "%" }} />
                </PurchaseOfPolicyIconBox>
                <PurchaseOfPolicyTitleDiv>
                    <PurchaseOfPolicyTitle>სამოგზაური დაზღვევა</PurchaseOfPolicyTitle>
                </PurchaseOfPolicyTitleDiv>
             </PurchaseOfPolicyBox>
             
             <PurchaseOfPolicyBox to="/">
                <PurchaseOfPolicyIconBox>
                   <Property style={{ width: 100 + "%", height: 100 + "%" }} />
                </PurchaseOfPolicyIconBox>
                <PurchaseOfPolicyTitleDiv>
                    <PurchaseOfPolicyTitle>ქონების დაზღვევა</PurchaseOfPolicyTitle>
                </PurchaseOfPolicyTitleDiv>
             </PurchaseOfPolicyBox>
             
             <PurchaseOfPolicyBox to="/">
                <PurchaseOfPolicyIconBox>
                   <Luggage style={{ width: 100 + "%", height: 100 + "%" }} />
                </PurchaseOfPolicyIconBox>
                <PurchaseOfPolicyTitleDiv>
                    <PurchaseOfPolicyTitle>პასუხისმგებლობის დაზღვევა</PurchaseOfPolicyTitle>
                </PurchaseOfPolicyTitleDiv>
             </PurchaseOfPolicyBox>


      

           </PurchaseOfPilicyOnlineWrapper>

            <PurchaseOfPolicyStateMentTitle>განაცხადის გაგზავნა</PurchaseOfPolicyStateMentTitle>

            <PurchaseOfPilicyStatementWrapper>

                <PurchaseOfPolicyBox to="/">
                    <PurchaseOfPolicyIconBox>
                    <AutoIcon style={{ width: 100 + "%", height: 100 + "%" }} />
                    </PurchaseOfPolicyIconBox>
                    <PurchaseOfPolicyTitleDiv>
                        <PurchaseOfPolicyTitle>ავტომობილის დაზღვევა</PurchaseOfPolicyTitle>
                    </PurchaseOfPolicyTitleDiv>
                </PurchaseOfPolicyBox>
                
                <PurchaseOfPolicyBox to="/">
                    <PurchaseOfPolicyIconBox>
                    <Harvest style={{ width: 100 + "%", height: 100 + "%" }} />
                    </PurchaseOfPolicyIconBox>
                    <PurchaseOfPolicyTitleDiv>
                        <PurchaseOfPolicyTitle>აგრო დაზღვევა</PurchaseOfPolicyTitle>
                    </PurchaseOfPolicyTitleDiv>
                </PurchaseOfPolicyBox>

            </PurchaseOfPilicyStatementWrapper>

        </PurchaseOfPilicyContainer>
    )
}

export default PurchaseOfPolicy
