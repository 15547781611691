
import * as React from "react";

function NotAccepted(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 90 90"
      {...props}
    >
      <g data-name="Group 6002">
        <circle data-name="Ellipse 191" cx={45} cy={45} r={45} fill="#ea2e4b" />
        <g
          data-name="Group 4476"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={7}
        >
          <path data-name="Path 12797" d="M35 55.5L55.5 35" />
          <path data-name="Path 12798" d="M55.5 55.5L35 35" />
        </g>
      </g>
    </svg>
  );
}

export default NotAccepted;
