import * as React from "react";

function VisaCardIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 40"
      {...props}
    >
      <g data-name="Group 6002">
        <g
          data-name="Rectangle 2131"
          fill="none"
          stroke="#767880"
          strokeLinecap="round"
        >
          <rect width={64} height={40} rx={4} stroke="none" />
          <rect x={0.5} y={0.5} width={63} height={39} rx={3.5} />
        </g>
        <g data-name="Group 4489">
          <path d="M28.817 26.115h-3.331l2.083-12.881H30.9z" fill="#172272" />
          <path
            d="M40.891 13.552a8.207 8.207 0 00-2.987-.548c-3.289 0-5.605 1.754-5.62 4.262-.027 1.85 1.658 2.878 2.919 3.495 1.289.63 1.727 1.042 1.727 1.6-.013.863-1.041 1.261-2 1.261a6.629 6.629 0 01-3.125-.685l-.439-.206-.466 2.892a10.138 10.138 0 003.715.686c3.495 0 5.77-1.727 5.8-4.4.013-1.466-.877-2.59-2.8-3.508-1.165-.589-1.878-.987-1.878-1.59.014-.548.6-1.11 1.919-1.11a5.658 5.658 0 012.481.493l.3.137.453-2.782z"
            fill="#16267e"
          />
          <path
            d="M45.317 21.552c.275-.74 1.33-3.6 1.33-3.6-.014.027.273-.754.438-1.233l.233 1.11s.631 3.083.768 3.727zm4.111-8.318h-2.576a1.643 1.643 0 00-1.741 1.069l-4.947 11.812h3.495l.7-1.932h4.276c.1.452.4 1.932.4 1.932h3.084l-2.687-12.881z"
            fill="#13278a"
          />
          <path
            d="M22.704 13.234l-3.262 8.784-.356-1.781a9.859 9.859 0 00-4.6-5.4l2.988 11.265h3.522l5.235-12.868z"
            fill="#182068"
          />
          <path
            d="M16.413 13.234h-5.358l-.055.26c4.18 1.069 6.948 3.646 8.086 6.743l-1.165-5.92a1.378 1.378 0 00-1.508-1.083z"
            fill="#faa61a"
          />
        </g>
      </g>
    </svg>
  );
}

export default VisaCardIcon;
